import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history';
import Layouts from './layouts/LayoutIndex';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './lang';
import app, { applyCustomStyle } from 'configs/ConfigIndex';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const App = () => {
  useEffect(() => {
    let input = '';
    let tmr;

    const handleKeyDown = event => {
      input += event.key;

      if (input.length === 10 && !isNaN(input)) {
        // console.log('EXECUTA ROTA AQUI! CÓDIGO:', input, +new Date());
        input = '';
      }

      if (tmr && tmr.cancel) tmr.cancel();
      tmr = setTimeout(() => (input = ''), 400);
    };

    const divElement = document.querySelector('html');
    divElement.addEventListener('keydown', handleKeyDown);

    applyCustomStyle();
  }, []);

  return (
    <div className="App">
      <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter history={history}>
            <ThemeSwitcherProvider
              themeMap={themes}
              defaultTheme={app.currentTheme}
              insertionPoint="styles-insertion-point"
            >
              <Helmet>
                <title>{app.NAME}</title>
                <link
                  rel="icon"
                  type="image/png"
                  href={`/apps/${app.ID}/favicon.ico`}
                />
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href={`/apps/${app.ID}/apple-touch-icon.png`}
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href={`/apps/${app.ID}/favicon-32x32.png`}
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href={`/apps/${app.ID}/favicon-16x16.png`}
                />
                <link
                  rel="manifest"
                  href={`/apps/${app.ID}/site.webmanifest`}
                />
                <link
                  rel="mask-icon"
                  href={`/apps/${app.ID}/safari-pinned-tab.svg" color="#5bbad5`}
                />
              </Helmet>
              <Layouts />
            </ThemeSwitcherProvider>
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </div>
  );
};

export default App;
