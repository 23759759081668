import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { JarvislyFormContext, JarvislyNumber } from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appmultiService from 'services/appmultiService';
import { translateX } from 'utils/helpers';
import UploadFirebaseComponent from 'components/jarvisly-components/others/UploadFirebaseComponent';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiOperatorsParametersIndex = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------
  const { isFormDisabled, form } = useContext(JarvislyFormContext);
  const { setModuleForm } = useContext(ModuleContext);
  
  // props deconstruction ------------------------------------------------------
  const { document, setIsWorking } = props;

  // local variables I ---------------------------------------------------------
  const currencyOptions = appmultiService.getCurrencies();

  // component states ----------------------------------------------------------
  const [attachments, setAttachments] = useState([]);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setAttachments(document?.__attachments || []);
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

    // hooks ---------------------------------------------------------------------
    useEffect(() => {
      setModuleForm(form);
    }, [form]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useImperativeHandle(ref, () => ({ attachments }));

  // methods -------------------------------------------------------------------
  const onUploadBefore = async () => setIsWorking(true);
  const onUploadAfter = async () => setIsWorking(false);
  const onUploadError = async () => setIsWorking(false);

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card>
        <Row gutter={ROW_GUTTER}>
          <Col xs={12} md={6}>
            <JarvislyNumber
              name={['parameters', '__exchangeRate']}
              label="exchange"
              decimalSeparator=","
              min={0.01}
              max={99.99}
              addonBefore={null}
              addonAfter={currencyOptions}
              suffixDefaultValue={document?.parameters?.__currency}
              suffixStyle={{ width: 60 }}
            />
          </Col>
        </Row>
      </Card>

      {/*************************************************************
       ATTACHMENTS
       *************************************************************/}
      <Card
        title={
          <div className="text-muted">{translateX('general_conditions')}</div>
        }
      >
        {/* CONDITIONS */}
        <UploadFirebaseComponent
          hideUploadButton={true}
          buttonTitle="Upload"
          name="conditions"
          tags="conditions"
          listType="picture"
          attachments={attachments}
          setAttachments={setAttachments}
          onUploadBefore={onUploadBefore}
          onUploadAfter={onUploadAfter}
          onUploadError={onUploadError}
          isCroppingEnabled={false}
          accept={'.pdf'}
          fileSize={3072}
          isDisabled={isFormDisabled}
          fileFolder="operators/conditions"
          maxFiles={1}
        />
        {/* CONDITIONS */}
      </Card>

      {/*************************************************************
       LOGO
       *************************************************************/}
      <Card title={<div className="text-muted">{translateX('logo')}</div>}>
        {/* LOGO */}
        <UploadFirebaseComponent
          hideUploadButton={true}
          buttonTitle="Upload"
          name="logo"
          tags="logo"
          listType="picture"
          attachments={attachments}
          setAttachments={setAttachments}
          onUploadBefore={onUploadBefore}
          onUploadAfter={onUploadAfter}
          onUploadError={onUploadError}
          isCroppingEnabled={true}
          accept={'.jpg,.png'}
          fileSize={3072}
          isDisabled={isFormDisabled}
          fileFolder="operators/logo"
          maxFiles={1}
        />
        {/* LOGO */}
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
});

export default MultiOperatorsParametersIndex;
