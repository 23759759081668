import React, { useContext, useEffect } from 'react';
import { Flex, Table } from 'antd';
import { parsePhoneToDisplay, setLocale, translateX } from 'utils/helpers';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
  JarvislyFormContext,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import { PlusOutlined } from '@ant-design/icons';
import entityService from 'services/entityService';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const JarvislyContactsIndex = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    _id,
    isWorking,
    setIsWorking,
    document,
    mode,
    setShowContactModal,
    setSelectedRecord,
    refreshDocument,
  } = props;

  // local variables -----------------------------------------------------------
  const isDisabled = isWorking || mode === 'view';
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onAdd = () => {
    setSelectedRecord(null);
    setShowContactModal(true);
  };

  const onEdit = record => {
    setSelectedRecord(record);
    setShowContactModal(true);
  };

  const onDelete = async record => {
    setIsWorking(true);
    await entityService.removeContact(_id, record);
    refreshDocument(_id, true);
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Table
        rowKey="entityId"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={document?.moreContacts || []}
        pagination={false}
        loading={!document || isWorking}
      />

      <Flex justify={appService.isMobile() ? 'center' : 'end'}>
        <FormButton
          title="add"
          className="m-3"
          icon={<PlusOutlined />}
          disabled={isDisabled}
          onClick={onAdd}
        />
      </Flex>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      {
        title: setLocale('contacts'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                shape="circle"
                size="small"
                title={record?.name}
                subtitle={record?.note}
                showAge={record?.birthdate}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },

      {
        title: translateX('contact'),
        dataIndex: 'phone',
        render: (_, record) => {
          return (
            <>
              <div>{parsePhoneToDisplay(record?.phone)}</div>
              <div>{record?.email}</div>
            </>
          );
        },
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              recordId={record?.entityId}
              isDisabled={isDisabled}
              deleteTitle={translateX(
                'remove_this_item',
                ['%ITEM%'],
                ['contact'],
              )}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default JarvislyContactsIndex;
