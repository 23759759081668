import React, { useEffect, useRef, useState } from 'react';
import * as echarts from 'echarts/core';
import 'echarts-liquidfill';
import { Button, Card, Flex, Modal, Tabs, Tag, Tooltip, Descriptions, Divider } from 'antd';
import { IotDevicesInfoIcons } from 'modules/app-views/appveertical/iotDevices/Components';
import DocumentStatusTag from 'components/jarvisly-components/others-old/DocumentStatusTag';
import { translateX } from 'utils/helpers';
import app from 'configs/ConfigIndex';
import { BsInfoLg } from 'react-icons/bs';

const Diagnostic = props => {

  const { record } = props;

  // console.log('record =>', record);

  return (
    <>
      {/* CAPACITY */}
      <Divider orientation="left" plain>{translateX('capacity')}</Divider>
      <Descriptions
        bordered
        layout='horizontal'
        size='small'
        items={[
          {
            key: `_id`,
            label: 'ID',
            children: <>{record?._id}</>,
          },

          {
            key: 'capacity',
            label: translateX('capacity'),
            children: <span className="text-lowercase">
              {`n/d ${translateX('liters')}`}
            </span>,
          },
          {
            key: `limit`,
            label: translateX('limit'),
            children: <span className="text-lowercase">
              {`n/d ${translateX('liters')}`}
            </span>,
          },
        ]}
        className='w-100 mb-4'
        column={1}
      />
      {/* CAPACITY */}

    {/* READING */}
      <Divider orientation="left" plain>{translateX('reading')}</Divider>
      <Descriptions
        bordered
        layout='horizontal'
        size='small'
        items={[
          {
            key: 'last_reading',
            label: translateX('last_reading'),
            children: <>n/d</>,
          },
          {
            key: `sensor_distance`,
            label: translateX('sensor_distance'),
            children: <>{`n/d cm`}</>,
          },
          {
            key: 'current_content',
            label: translateX('current_content'),
            children: <span className="text-lowercase">
              {`n/d ${translateX('liters')}`}
            </span>,
          },
          {
            key: `current_resource`,
            label: translateX('current_resource'),
            children: <span className="text-lowercase">
              {`n/d ${translateX('hours')}`}
            </span>,

          },
        ]}
        className='w-100 mb-4'
        column={1}
      />
    {/* READING */}


      {/* CALIBRATING */}
      <Divider orientation="left" plain>{translateX('calibration')}</Divider>
      <Descriptions
        bordered
        layout='horizontal'
        size='small'
        items={[
          {
            key: 'last_calibration',
            label: translateX('last_calibration'),
            children: <>n/d</>,
          },
          {
            key: `calibration_period`,
            label: translateX('calibration_period'),
            children: <span className="text-lowercase">
              {`n/d ${translateX('hours')}`}
            </span>,
          },
          {
            key: 'maximal_distance',
            label: translateX('maximal_distance'),
            children: <span className="text-lowercase">
              {`n/d cm`}
            </span>,
          },
          {
            key: `minimum_distance`,
            label: translateX('minimum_distance'),
            children: <span className="text-lowercase">
              {`n/d cm`}
            </span>,

          },
        ]}
        className='w-100 mb-4'
        column={1}
      />
      {/* CALIBRATING */}
    </>
  );
};

const LiquidFillChart = props => {
  const { record: item, color = app.baseColor, onClick } = props;
  const chartRef = useRef(null); // Cria uma referência para o container do gráfico

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const chartOptions = buildChartOptions();
  const items = buildTabItens();

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current); // Inicializa o gráfico dentro do div referenciado
    // const infoValue = Math.round(item?.field1?.infoValue);

    // Configuração do gráfico
    chartInstance.setOption(chartOptions);

    // Função para redimensionar o gráfico
    const handleResize = () => {
      chartInstance.resize();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      chartInstance.dispose();
      window.removeEventListener('resize', handleResize);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const Title = props => {
    const { device } = props;

    return (
      <>
        <div className='text-left text-truncate'>
          <div className='text-uppercase text-truncate'>
            {device?.title}
          </div>
          <div
            className='font-size-sm text-muted text-uppercase w-100 text-truncate'
            style={{ minHeight: 19 }}
          >
            {device?.__entity?.name}
          </div>
        </div>
      </>
    );
  };

  return (
    <Card
      className='water-tank'
      // title={
      //   <div>
      //     <div className="font-size-md text-uppercase">{item?.title}</div>
      //     <div
      //       className="font-size-sm text-muted text-uppercase w-100"
      //       style={{ minHeight: 19 }}
      //     >
      //       {item?.__entity?.name}
      //     </div>
      //
      //     {/*<div className="font-size-sm text-muted">{item.channel}</div>*/}
      //   </div>
      // }
      onClick={onClick}
    >
      <Modal
        title={<Title device={selectedDevice} />}
        open={showMoreInfo}
        onOk={() => setShowMoreInfo(false)}
        onCancel={() => setShowMoreInfo(false)}
        destroyOnClose={true}
        afterClose={() => setSelectedDevice(null)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Tabs
          defaultActiveKey='1'
          items={items}
          size='small'
          // rootClassName='mb-5'
        />

        <Flex justify='flex-start' style={{ marginBottom: -64 }}>
          <Flex
            className='p-2 mb-2'
            align='center'
            style={{
              borderRadius: 6,
              backgroundColor: 'rgba(236,236,236,0.63)',
              display: 'inline-flex',
            }}
          >
            <DocumentStatusTag record={selectedDevice} />
            <IotDevicesInfoIcons
              document={selectedDevice}
              mr={12}
              className='ml-2'
            />
            <Tooltip title={translateX('channel')}>
              <Tag color='default' style={{ height: 22, marginInlineEnd: 4 }}>
                {selectedDevice?.channel}
              </Tag>
            </Tooltip>
          </Flex>
        </Flex>
      </Modal>

      <Flex justify='space-between' align='start'>
        <Title device={item} />

        <div className='text-right'>
          <Button
            onClick={() => {
              setSelectedDevice(item);
              setShowMoreInfo(true);
            }}
            type='default'
            size='small'
            shape='circle'
            icon={<BsInfoLg />}
          />
        </div>
      </Flex>

      <Flex justify='center' align='center' className='disable-focus-canvas'>
        <div
          ref={chartRef}
          style={{
            width: '90%',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // marginTop: -14,
            padding: 16,
          }}
        />

        <IotDevicesInfoIcons document={item} vertical mr={12} />
      </Flex>

      {/*<Flex justify="center">*/}
      {/*</Flex>*/}

      <Flex justify='space-between' align='center'>
        <DocumentStatusTag record={item} />

        <Tooltip title={translateX('channel')}>
          <Tag color='default'>{item?.channel}</Tag>
        </Tooltip>
      </Flex>
    </Card>
  );

  function buildChartOptions() {
    return {
      series: [
        {
          type: 'liquidFill',
          data: [item?.infoValue / 100],
          label: {
            show: true,
            color: color,
            insideColor: '#fff',
            fontSize: 24,
            fontWeight: 'bold',
            align: 'center',
            baseline: 'middle',
            position: 'inside',
          },
          emphasis: {
            disabled: true,
            // itemStyle: {
            //   opacity: 0.8,
            //   color: '#ff0000', // Mudar a cor ao passar o mouse
            //   borderColor: '#000',
            //   borderWidth: 1,
            // },
            // label: {
            //   show: true, // Mostrar o rótulo ao passar o mouse
            //   fontSize: 16,
            //   color: '#ffffff',
            // },
          },
          tooltip: {
            show: false,
            // show: true,
            // formatter: '{b}: {c}',
            // backgroundColor: '#222',
            // borderColor: '#777',
            // textStyle: {
            //   color: '#fff',
            // },
          },
          color: [color],
          center: ['50%', '50%'],
          radius: '99%',
          amplitude: '4px',
          waveLength: '80%',
          phase: 'auto',
          period: 1000,
          direction: 'right',
          shape: 'circle',
          itemStyle: {
            // opacity: 0.95,
            shadowBlur: 50,
            shadowColor: 'rgba(0, 0, 0, 0.4)',
          },
          waveAnimation: true,
          animationEasing: 'linear',
          animationEasingUpdate: 'linear',
          animationDuration: 2000,
          animationDurationUpdate: 1000,

          outline: {
            show: true,
            borderDistance: 0,
            itemStyle: {
              color: 'none',
              borderColor: color,
              borderWidth: 1,
              shadowBlur: 20,
              shadowColor: 'rgba(0, 0, 0, 0.25)',
            },
          },
          backgroundStyle: {
            color: `${color}1A`,
          },
        },
      ],
    };
  }

  function buildTabItens() {
    return [
      {
        key: 'diagnosis',
        label: translateX('diagnosis'),
        children: <Diagnostic {...props} />,
      },
      // {
      //   key: 'occurrences',
      //   label: translateX('occurrences'),
      //   children: 'Content of Tab Occurences',
      // },
      {
        key: 'readings',
        label: translateX('readings'),
        children: 'Content of Tab Readings',
      },
      // {
      //   key: 'contacts',
      //   label: translateX('contacts'),
      //   children: 'Content of Tab Contacts',
      // },
    ];
  }
};

export default LiquidFillChart;
