import { PlusOutlined } from '@ant-design/icons';
import { Card, Flex, Table } from 'antd';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import { capitalize } from 'lodash';
import { FiSmartphone } from 'react-icons/fi';
import { MdOutlineMail } from 'react-icons/md';
import { VscNote } from 'react-icons/vsc';
import { translateX } from 'utils/helpers';

const PixKeyIndex = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { isWorking, setSelectedPix, setShowPixKeyModal, pixKey, setPixKey,isDisabled } =
    props;

  // local variables I ---------------------------------------------------------
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  const onAdd = () => {
    setSelectedPix(null);
    setShowPixKeyModal(true);
  };

  const onEdit = record => {
    setSelectedPix(record);
    setShowPixKeyModal(true);
  };

  const onDelete = record => {
    const index = pixKey.findIndex(item => item._id === record._id);

    if (index !== -1) {
      const updatedPixKey = [...pixKey];
      updatedPixKey.splice(index, 1);

      setPixKey(updatedPixKey);
    }
  };

  const getIcon = type => {
    switch (type) {
      case 'email':
        return <MdOutlineMail />;
      case 'mobile':
        return <FiSmartphone />;
      case 'cnpj':
        return <VscNote />;
      default:
        return null;
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Card>
      <Table
        rowKey="_id"
        columns={tableColumns}
        dataSource={pixKey}
        pagination={false}
        loading={isWorking}
      />

      <Flex justify="center">
        <FormButton
          title="add_pix_key"
          className="mt-3"
          style={{ width: 'auto' }}
          icon={<PlusOutlined />}
          onClick={onAdd}
          disabled={isDisabled}
        />
      </Flex>
    </Card>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        title: translateX('name'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                imageUrl={getIcon(record?.type)}
                title={translateX(record?.type)}
                subtitle={capitalize(
                  record?.cnpj || record?.email || record?.mobile,
                )}
                shape="square"
                style={{ padding: 10 }}
                backColor="#ecf2fe"
              />
            </div>
          );
        },
      },
      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              recordId={record?._id}
              isDisabled={isDisabled}
              deleteTitle={translateX(
                'remove_this_item',
                ['%ITEM%'],
                ['contact'],
              )}
            />
          );
        },
      },
    ];
  }
};

export default PixKeyIndex;
