import { Badge, Card, Col, Row } from 'antd';
import { translateX } from 'utils/helpers';
import {
  JarvislyEmail,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyPhone,
  JarvislySelect,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

const UnitsRecordTab = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);

  // context deconstruction ----------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);
  const blockOptions = buildBlockOptions();
  const profileOptions = buildProfileOptions();

  // component states ----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card title={translateX('basic_info')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12} lg={6}>
            <JarvislyInput name="room" label="apartment" required />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <JarvislySelect
              name="selectedBlockId"
              label="Bloco"
              options={blockOptions}
              showSearch
              allowClear
              required
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <JarvislySelect
              name="selectedProfileId"
              label="perfil"
              options={profileOptions}
              showSearch
              allowClear
              required
            />
          </Col>
          <Col xs={24} sm={12} lg={6}>
            <JarvislyInput name="directCall" label="direct_call" />
          </Col>
        </Row>
      </Card>
      <Card title={translateX('responsible')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyInput name={['dataResponsible', 'name']} required />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyPhone name={['dataResponsible', 'mobile']} required />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyEmail name={['dataResponsible', 'email']} required />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildProfileOptions() {
    return (
      parameters?.profiles?.map(profile => {
        return {
          value: profile._id,
          text: profile.name,
          label: profile.name,
          disabled: false,
        };
      }) || []
    );
  }

  function buildBlockOptions() {
    return (
      parameters?.blocks?.map(block => {
        return {
          value: block._id,
          text: `${block.abbreviation} ${block.name}`,
          label: (
            <>
              <Badge
                dot
                style={{
                  marginRight: 8,
                  color: block?.foreColor,
                  backgroundColor: block?.backColor,
                  marginTop: 4,
                }}
              />
              {block.name}
            </>
          ),
          disabled: false,
        };
      }) || []
    );
  }
};

export default UnitsRecordTab;
