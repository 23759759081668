import { TagOutlined } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import {
  JarvislyEmail,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyPhone,
} from 'components/jarvisly-components/others/FormComponents';
import { API_ENTITIES } from 'constants/ApiConstant';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useContext, useEffect } from 'react';
import appService from 'services/appService';
import axiosService from 'services/axiosService';
import { toCapitalCase, translateX } from 'utils/helpers';

// COMPONENT *******************************************************************

// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showContactsModal,
    setShowContactsModal,
    setSelectedRecord,
    selectedRecord,
    isDisabled,
  } = props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !selectedRecord?._id
      ? translateX('new_contact')
      : translateX('edit_contact');
  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (selectedRecord && showContactsModal) {
      form.setFieldsValue(selectedRecord); // Preenchendo o formulário com os dados do registro
    }
  }, [selectedRecord, showContactsModal, form]);

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowContactsModal(false);
  };

  const afterClose = () => {
    form.resetFields();
    setSelectedRecord(null);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        // className="title-fixed-with-18 modal-top"
        title={
          <>
            <TagOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showContactsModal}
        width={640}
        okText={translateX('save')}
        onCancel={onClose}
        cancelText={translateX('close')}
        // okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={'primary'}
        // confirmLoading={isWorking}
        afterClose={afterClose}
      >
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyInput name="name" />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyPhone name="mobile" />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyEmail name="email" />
          </Col>
        </Row>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const ContactModal = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, refreshDocument, selectedRecord, setShowContactsModal } =
    props;

  // local variables I ---------------------------------------------------------
  // let entityId = selectedRecord?.entityId;

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body, searchedEntity }) => {
    // color
    const url = API_ENTITIES;
    const name = body.name.toLowerCase();
    let entityId = selectedRecord?.entityId;

    if (
      document?.__entity?.moreContacts?.findIndex(
        c => c?.name?.toLowerCase() === name && c?.entityId !== entityId,
      ) > -1
    ) {
      appService.notification(
        'e',
        'check_info',
        'check_info',
        'contact_already_exists',
        5,
      );
      return;
    }

    body.name = toCapitalCase(body.name);
    body.dataType = 'person';
    body.dataProfile = ['contact'];
    body.uniqueKey = name || +new Date();

    if (!searchedEntity) {
      const [doc] = await axiosService.upsert({
        url,
        _id: entityId,
        data: body,
      });
      entityId = doc?._id;
    } else {
      entityId = searchedEntity._id;
    }
    console.log('1');
    const contact = {
      entityId,
      name: body.name || '',
      mobile: body?.mobile || '',
      email: body?.email || '',
      dataProfile: body?.dataProfile,
      note: body?.note || '',
      mainContact: false,
    };
    console.log('2');

    if (!document?.moreContacts) {
      document.moreContacts = [];
      console.log('3');
    }
    console.log('4');
    let idx = document.moreContacts.findIndex(c => c?.entityId === entityId);

    if (idx === -1) {
      console.log('5');
      document.moreContacts.push(contact);
    } else {
      console.log('6');
      document.moreContacts[idx] = {
        ...document.moreContacts[idx],
        ...contact,
      };
    }
    console.log('7');
    const axiosOptions = {
      url,
      _id: document?._id,
      data: {
        moreContacts: document.moreContacts,
      },
    };

    try {
      await axiosService.put(axiosOptions);
      console.log('8');
      refreshDocument();
      setShowContactsModal(false);
    } catch (error) {
      console.error('Error updating more contacts:', error);
    }
  };

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
        document={selectedRecord}
        requiredFields={['name', 'mobile', 'email']}
        showSubmitButton={false}
        onConfirm={onConfirm}
        abortComponentSave={true}
        autoFocus="name"
      >
        <ModalComponent {...props} />
      </JarvislyFormProvider>
    </>
  );
};

export default ContactModal;
