import {
  FormPlaca,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislySelect,
} from '../../others/FormComponents';
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import { Card, Col, Modal, Row } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useSelector } from 'react-redux';
import appsindicoService from 'services/appsindicoService';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { record, showModal, setShowModal, afterClose, isDisabled, updating } =
    props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !record?._id
    ? translateX('new_vehicle')
    : translateX('edit_vehicle');

  const { parameters } = useSelector(s => s.moduleSlice);
  const carBrandOptions = appsindicoService.buildCarBrandOptions(parameters);
  const motorcycleBrandOptions =
    appsindicoService.buildMotorcycleBrandOptions(parameters);
  const typeVehiclesOptions =
    appsindicoService.buildTypeVehiclesOptions(parameters);
  const colorOptions = appsindicoService.buildColorOptions(parameters);

  // component states -----------------------------------------------------------
  const [brandOptions, setBrandOptions] = useState([]);
  const [, setType] = useState('');

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form?.resetFields();
    if (record) form?.setFieldsValue(record);
  }, [record, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  const handleTypeChange = value => {
    form.resetFields(['brand']);

    setType(value);
    if (value === 'car') {
      setBrandOptions(carBrandOptions);
    } else if (value === 'motorcycle') {
      setBrandOptions(motorcycleBrandOptions);
    } else {
      setBrandOptions([]);
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18 modal-top"
        title={
          <>
            <TagOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        width={640}
        okText={translateX(!isDisabled ? 'save' : 'close')}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        confirmLoading={updating}
        afterClose={afterClose}
      >
        <Card>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={8} lg={8}>
              <FormPlaca name="plate" required disabled={isDisabled} />
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <JarvislySelect
                label="vehicle_type"
                name="type"
                onChange={handleTypeChange}
                options={typeVehiclesOptions}
                showSearch
                allowClear
              />
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <JarvislyInput name="year" maxLength={4} />
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={8} lg={8}>
              <JarvislySelect
                name="brand"
                options={brandOptions}
                showSearch
                allowClear
              />
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <JarvislyInput name="model" />
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <JarvislySelect
                name="color"
                options={colorOptions}
                showSearch
                allowClear
              />
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} lg={24}>
              <JarvislyInput name="observation" />
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const JarvislyVehiclesModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, isWorking, refreshDocument } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // for 'vehicles' tab
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables II --------------------------------------------------------
  const vehiclesTabProps = {
    ...props,
    showModal,
    setShowModal,
    selectedRecord,
    setSelectedRecord,
  };

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal: value => setShowModal(value),
    selectedRecord: selectedRecord,
    setSelectedRecord: value => setSelectedRecord(value),
  }));

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body }) => {
    try {
      const isEditing = selectedRecord?._id; // Verifica se há um vehiclee selecionado para edição
      const axiosVehiclesOptions = {
        url: '/v1/vehicles',
        _id: isEditing ? selectedRecord._id : undefined, // Usa o ID se estiver editando
        data: body,
      };

      // Realiza a operação de upsert para vehiclees
      const [response] = await axiosService.upsert(axiosVehiclesOptions);
      if (response) {
        // Se estamos adicionando um novo vehiclee, atualiza os IDs dos vehiclees na unidade
        if (!isEditing) {
          const existingVehiclesIds = document?.vehiclesIds || [];
          const newVehicleId = response._id;
          const updatedVehiclesIds = [...existingVehiclesIds, newVehicleId];

          const axiosUnitsOptions = {
            url: '/v1/units',
            _id: document?._id,
            data: { vehiclesIds: updatedVehiclesIds },
          };

          await axiosService.upsert(axiosUnitsOptions);
        }
      }

      refreshDocument();
      setShowModal(false);

      // setShowVehiclesModal(false);
      setSelectedRecord(null); // Limpa a seleção após a operação

      return response;
    } catch (error) {
      console.log(error);
    }
  };
  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-contact"
      document={selectedRecord}
      requiredFields={['color', 'type', 'plate', 'year', 'brand', 'model']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus="nameResponsable"
    >
      <ModalComponent
        {...vehiclesTabProps}
        updating={isWorking}
        record={selectedRecord}
        afterClose={() => {
          setSelectedRecord(null);
        }}
      />
    </JarvislyFormProvider>
  );
});

export default JarvislyVehiclesModal;
