import brazilianBanks from 'assets/data/brazilianBanks.json';
import {
  clone,
  normalize,
  returnOnlyNumbers,
  sort,
  toCapitalCase,
  translateX,
} from '../utils/helpers';
import appService from './appService';
import asaasService from './asaasService';
import { API_BANKS } from '../constants/ApiConstant';
import axiosService from './axiosService';
import store from '../store';

const bankService = {};

bankService.getBanks = (type = null, sortBy = 'number') => {
  const banks = clone(brazilianBanks.banks);
  const digitalAccounts = ['461'];

  const list = banks
    .map(bank => {
      const bankCode = bank?.number.toString().padStart('0', 3);

      bank.code = bankCode;
      bank.name = toCapitalCase(bank.LongName);
      bank.text = normalize(bank.LongName, 'lower');
      bank.title = `${bankCode} - ${bank.name}`;
      bank.isDigitalAccount = digitalAccounts.includes(bankCode);
      bank.tooltipText = bank.Document;
      bank.minimalBillValue = digitalAccounts.includes(bankCode) ? 5 : 0;

      return bank;
    })
    .sort((a, b) => sort(a, b, sortBy));

  if (type === 'digital') {
    return list.filter(b => b.isDigitalAccount);
  } else if (type === 'conventional') {
    return list.filter(b => !b.isDigitalAccount);
  } else {
    return list;
  }
};

bankService.getBanksOptions = (type, sortBy) => {
  const banks = bankService.getBanks(type, sortBy);

  return banks.map(bank => {
    return {
      // ...banks,
      data: { ...bank },
      value: bank.number,
      text: bank.text,
      label: bank.title,
      tooltipText: bank.tooltipText,
      disabled: false,
    };
  });
};

bankService.getCompanyBanks = () => {
  const { selectedCompany } = store.getState().authSlice;

  const banks = selectedCompany?.__banks || [];

  return banks.map(bank => {
    return {
      // ...banks,
      data: { ...bank },
      value: bank?.accountCode,
      text: `${bank?.bankTitle} | ${bank?.accountCode} | ${bank?.note}`,
      label: bank?.alias,
      tooltipText: bank?.note,
      disabled: false,
    };
  });
};

bankService.getBankByAccountCode = accountCode => {
  if (!accountCode) return null;

  const { selectedCompany } = store.getState().authSlice;
  const banks = selectedCompany?.__banks || [];

  return banks.find(b => b.accountCode === accountCode) || null;
};

bankService.getBankByNumber = number => {
  if (!number || isNaN(number)) return null;
  const banks = bankService.getBanks();
  return banks.find(b => b.number === parseInt(number)) || null;
};

bankService.addBank = async (body, document) => {
  let decoratedBody = {};

  try {
    const bank = bankService.getBankByNumber(body?.selectedBank) || {};
    const bankNumber = bank.number;
    const bankCode = bank.code;
    const agencyCode = `${bankCode}${returnOnlyNumbers(body?.agency)}${
      body?.agencyDigit ? returnOnlyNumbers(body?.agencyDigit) : ''
    }`;
    const accountCode = `${agencyCode}${returnOnlyNumbers(body?.account)}${
      body?.accountDigit ? returnOnlyNumbers(body?.accountDigit) : ''
    }`;

    decoratedBody = {
      profile: document?.dataType,
      accountType: body?.accountType,
      alias: body?.alias,
      bankCode,
      bankNumber,
      bankName: bank?.name,
      bankTitle: bank?.title,

      agency: body?.agency,
      agencyDigit: body?.agencyDigit || null,
      account: body?.account,
      accountDigit: body?.accountDigit,
      agencyCode,
      accountCode,
      note: body?.note,

      entityId: document._id,
      asaasInfo: null,
      apiIntegrated: body?.apiIntegrated || false,
      status: body?.status || 'approved',
      sandbox: body?.sandbox,
      accountProfile: body?.accountProfile,

      pixKey: body?.pixKey

    };

    // do the asaas api request through the Jarvisly asaas-v1 integrator if is a asaas digital account
    if (bankNumber === 461) {
      const doc = await asaasService.getAccountByCode(accountCode);

      if (doc) {
        decoratedBody.asaasInfo = {
          subAccountId: doc?.subAccountId,
          loginEmail: doc?.loginEmail,
          tradingName: doc?.tradingName?.toUpperCase(),
          createdAt: doc?._metadata?.audit?.createdAt,
          updatedAt: doc?._metadata?.audit?.updatedAt,
          statusCommercialInfo: doc?.asaasStatus?.commercialInfo,
          statusBankAccountInfo: doc?.asaasStatus?.bankAccountInfo,
          statusDocumentation: doc?.asaasStatus?.documentation,
          statusGeneral: doc?.asaasStatus?.general,
        };
        decoratedBody.apiIntegrated = true;
        decoratedBody.status = doc?.status;
      }
    }

    const axiosOptions = {
      url: API_BANKS,
      data: decoratedBody,
      headers: { 'x-subscriptionid': document?._metadata?.subscriptionId },
    };

    await axiosService.post(axiosOptions);

    appService.message(
      's',
      translateX('bank_account_added_successfully'),
      'add_account',
      5,
    );
  } catch (error) {
    appService.message(
      'e',
      translateX('bank_error_adding_account'),
      'add_account',
      5,
    );
  }
};

bankService.getBillingTypes = titleFromLabel => {
  return [
    {
      value: 'whatever',
      text: 'whatever',
      label: 'others',
      tooltipText: 'whatever_tooltip',
      disabled: false,
    },
    {
      value: 'pix',
      text: 'pix',
      label: 'pix',
      tooltipText: 'pix_tooltip',
      disabled: false,
    },
    {
      value: 'slip_pix',
      text: 'slip_pix',
      label: 'slip_pix',
      tooltipText: 'slip_pix_tooltip',
      disabled: false,
    },
    {
      value: 'credit_card',
      text: 'credit_card',
      label: 'credit_card',
      tooltipText: 'credit_card_tooltip',
      disabled: false,
    },
  ]
    .map(d => {
      const text = translateX(d.text);
      const label = translateX(d.label);

      return {
        value: d.value,
        text: normalize(text, 'lower'),
        label: titleFromLabel ? text : label,
        tooltipText: translateX(d.tooltipText),
        disabled: false,
      };
    })
    .sort((a, b) => sort(a, b, 'text'));
};

bankService.getBankById = async (_id, projection) => {
  let url = API_BANKS;

  if (projection) url += `/${projection}`;

  const axiosOptions = { url, _id };
  const [doc] = await axiosService.get(axiosOptions);
  return doc;
};

bankService.getIntegratedAccountByApiKey = async (apiKey, options) => {
  const { bankNumber } = options;

  try {
    switch (bankNumber) {
      case 461:
        return await asaasService.getAccountByApiKey(apiKey, options);

      default:
        return null;
    }
  } catch {
    return null;
  }
};

export default bankService;
