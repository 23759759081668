import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  rdxSetModuleType,
  rdxSetShowBreadcrumb,
  rdxSetShowPageHeader,
} from 'store/slices/moduleSlice';
import app from 'configs/ConfigIndex';
import moduleService from 'components/jarvisly-module/ModuleService';

export const DefaultDashboard = () => {
  // local variables -----------------------------------------------------------
  const dispatch = useDispatch();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    dispatch(rdxSetModuleType(null));
    dispatch(rdxSetShowBreadcrumb(true));
    dispatch(rdxSetShowPageHeader(true));
    moduleService.loadingModule(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const StepByStepConfiguration = () => {
    switch (app.PROFILE.TYPE) {
      case 'singleCompany':
        if (app.ID === 'appmulti') {
          // return <SingleCompanyConfigurationSteps />;
          return null;
        } else {
          return null;
        }

      default:
        return null;
    }
  };

  // const v = [dayjs(), dayjs().add(5, 'days')];

  if (app?.HOME_MODULE) return <>{app.HOME_MODULE()}</>;

  return (
    <>
      {/*<div>*/}
      {/*  <RangePicker value={v} format={'DD/MM/YYYY'} />*/}
      {/*</div>*/}
      {/*// <Row>*/}
      {/*//   <Col xs={24} md={8} lg={6}>*/}
      <StepByStepConfiguration />
      {/*// </Col>*/}
      {/*// </Row>*/}
    </>
  );
};

export default DefaultDashboard;
