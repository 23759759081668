import { useEffect, useState } from 'react';
import DarkMultiLogo from 'assets/images/logo-116x70.png';
import { Alert, Button, Descriptions, Flex, Modal, Table } from 'antd';
import { translateX } from 'utils/helpers';
import { CalendarOutlined, CopyOutlined, EnvironmentOutlined } from '@ant-design/icons';
import appService from 'services/appService';
import routeService from 'services/routeService';
import Icon from 'components/util-components/Icon';
import dayjs from 'dayjs';
import { FaPlaneArrival, FaPlaneDeparture } from 'react-icons/fa';
import { MdOutlineScreenRotation } from 'react-icons/md';

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const MultiQuotationCompareModal = props => {
  // props deconstruction ------------------------------------------------------
  const {
    showModal,
    setShowModal,
    compareData,
    compareColumns,
    openNotification,
    filter,
    compare,
  } = props;

  // component states -----------------------------------------------------------
  const [url, setUrl] = useState();
  const logo = DarkMultiLogo;
  // local variables ------------------------------------------------------------
  const isMobile = appService.isMobile();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!showModal) return;

    (async () => {
      const doc = await appService.getWebConfig();
      setUrl(doc?.websiteUrl);
    })();
  }, [showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------

  const onClose = () => {
    setShowModal(false);
    openNotification();
  };

  const onClickCopyUrl = () => {
    const qs = {
      destiny: filter?.destiny,
      departure: filter?.departure,
      arrival: filter?.arrival,
      passengers: filter?.passengers,
      compare: compare.map(c => c._id),
    };

    const api = 'travel-insurance-quotations';
    const queryString = routeService.queryStringObjToStr(qs);
    const fullUrl = `${url}/${api}?${queryString}`;

    appService.copyUrl(fullUrl);
  };

  // UI COMPONENT --------------------------------------------------------------

  // const columns = buildColumns();

  return (
    <>
      <Modal
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Fechar"
        width="auto"
        okButtonProps={{ type: 'default' }}
        title={
          <div className="text-muted text-uppercase mb-4">
            {translateX('compare_plans')}
          </div>
        }
        // centered
        open={showModal}
        onCancel={onClose}
        onOk={onClose}
        getContainer={false}
      >
        <img src={logo} width={140} alt="Multi Seguro Viagem." />

        { isMobile && (<div
          className="container-mobile"
          style={{ display: 'flex', justifyContent: 'end' }}
        >
              <Alert message={translateX('rotate_cellphone')} type="warning" showIcon  icon={<MdOutlineScreenRotation size={24}  />}  />
        </div> ) }

        <Descriptions
          bordered
          items={buildDescriptionsTravelItems(filter)}
          className="w-100 mb-4"
        />

        <Table
          rowKey="key"
          columns={compareColumns}
          dataSource={compareData}
          pagination={false}
        />

        <Flex style={{ marginTop: '1em' }}>
          <Button
            type="dashed"
            icon={<CopyOutlined />}
            danger
            onClick={onClickCopyUrl}
          />
          <p
            style={{
              marginLeft: '10px',
              marginBottom: 0,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {translateX('copy_comparison_url')}
          </p>
        </Flex>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildDescriptionsTravelItems(record = {}) {
    // Adiciona verificação para garantir que 'record' não seja indefinido
    const { passengers = [] } = record;

    const items = [
      {
        key: 'destiny',
        label: translateX('destiny'),
        span: isMobile ? 3 : 1,
        children: (
          <>
            <div>
              <Icon type={EnvironmentOutlined} />
              <span style={{ marginLeft: 8 }}>
                {translateX(record?.destiny)}
              </span>
            </div>
            <div>
              <Icon type={CalendarOutlined} />
              <span style={{ marginLeft: 8 }}>
                {record?.days}{' '}
                <span className="text-lowercase">
                  {translateX(record?.days > 1 ? 'days' : 'day')}
                </span>
              </span>
            </div>
          </>
        ),
      },
      {
        key: 'period',
        label: translateX('period'),
        span: isMobile ? 3 : 1,
        children: (
          <>
            <Flex align="center">
              <Icon
                type={FaPlaneDeparture}
                className=" font-size-md"
                style={{ flexShrink: 0 }}
              />
              <span
                className="ml-2 font-weight-semibold"
                style={{ marginLeft: 8 }}
              >
                {dayjs(record.departure).format('L')}
              </span>
            </Flex>

            <Flex align="center">
              <Icon
                type={FaPlaneArrival}
                className="font-size-md"
                style={{ flexShrink: 0 }}
              />
              <span
                className="ml-2 font-weight-semibold"
                style={{ marginLeft: 8 }}
              >
                {dayjs(record.arrival).format('L')}
              </span>
            </Flex>
          </>
        ),
      },
      {
        key: 'travellers',
        label: translateX('travelers'),
        span: isMobile ? 3 : 1,
        children: (
          <>
            <Flex align="center">
              <span
                className="ml-2 font-weight-semibold"
                style={{ marginLeft: 8, fontWeight: 'semibold' }}
              >
                {passengers?.length} passageiros
              </span>
            </Flex>
          </>
        ),
      },
    ];

    return items;
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default MultiQuotationCompareModal;
