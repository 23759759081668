// COMPONENT *******************************************************************
// *****************************************************************************
import { Card, Tabs } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { translateX } from 'utils/helpers';
import { JarvislyFormProvider, JarvislyTabProvider } from 'components/jarvisly-components/others/FormComponents';
import AthletesRecordTab from './record/athletesRecordTab';
import AthletesStatistcTab from './statistic/athletesStatistcTab';
import MODULE_API from '../athletes';
import { useSelector } from 'react-redux';
import axiosService from 'services/axiosService';
import JarvislyResponsablesIndex from 'components/jarvisly-components/forms/JarvislyResponsablesIndex';
import JarvislyResponsablesModal from 'components/jarvisly-components/forms/modals/JarvislyResponsablesModal';
import appService from 'services/appService';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import useUrl from '../../../../../components/jarvisly-components/hooks/urlHooks';

const ApptexansportsAthetesForm = props => {
  // providers context ---------------------------------------------------------
  const { documentContext } = useContext(ModuleContext);

  console.log('documentContext on Form onSave:', documentContext);

  // props deconstruction ------------------------------------------------------
  const { selectedModule, document, mode, form, _id, setModuleFocus } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const url = useUrl();
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'responsible', 'statistics'];

  const contactModalRef = useRef(null);
  // const parametersTabRef = useRef(null);
  // const documentStatusModalRef = useRef(null);

  const contactsTabProps = {
    ...props,
    showContactModal: contactModalRef?.current?.showModal,
    setShowContactModal: contactModalRef?.current?.setShowModal,
    selectedRecord: contactModalRef?.current?.selectedRecord,
    setSelectedRecord: contactModalRef?.current?.setSelectedRecord,
  };
  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);
  const [, setInput] = useState('');
  const followupRef = useRef(null);

  // local variables II --------------------------------------------------------
  const tabItems = buildTabItens();
  const user = useSelector(s => s.authSlice.selectedUser);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!availableTabs.includes(tab)) setTab(initTab);
    url.updateUrlQueryParams({ tab }, true);
    setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'name' }));
  }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onAddFollowup = async () => {
    form.submit();

    const axiosOptions = {
      url: `${MODULE_API}/add-to-set/${_id}/$push/followUp`,
      data: {
        accountId: user._id,
        // text: input,
      },
    };

    await axiosService.put(axiosOptions);

    // refreshDocument(_id, true);
    setInput('');
    followupRef?.current?.focus();
  };

  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;

    // ATTACHMENTS
    body.attachmentIds = await appService.buildAttachmentIds(
      documentContext?.attachments,
      document,
    );

    console.log('NOVO BODY ATTACHMENT IDS =>', body.attachmentIds);
    // body.attachmentIds = await appService.buildAttachmentIds(
    //   body.pictureAttachment,
    // );

    console.log('body ->', body);
    return body;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* CONTACTS MODAL WRAPPER */}
      <JarvislyResponsablesModal {...props} ref={contactModalRef} />
      {/* CONTACTS MODAL WRAPPER */}
      <JarvislyFormProvider
        name="form-athletes"
        document={document}
        autoFocus="cpf"
        requiredFields={['name']}
        // showSubmitButton={tab !== 'responsable'}
        setTab={setTab}
        onSave={onSave}
        onClick={onAddFollowup}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('record'),
        key: initTab,
        hide: false,
        children: (
          <>
            <JarvislyTabProvider tab={initTab}>
              <AthletesRecordTab {...props}></AthletesRecordTab>
            </JarvislyTabProvider>
          </>
        ),
      },

      {
        label: translateX('responsible'),
        key: 'responsible',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab="responsible">
            <Card className="cad-with-body-no-padding">
              <JarvislyResponsablesIndex
                {...contactsTabProps}
                activeTab={tab}
              />
            </Card>
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateX('statistics'),
        key: 'statistics',
        hide: false,
        disabled: mode === 'add',
        children: <AthletesStatistcTab {...props}></AthletesStatistcTab>,
      },
    ];
  }
};

export default ApptexansportsAthetesForm;
