import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LockOutlined,
  MailOutlined,
  MobileOutlined,
  NumberOutlined,
  SendOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Flex, Form } from 'antd';
import { hideAuthMessage, showLoading, signUp } from 'store/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { initCounter, translateX } from 'utils/helpers';
import {
  FormButton,
  FormEmail,
  FormInput,
  FormPassword,
  FormPhone,
  FormWrapper,
  goFocus,
} from 'components/jarvisly-components/others/FormComponents';
import authService from 'services/authService';
import appService from 'services/appService';
import app from 'configs/ConfigIndex';

const loginTheme = app?.APPLICATION_LAYER;

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const stepsWithCancel = [1, 3];

// COMPONENT *******************************************************************
// *****************************************************************************

export const RegisterForm = props => {
  // props deconstruction ------------------------------------------------------
  const { allowRedirect = true } = props;

  // local variables -----------------------------------------------------------
  const dispatch = useDispatch();
  const { loading, showMessage, token, redirect } = useSelector(
    s => s.authSlice,
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const elRefs = {};

  // component states ----------------------------------------------------------
  const [counter, setCounter] = useState(0);
  const [currentStep, setCurrentStep] = useState(0); // 4-last step
  const [isWorking, setIsWorking] = useState(false);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (token !== null && allowRedirect) {
      navigate(redirect);
    }
    if (showMessage) {
      const timer = setTimeout(() => dispatch(hideAuthMessage()), 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  });

  /*
            useEffect(() => {


                if (currentStep === 3) {

                    const title = translateX('change_phone')
                    setCancelButtonTitle(`${title} em ${counter}seg`)

                } else {
                    const title = translateX('change_email')
                    setCancelButtonTitle(title)
                }

            }, [counter])
        */

  // methods -------------------------------------------------------------------
  const onConfirm = async () => {
    form
      .validateFields()
      .then(async values => {
        switch (currentStep) {
          // SENT EMAIL CODE
          case 0: {
            try {
              appService.notification(
                <SendOutlined />,
                'sending_email',
                'sendingKey',
                'wait_for_email',
                0,
              );
              setIsWorking(true);
              const success = await authService.sendRegistrationCode(
                values,
                loginTheme,
              );
              setIsWorking(false);
              appService.notificationDestroy('sendingKey');

              if (success === 'error') return;

              if (success) {
                appService.notification(
                  'i',
                  values.email,
                  'enterCodeKey',
                  'inform_your_email_code',
                  0,
                );
                setCurrentStep(currentStep + 1);
                goFocus(elRefs, 'emailCode');
              } else {
                appService.notification(
                  'w',
                  values.email,
                  'failureKey',
                  'this_email_already_in_use',
                  5,
                );
                goFocus(elRefs, 'email');
              }
            } catch (error) {
              appService.notificationDestroy('sendingKey');
              setIsWorking(false);
              return;
            }

            break;
          }

          // VALIDADE EMAIL CODE
          case 1: {
            try {
              appService.notificationDestroy('enterCodeKey');
              appService.notificationDestroy('failureKey');

              setIsWorking(true);
              const success =
                await authService.validateRegistrationCode(values);
              setIsWorking(false);

              if (success) {
                setCurrentStep(currentStep + 1);
                goFocus(elRefs, 'mobile');
              } else {
                appService.notification(
                  'w',
                  'invalid_code',
                  'failureKey',
                  'invalid_code_description',
                  5,
                );
                goFocus(elRefs, 'emailCode');
              }
            } catch (error) {
              setIsWorking(false);
              return;
            }

            break;
          }

          // SENT SMS CODE
          case 2: {
            try {
              appService.notification(
                <MobileOutlined />,
                'sending_sms',
                'sendingKey',
                'wait_for_sms',
                0,
              );
              setIsWorking(true);
              const success = await authService.sendRegistrationCode(
                values,
                loginTheme,
              );
              setIsWorking(false);
              appService.notificationDestroy('sendingKey');

              if (success) {
                appService.notification(
                  'i',
                  values.mobile,
                  'enterCodeKey',
                  'inform_your_sms_code',
                  0,
                );
                setCurrentStep(currentStep + 1);
                goFocus(elRefs, 'mobileCode');
                initCounter(setCounter, 60, 'sendSms', true);
              } else {
                appService.notification(
                  'w',
                  values.mobile,
                  'failureKey',
                  'error_429_description',
                  5,
                );
                goFocus(elRefs, 'email');
              }
            } catch (error) {
              setIsWorking(false);
              return;
            }

            break;
          }

          // VALIDADE SMS CODE
          case 3: {
            try {
              setIsWorking(true);
              const success =
                await authService.validateRegistrationCode(values);
              setIsWorking(false);

              appService.notificationDestroy('enterCodeKey');
              appService.notificationDestroy('failureKey');

              if (success) {
                setCurrentStep(currentStep + 1);
                goFocus(elRefs, 'password');
              } else {
                appService.notification(
                  'w',
                  'invalid_code',
                  'failureKey',
                  'invalid_code_description',
                  5,
                );
                goFocus(elRefs, 'mobileCode');
              }
            } catch (error) {
              setIsWorking(false);
              return;
            }

            break;
          }

          // DO REGISTRATION
          case 4: {
            setIsWorking(true);
            dispatch(showLoading());

            try {
              await dispatch(signUp(values));
              authService.refreshAccount();
              setIsWorking(false);
            } catch (e) {
              setCounter(0);
              setCurrentStep(4);
              setIsWorking(false);
            }

            break;
          }

          default:
            console.warn('nothing to do!');
        }
      })
      .catch(e => e);

    return null;
  };

  const onCancel = () => {
    switch (currentStep) {
      case 1:
        setCurrentStep(currentStep - 1);
        goFocus(elRefs, 'email', 'end');

        appService.notificationDestroy('enterCodeKey');
        appService.notificationDestroy('failureKey');

        break;

      case 3:
        setCurrentStep(currentStep - 1);
        goFocus(elRefs, 'mobile');

        appService.notificationDestroy('enterCodeKey');
        appService.notificationDestroy('failureKey');

        break;

      default:
        console.warn('nothing to do!');
    }
  };

  /*    const cancelButtonTitle = () => {

                if (currentStep === 1) {
                    return "change_email"

                } else {
                    return "change_phone"
                }
            }*/

  const goBack = () => {
    // showLoading();
    navigate('/auth/signing', { replace: true });
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <FormWrapper
        form={form}
        className="mb-5"
        // initialValues={{
        //   name: "Carol C. Biruel",
        //   email: "biruel@infodesk.com.br",
        //   emailCode: 9673,
        //   mobile: "(19) 9.8232-0000",
        //   mobileCode: 9546,
        //   password: "idadm123"
        // }}
        elRefs={elRefs}
        onConfirm={onConfirm}
      >
        <FormInput
          name="name"
          elRefs={elRefs}
          prefix={<UserOutlined className="text-primary" />}
          autoFocus
          required
        />

        <FormEmail
          elRefs={elRefs}
          prefix={<MailOutlined className="text-primary" />}
          form={form}
          disabled={currentStep >= 1 || isWorking}
          required
        />

        <FormInput
          name="emailCode"
          style={{ display: currentStep >= 1 ? '' : 'none' }}
          label="email_code"
          minLength={4}
          maxLength={4}
          elRefs={elRefs}
          prefix={<NumberOutlined className="text-primary" />}
          disabled={currentStep >= 2}
          required={currentStep >= 1}
        />

        <FormPhone
          elRefs={elRefs}
          name="mobile"
          style={{ display: currentStep >= 2 ? '' : 'none' }}
          form={form}
          profile="mobile"
          prefix={<MobileOutlined className="text-primary" />}
          disabled={currentStep >= 3 || isWorking}
          required={currentStep >= 2}
        />

        <FormInput
          name="mobileCode"
          style={{ display: currentStep >= 3 ? '' : 'none' }}
          label="inform_sms_code"
          minLength={4}
          maxLength={4}
          elRefs={elRefs}
          prefix={<NumberOutlined className="text-primary" />}
          disabled={currentStep >= 4}
          required={currentStep >= 3}
        />

        <FormPassword
          elRefs={elRefs}
          style={{ display: currentStep >= 4 ? '' : 'none' }}
          form={form}
          label={'create_an_password'}
          prefix={<LockOutlined className="text-primary" />}
          disabled={currentStep >= 5}
          required={currentStep >= 4}
        />

        <Flex justify="space-between" align="center">
          <FormButton
            className="mt-3"
            style={{
              width: '100%',
              marginRight: stepsWithCancel.includes(currentStep) ? 8 : 0,
            }}
            // loading={isWorking}
            disabled={isWorking}
            title={currentStep === 4 ? 'register' : 'confirm'}
            // titleLoading={
            //   currentStep === 4
            //     ? "registering"
            //     : "confirming"
            // }
          />

          {stepsWithCancel.includes(currentStep) && (
            <FormButton
              className="mt-3"
              style={{ width: '100%', marginLeft: 8 }}
              type="default"
              onClick={onCancel}
              loading={loading}
              disabled={counter > 0 || loading || isWorking}
              title={
                currentStep === 3
                  ? counter > 0
                    ? `${translateX('change_phone')} ${counter}"`
                    : 'change_phone'
                  : 'change_email'
              }
            />
          )}
        </Flex>

        <Flex justify="center" align="center">
          <FormButton
            className="mt-3"
            type="link"
            onClick={goBack}
            disabled={loading || isWorking}
            title="go_back"
          />
        </Flex>

        {/*
        <Row justify={currentStep >= 2 ? 'space-between' : 'center'} className="w-100">
          {currentStep >= 2 && <Col span={12}>
            <FormButton className="mt-3"
                        style={{ width: 120 }}
                        type="default"
                        title="cancel"/>
          </Col>}

          <Col span={currentStep >= 2 ? 24 : 12}>
            <FormButton className="mt-3"
              // style={{ width: currentStep >= 2 ? 120 : '100%' }}
                        title="confirm"
                        titleLoading="confirming"/>
          </Col>
        </Row>
*/}
      </FormWrapper>
    </>
  );
};

/* const mapDispatchToProps = {
  signUp,
  showAuthMessage,
  hideAuthMessage,
  showLoading,
} */

export default RegisterForm;
