import MultiCustomersList from './List/List';
import CustomersPersonsForm from './Form/Form';
import {
  MultiCustomersFormHeaderColumn2,
  MultiCustomersFormHeaderColumn3,
  MultiCustomersFormHeaderSubtitle,
} from 'modules/app-views/appmulti/customers-persons/Components';
import { getFromLocalStorage } from 'utils/helpers';
import { DefaultFormHeaderColumn1, DefaultFormHeaderCustomIcons } from 'components/jarvisly-module/Form/FormHeader';
import { TeamOutlined } from '@ant-design/icons';
import { API_ENTITIES } from 'constants/ApiConstant';
import dayjs from 'dayjs';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MENU_GROUP = 'records';
const MODULE_TITLE = 'customers';
const MODULE_ICON = TeamOutlined;
export const APPMULTI_CUSTOMERS_ROUTE = `/app/${MENU_GROUP}/${MODULE_TITLE}-person`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const MULTI_CUSTOMERS_MODULE = {
  rootName: 'customers',
  name: 'customersPersons',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  urlForm: null,
  api: API_ENTITIES,

  url: APPMULTI_CUSTOMERS_ROUTE,
  routes: [`${APPMULTI_CUSTOMERS_ROUTE}/:_id`, APPMULTI_CUSTOMERS_ROUTE],

  listState: {
    showParameters: false,
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {
      dataProfile: ['customer', 'traveller'],
      dataType: ['person', 'company'],
    },
    filterOptions: {
      dataProfile: ['customer', 'traveller'],
      dataType: ['person', 'company'],
    },

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },
    sorterOptions: [
      '_metadata.counter',
      '_metadata.documentStatus',
      'name',
      '_metadata.audit.updatedAt',
      'status',
    ],

    search: {},

    textSearch: {},
  },

  methods: {
    decorateDocument,
  },

  documentState: {
    showParameters: true,
    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'name',
    newDocumentTitle: 'new_customer',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'divider',
      'document_status',
    ],
    refreshParametersOnSave: true,

    mockDocument: {
      name: 'Infodesk',
      cnpj: '02934311000153',
      fullName: 'Infodesk Technologies Ltda',
      phone: '19982320000',
      email: 'biruel@infodesk.com.br',
      zip: '13070173',
      address1: 'Avenida Marechal Rondon',
      number: '700',
      address2: 'Sala 307',
      neighborhood: 'Jardim Chapadão',
      __city: 'Campinas - SP',
      parameters: { __exchangeRate: 5.21 },
    },
  },

  components: {
    // module index
    dashboard: null,
    list: props => <MultiCustomersList {...props} />,
    grid: null,
    kanban: null,

    // module form
    form: (props, ref) => <CustomersPersonsForm {...props} ref={ref} />,
    formHeader: null,
    formHeaderTitle: null,
    formHeaderSubtitle: props => (
      <MultiCustomersFormHeaderSubtitle {...props} />
    ),
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: props => <MultiCustomersFormHeaderColumn2 {...props} />,
    formHeaderColumn3: props => <MultiCustomersFormHeaderColumn3 {...props} />,
    formHeaderTags: null,
    formHeaderCustomIcons: props => (
      <DefaultFormHeaderCustomIcons {...props} showStatusIcon />
    ),

    // module parameters
    parameters: null,

    // data preview
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },
};

// EXPORT ********************************************************************
// ***************************************************************************

export default MULTI_CUSTOMERS_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  if (doc?.birthdate) doc.birthdate = dayjs(doc.birthdate);

  return doc;
}
