import React, { useContext, useEffect } from 'react';
import { translateX } from 'utils/helpers';
import { Card, Col, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { JarvislyFormContext, JarvislyInput, JarvislyNumber, JarvislySelect } from 'components/jarvisly-components/others/FormComponents';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const ProductRecordIndex = props => {
  // props deconstruction ------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);
  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {}, []);
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps


  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card title={translateX('product')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={4}>
            <JarvislyInput name="reference" />
          </Col>
          <Col xs={24} sm={16} lg={14}>
            <JarvislyInput name="description" />
          </Col>
          <Col xs={24} sm={8} lg={3}>
            <JarvislySelect
              name="unit"
              options={[
                { value: 'weight  ', label: 'Peso  ' },
                { value: 'volume  ', label: 'volume  ' },
                { value: 'length  ', label: 'Comprimento   ' },
              ]}
            />
          </Col>
          <Col xs={24} sm={16} lg={3}>
            <JarvislyNumber name="price" addonBefore={null} addonAfter={null} />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default ProductRecordIndex;
