import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Avatar, Button, Table, Tag, Tooltip } from 'antd';
import { setLocale, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentStatusTag from 'components/jarvisly-components/others-old/DocumentStatusTag';
import { useState } from 'react';
import ListPreview from 'components/jarvisly-module/List/ListPreview';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import Utils from 'utils';

const UnitsList = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    // columnSorter,
    // columnSearch,
    // columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,
  } = props;

  // local variables I ---------------------------------------------------------
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------
  const [selectedItem, setSelectedItem] = useState();
  const [showPreviewDrawer, setShowPreviewDrawer] = useState(false);
  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onClickPreview = record => {
    setSelectedItem(record);
    setShowPreviewDrawer(true);
  };

  const close = () => {
    setShowPreviewDrawer(false);
  };
  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* PREVIEW LEFT DRAWER */}
      <ListPreview
        close={close}
        visible={showPreviewDrawer}
        record={selectedItem}

        // showDrawer={showPreviewDrawer}
        // setShowDrawer={setShowPreviewDrawer}
        // _module={_module}
        // rdxModuleSettings={rdxModuleSettings}
      />
      {/* PREVIEW LEFT DRAWER */}
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        hidden: !showNumberOfLine,
        title: translateX('line'),
        width: 80,
        render: (item, record, index) => {
          if (!pagination) return;

          const { current, pageSize } = pagination;

          return (
            <div className="text-left">
              {current * pageSize - pageSize + index + 1 || index}
            </div>
          );
        },
      },

      {
        title: translateX('apartment'),
        dataIndex: 'apartment',
        render: (_, record) => {
          const subtitle =
            record?.dataResponsible?.name || record?.__titular?.name;

          // Filtra o bloco com base no selectedBlockId
          const selectedBlock = record?.lkq_blocks?.blocks?.find(
            block => block?._id === record?.selectedBlockId,
          );

          if (selectedBlock) {
            return (
              <div className="d-flex" style={{ width: 200 }}>
                <ImageDescription
                  shape="square"
                  initials={
                    <span className="text-uppercase" style={{ color: 'white' }}>
                      {record?.room}
                    </span>
                  }
                  subtitle={subtitle}
                  foreColor={selectedBlock?.foreColor}
                  backColor={selectedBlock?.backColor}
                />
              </div>
            );
          }

          return null;
        },
      },
      {
        title: translateX('block_unit'),
        dataIndex: 'block_unit',
        render: (_, record) => {
          const selectedBlock = record?.lkq_blocks?.blocks?.find(
            block => block?._id === record?.selectedBlockId,
          );

          if (selectedBlock) {
            return (
              <div key={record._id}>
                <Tag color={selectedBlock?.backColor}>
                  <span
                    className="text-uppercase"
                    style={{ color: selectedBlock?.foreColor }}
                  >
                    {selectedBlock?.name}
                  </span>
                </Tag>
              </div>
            );
          }

          return null; // Não exibe nada se nenhum bloco for encontrado
        },
      },

      {
        title: translateX('residents'),
        dataIndex: 'residents',
        render: (_, record) => (
          <>
            {!record?.titularId ? (
              <Tag key={'unoccupied'} color={'gray'}>
                <span className="text-uppercase" style={{ color: 'white' }}>
                  {translateX('unoccupied')}
                </span>
              </Tag>
            ) : null}

            <Avatar.Group
              size="large"
              max={{
                count: 2,
                style: {
                  color: 'white',
                  backgroundColor: '#c0c0c0',
                  cursor: 'pointer',
                },
                popover: {
                  trigger: 'click',
                },
              }}
            >
              {record?.__titular ? (
                <Avatar
                  style={{
                    color: 'white',
                    backgroundColor: '#c0c0c0',
                  }}
                >
                  <span className="font-weight-semibold font-size-sm">
                    {Utils.getNameInitial(record.__titular.name)}
                  </span>
                </Avatar>
              ) : null}

              {record?.__dependents?.map((dependent, index) => (
                <Avatar
                  key={index} // O key deve ser atribuído aqui
                  style={{
                    color: 'white',
                    backgroundColor: '#c0c0c0',
                  }}
                >
                  <span className="font-weight-semibold font-size-sm">
                    {Utils.getNameInitial(dependent?.name)}
                  </span>
                </Avatar>
              ))}
            </Avatar.Group>
          </>
        ),
      },

      {
        title: translateX('status'),
        dataIndex: 'status',
        render: (_, record) => <DocumentStatusTag record={record} />,
      },

      {
        title: translateX('last_update'),
        dataIndex: '_metadata.audit.updatedAt',
        hidden: false,
        render: (_, record) => (
          <>
            {record?._metadata?.audit?.updatedAt &&
              dayjs(record._metadata.audit.updatedAt).fromNow()}
          </>
        ),
      },

      {
        title: setLocale('actions'),
        dataIndex: 'actions',
        align: 'center',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            <Tooltip title={setLocale('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }
};

export default UnitsList;
