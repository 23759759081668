import { EditOutlined } from '@ant-design/icons';
import { GiSoccerKick } from 'react-icons/gi';

const APP_TEXANSPORTS = {
  ID: 'apptexansports',
  NAME: 'Texan Sports',
  THEME_COLOR: 'CUSTOM_TEXANSPORTS_APP',
  THEME: 'dark',
  FINTECH_SERVICES: true,
  DOMAINS: [
    'sistema.texansports.com',
    'sistema.texansports.com.br',
    'texansports.jarvisly.com',
  ],
  '//PROFILE': [
    'singleCompany',
    'multiCompanies',
    'singleClinic',
    'multiClinics',
    'condominium',
  ],
  PROFILE: 'singleCompany',
  DEV_MODE: true,
  COPY_RIGHT: 'Texan Sports',
  '//API_DEV_HOST': ['localhost', 'vps', 'heroku'],
  API_DEV_HOST: 'localhost',
  API_DEV_HTTPS: false,
  API_PORT_DEV: 8007,
  API_PORT_PRODUCTION: 8007,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: 'localhost',
  API_HOST_DEV_VPS: 'vps.jarvisly.com',
  API_HOST_PRODUCTION: 'vps.jarvisly.com',
  API_HOST_DEV_HEROKU: 'texansports.herokuapp.com',
  WHATSAPP_INTEGRATED: false,
  TOKEN: process.env.REACT_APP_APPTEXANSPORTS_TOKEN,
  LOCALE: 'pt',

  LOGO_APP: '/apps/apptexansports/logo-app.png',
  LOGO_APP_WHITE: '/apps/apptexansports/logo-app-white.png',

  LOGO_APP_ICON: '/apps/apptexansports/logo-app-icon.png',
  LOGO_APP_ICON_WHITE: '/apps/apptexansports/logo-app-icon-white.png',

  LOGO: '/apps/apptexansports/logo.png',
  LOGO_WHITE: '/apps/apptexansports/logo-white.png',
  LOGO_LOGIN_SIDE: '/apps/apptexansports/logo-app-side.png',

  TITLE: 'Texan Sports',
  '//SLOGAN': 'Condomínios Inteligentes',
  SLOGAN: '',
  SLOGAN2: '',
  SLOGAN_REGISTRATION: 'registration_slogan',
  SLOGAN_REGISTRATION2: '',
  APPLICATION_LAYER: 'adm',
  LOGIN_IDENTIFICATION: 'email',
  SELF_REGISTRATION: true,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: 'MENU',
    CONFIGURATIONS: false,
    LANGUAGES: true,
  },
  ENABLE_MARKETPLACE: false,
  MODULES: ['accounts', 'athletes', 'appmultiOperators'],
  MENU_NAVIGATION: [
    // ------------------
    // RECORDS
    // ------------------
    {
      key: 'records',
      path: '/app/records',
      title: 'records',
      className: '',
      icon: EditOutlined,
      isGroupTitle: true,
      submenu: [
        // entities
        {
          key: 'records_athletes',
          path: '/app/records/athletes',
          title: 'athletes',
          icon: GiSoccerKick,
        },
      ],
    },
  ],
  FIREBASE_CONFIG: JSON.parse(
    process.env.REACT_APP_APPTEXANSPORTS_FIREBASE_CONFIG,
  ),
};

export default APP_TEXANSPORTS;
