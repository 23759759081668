import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { setLocale, translateWord, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentStatusTag from 'components/jarvisly-components/others-old/DocumentStatusTag';
import accounting from 'accounting';
import React from 'react';
import { useSelector } from 'react-redux';
import MULTI_PLANS_MODULE from '../plans';

const MultiPlansList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    onClickOpen,

    numberLineColumn,
    documentCodeColumn,
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);
  const statusFilter = buildDocumentStatusFilter();

  const operatorsFilter = parameters?.operators.map(o => {
    return {
      text: o?.name,
      value: o?._id,
    };
  });

  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDocumentStatusFilter() {
    const options =
      MULTI_PLANS_MODULE?.initialRequest?.filterOptions?.status || [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }

  function buildTableColumns() {
    return [
      numberLineColumn,
      documentCodeColumn,

      {
        title: translateWord('plan'),
        dataIndex: 'name',
        ...columnSorter('name'),
        ...columnSearch('name'),
        className: 'text-truncate',
        render: (_, record) => (
          <>
            <div className="text-uppercase">{record?.name}</div>
          </>
        ),
      },

      {
        title: translateWord('operator'),
        dataIndex: 'operatorId',
        ...columnFilters('operatorId', operatorsFilter),
        // ...columnSorter('__operator.name'),
        align: 'left',
        render: (_, record) => (
          <>
            <div className="font-weight-bold">{record?.__operator?.name}</div>

            <Tooltip
              title={translateWord(record?.__operator?.parameters.__tooltip)}
            >
              {/*{translateX('exchange')}:*/}
              {record?.__operator?.parameters?.__exchangeRate &&
              !record?.__operator?.parameters?.__hideExchangeOnList
                ? accounting.formatMoney(
                    record.__operator?.parameters.__exchangeRate,
                    `${record?.__operator?.parameters?.__symbol} `,
                    2,
                    '.',
                    ',',
                  )
                : ''}
            </Tooltip>
          </>
        ),
      },

      // {
      //   title: translateWord('quotation'),
      //   dataIndex: '__operator.parameters.__exchangeRate',
      //   ...columnSorter('__operator.parameters.__exchangeRate'),
      //   // width: 100,
      //   render: (_, record) => (
      //     <div className="text-center">
      //       {record?.__operator?.parameters?.__exchangeRate &&
      //       !record?.__operator?.parameters?.__hideExchangeOnList ? (
      //         <Tooltip
      //           title={translateWord(record?.__operator?.parameters.__tooltip)}
      //         >
      //           {accounting.formatMoney(
      //             record.__operator?.parameters.__exchangeRate,
      //             `${record?.__operator?.parameters?.__symbol} `,
      //             2,
      //             '.',
      //             ',',
      //           )}
      //         </Tooltip>
      //       ) : (
      //         ''
      //       )}
      //     </div>
      //   ),
      // },

      {
        title: translateWord('price'),
        dataIndex: 'price',
        ...columnSorter('price'),
        // width: 100,
        render: (_, record) => (
          <>
            <div className="text-right text-uppercase">
              {accounting.formatMoney(
                record?.price || record?.dailyPrice,
                `${
                  record?.__priceSymbol ||
                  record?.__operator?.parameters?.__symbol
                } `,
                2,
                '.',
                ',',
              )}
              {/*/*/}
              {/*{translateX(*/}
              {/*  !record?.priceType || record.priceType === 'daily'*/}
              {/*    ? 'day'*/}
              {/*    : record.priceType,*/}
              {/*)}*/}
            </div>
            <div className="text-right text-lowercase">
              {translateX(
                !record?.priceType || record.priceType === 'daily'
                  ? 'day'
                  : record.priceType,
              )}
            </div>
          </>
        ),
      },

      {
        title: translateWord('status'),
        dataIndex: 'status',
        ...columnFilters('status', statusFilter),
        ...columnSorter('status'),
        render: (_, record) => <DocumentStatusTag record={record} />,
      },

      {
        title: translateWord('last_update'),
        dataIndex: '_metadata.audit.updatedAt',
        ...columnSorter('_metadata.audit.updatedAt'),
        // width: 160,
        hidden: false,
        render: (_, record) => (
          <>
            {record?._metadata?.audit?.updatedAt &&
              // moment(record._metadata.audit.updatedAt).format('L LT')}
              dayjs(record._metadata.audit.updatedAt).fromNow()}
          </>
        ),
      },

      {
        title: setLocale('actions'),
        dataIndex: 'actions',
        align: 'center',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            {/* <Tooltip title={setLocale('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>*/}
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }
};

export default MultiPlansList;
