import { Tabs } from 'antd';
import { returnOnlyNumbers, translateWord } from 'utils/helpers';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import MultiCustomersRecordIndex from './Record/RecordIndex';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import JarvislyDocumentStatusModal from 'components/jarvisly-components/forms/modals/JarvislyDocumentStatusModal';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';
import ContactModal from './EmergencyContact/ContactModal';
import ContactTab from './EmergencyContact/ContactTab';
import useTab from 'components/jarvisly-components/hooks/useTabHook';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const CustomersPersonsForm = forwardRef((props, ref) => {

  // props deconstruction ------------------------------------------------------
  const {
    selectedModule,
    document,
    // _id,
    // setModuleFocus,
    setRequiredFields,
    requiredFields,
    mode
  } = props;

  // local variables I ---------------------------------------------------------
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'contacts'];
  const documentStatusModalRef = useRef(null);
  // const { parameters } = useSelector(s => s.moduleSlice);


  // component states ----------------------------------------------------------
  const [tab, setTab] = useTab(availableTabs, 'cpf');
  // const [formData, setFormData] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [entityType, setEntityType] = useState('person');
  const [formData, setFormData] = useState(null);
  const [showContactsModal, setShowContactsModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    // formData,
    selectedEntity,
    setSelectedEntity,
    entityType,
    formData,
    setEntityType,
    selectedRecord,
    setSelectedRecord,
    showContactsModal,
    setShowContactsModal
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------
  // useEffect(() => {
  //   if (!availableTabs.includes(tab)) setTab(initTab);
  //   url.updateUrlQueryParams({ tab }, true);
  //   setModuleFocus(prev => ({ ...prev, tab: initTab, field: 'cpf' }));
  // }, [tab, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const parameterRequiredFields = [
      // 'cpfCnpj',
      'cpf',
      "name",
      // 'cnpj',
      // 'zip',
      // 'number',
      'phoneEmail',
    ];

    setRequiredFields(["name", "cpf"]);
    console.log("required", requiredFields)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    documentStatusModalRef,
  }));

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;

    // decorate entity
    body.dataType = 'person';
    body.dataProfile = 'customer';
    body.uniqueKey = returnOnlyNumbers(body.cpf) || +new Date();
    body.status = document?.status;

    return body;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* DOCUMENT STATUS MODAL WRAPPER */}
      <JarvislyDocumentStatusModal {...props} ref={documentStatusModalRef} />
      {/* DOCUMENT STATUS MODAL WRAPPER */}

      {/* CONTACT MODAL WRAPPER */}
      <ContactModal {...childProps}/>
      {/* CONTACT MODAL WRAPPER */}

      <JarvislyFormProvider
        name="form-customer"
        document={document}
        requiredFields={["name", "cpf", "phoneEmail"]}
        autoFocus="cpf"
        setTab={setTab}
        onSave={onSave}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateWord('record'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <MultiCustomersRecordIndex {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
      {
        label: translateWord('contacts'),
        key: 'contacts',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab='contacts'>
            <ContactTab {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
    ];
  }
});

export default CustomersPersonsForm;
