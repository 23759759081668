import { forwardRef, useContext, useState } from 'react';
import { Col, Modal } from 'antd';
import { translateX } from 'utils/helpers';
import { WhatsAppOutlined } from '@ant-design/icons';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
} from 'components/jarvisly-components/others/FormComponents';
import whatsappService from 'services/whatsappService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    showEditModal,
    setShowEditModal,
    title,
    confirmLoading,
    setConfirmLoading,
    setCurrentId,
    setCurrentTitle,
    setCurrentDescription,
    currentTitle,
    currentDescription,
  } = props;

  const onClose = () => {
    form.resetFields();
    setConfirmLoading(false);
    setShowEditModal(false);
    setCurrentId('');
    setCurrentTitle('');
    setCurrentDescription('');
  };

  return (
    <Modal
      title={
        <>
          <WhatsAppOutlined />
          <span className="ml-2">{title}</span>
        </>
      }
      destroyOnClose={true}
      onClose={onClose}
      onCancel={onClose}
      onOk={() => form.submit()}
      open={showEditModal}
      width={640}
      confirmLoading={confirmLoading}
      okText={translateX('edit')}
      okButtonProps={{ type: 'primary' }}
      getContainer={false}
      className="modal-without-padding"
    >
      <Col xs={24}>
        <JarvislyInput name="title" defaultValue={currentTitle} />
        <JarvislyInput name="description" defaultValue={currentDescription} />
      </Col>
    </Modal>
  );
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const EditWppModal = forwardRef((props, ref) => {
  // props deconstruction ------------------------------------------------------
  const {
    setShowEditModal,
    currentId,
    setCurrentId,
    setCurrentTitle,
    setCurrentDescription,
  } = props;

  // component states ----------------------------------------------------------
  const [confirmLoading, setConfirmLoading] = useState(false);

  // local variables ------------------------------------------------------------
  const title = translateX('edit_wpp');

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    title,
    confirmLoading,
    setConfirmLoading,
  };

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  //TODO
  const onConfirm = async ({ body, form }) => {
    setConfirmLoading(true);
    try {
      const customBody = {
        title: body.title,
        description: body.description,
      };
      await whatsappService.editClientConnection(customBody, currentId);
      form.resetFields();
      setShowEditModal(false);
      setCurrentId('');
      setConfirmLoading(false);
      setCurrentTitle('');
      setCurrentDescription('');
    } catch (error) {
      console.log('Error editing session --> ', error);
      setConfirmLoading(false);
    }
    return null;
  };

  // UI COMPONENT --------------------------------------------------------------

  return (
    <JarvislyFormProvider
      name="whatsapp-form"
      requiredFields={['title']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      abortComponentSave={true}
    >
      <ModalComponent {...childProps} />
    </JarvislyFormProvider>
  );
});

// EXPORT **********************************************************************
// *****************************************************************************

export default EditWppModal;
