import { combineReducers } from 'redux';

import themeSlice from './slices/themeSlice';
import theme from './slices/themeSlice';
// remove this two lines after change all to <>Slice
import authSlice from './slices/authSlice';
import auth from './slices/authSlice';
import moduleSlice from './slices/moduleSlice';

const rootReducer = asyncReducers => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    themeSlice,
    auth,
    authSlice,
    moduleSlice,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
