import React from 'react';
import { Col, Row, Tooltip } from 'antd';
import { Icon } from 'components/util-components/Icon';
import { ApiOutlined, ApiTwoTone, GlobalOutlined, HomeOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { parsePhoneToDisplay, translateX } from 'utils/helpers';
import Flex from 'components/shared-components/Flex';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
export const AccountOrganizationsDocumentHeaderColumn2 = props => {
  const { document } = props;

  return (
    <Col xs={24} sm={8}>
      <Row style={{ display: document?.email ? 'initial' : 'none' }}>
        <Col>
          {document?.email && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('email')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={MailOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {document?.email}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>

      <Row style={{ display: document?.phone ? 'initial' : 'none' }}>
        <Col>
          {document?.phone && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('phone')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={PhoneOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {parsePhoneToDisplay(document?.phone)}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const AccountOrganizationsDocumentHeaderColumn3 = props => {
  const { document } = props;

  return (
    <Col xs={24} sm={8}>
      <Row style={{ display: document?.__city ? 'initial' : 'none' }}>
        <Col>
          {document?.__city && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('locale')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={HomeOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {document?.__city}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>

      <Row style={{ display: document?.website ? 'initial' : 'none' }}>
        <Col>
          {document?.website && (
            <Row className="p-1" style={{ minHeight: 29 }} align={'middle'}>
              <Tooltip placement="right" title={translateX('website')}>
                <Flex
                  flexDirection="row"
                  justifyContent="start"
                  alignItems="center"
                  className="text-truncate"
                >
                  <Icon
                    type={GlobalOutlined}
                    className="text-primary font-size-md"
                    style={{ flexShrink: 0 }}
                  />
                  <div
                    className="ml-2 font-weight-semibold"
                    style={{ flex: 1 }}
                  >
                    {document?.website}
                  </div>
                </Flex>
              </Tooltip>
            </Row>
          )}
        </Col>
      </Row>
    </Col>
  );
};

// COMPONENT *******************************************************************
// *****************************************************************************
export const CompaniesDocumentHeaderCustomIcons = props => {
  const { document, isEmpty } = props;
  const apiIntegration = document?.parameters?.integrated;

  if (isEmpty) return null;

  return (
    <>
      <Tooltip
        title={translateX(
          apiIntegration ? 'api_integrated' : 'no_api_integration',
        )}
      >
        <div style={{ opacity: apiIntegration ? 1 : 0.3, height: 17 }}>
          <Icon
            type={apiIntegration ? ApiTwoTone : ApiOutlined}
            className="text-primary font-size-md"
            style={{ flexShrink: 0 }}
          />
        </div>
      </Tooltip>
    </>
  );
};
