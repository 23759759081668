import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { toCapitalCase, translateX } from 'utils/helpers';
import { Col, Flex, Modal, Row } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import {
  JarvislyDateRangePicker,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislySwitch,
} from 'components/jarvisly-components/others/FormComponents';
import dayjs from 'dayjs';
import axiosService from 'services/axiosService';
import { API_PARAMETERS } from 'constants/ApiConstant';
import { useSelector } from 'react-redux';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { record, showModal, setShowModal, afterClose, isDisabled, updating } =
    props;

  // local variables I ----------------------------------------------------------

  // component states -----------------------------------------------------------
  const [allDay, setAllDay] = useState(false);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form?.resetFields();
    if (record) form?.setFieldsValue(record);
  }, [record, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18 modal-top"
        title={
          <>
            <CalendarOutlined />
            <span className="ml-2">{translateX('add_calendar_block')}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        okText={translateX(!isDisabled ? 'save' : 'close')}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        confirmLoading={updating}
        afterClose={afterClose}
      >
        <Row gutter={ROW_GUTTER}>
          <Col span={24}>
            <JarvislyInput name="description" />
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          <Col span={24}>
            <Flex align="center">
              <div className="mr-3" style={{ marginBottom: 24, marginLeft: 4 }}>
                {translateX('all_day')}
              </div>
              <div>
                <JarvislySwitch name="allDay" noLabel onChange={setAllDay} />
              </div>
            </Flex>
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          <Col span={24}>
            <JarvislyDateRangePicker
              name="interval"
              showTime={!allDay}
              format={!allDay ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'}
              minuteStep={30}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const AppDedaloAgendasSettingsAddModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { isWorking } = props;

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);

  // component states ----------------------------------------------------------

  // for 'contacts' tab
  const [updating, setUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    showModal,
    setShowModal,
    selectedRecord,
    setSelectedRecord,
    updating,
  };

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal: value => setShowModal(value),
    selectedRecord: selectedRecord,
    setSelectedRecord: value => setSelectedRecord(value),
  }));

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body }) => {
    setUpdating(true);

    const dateFrom = body.allDay
      ? dayjs(body.interval[0].format('YYYY-MM-DDT00:00:00'))
      : dayjs(body.interval[0].format('YYYY-MM-DDTHH:mm:00'));

    const dateTo = body.allDay
      ? dayjs(body.interval[1].format('YYYY-MM-DDT23:59:59'))
      : dayjs(body.interval[1].format('YYYY-MM-DDTHH:mm:59'));

    const betweenDates =
      dateFrom.format('YYYY-MM-DDTHH:mm:00') !==
        dateFrom.format('YYYY-MM-DDT00:00:00') ||
      dateTo.format('YYYY-MM-DDTHH:mm:59') !==
        dateTo.format('YYYY-MM-DDT23:59:59') ||
      dateFrom.format('YYYY-MM-DD') !== dateTo.format('YYYY-MM-DD');

    const dataSet = {
      description: toCapitalCase(body.description),
      allDay: !!body.allDay,
      dateFrom: dateFrom,
      dateTo: dateTo,
      betweenDates: betweenDates,
    };

    console.log('POST DATE ->', dataSet);

    const axiosOptions = {
      url: `${API_PARAMETERS}/add-to-set/${parameters?._id}/$push/agendas.blocks`,
      data: dataSet,
    };

    setShowModal(false);
    return await axiosService.put(axiosOptions);

    // refreshDocument(_id, true);
    // setInput('');
    // followupRef?.current?.focus();

    //
    // const url = API_ENTITIES;
    // const name = body.name.toLowerCase();
    // const cpf = returnOnlyNumbers(body?.cpf);
    // let entityId = selectedRecord?.entityId;
    //
    // if (
    //   document?.moreContacts.findIndex(
    //     c => c?.name?.toLowerCase() === name && c?.entityId !== entityId,
    //   ) > -1
    // ) {
    //   appService.notification(
    //     'e',
    //     'check_info',
    //     'check_info',
    //     'contact_already_exists',
    //     5,
    //   );
    //   return;
    // }
    //
    // body.name = toCapitalCase(body.name);
    // body.dataType = 'person';
    // body.dataProfile = 'contact';
    // body.uniqueKey = cpf || +new Date();
    //
    // // save person in entities collection
    // if (!searchedEntity) {
    //   const [doc] = await axiosService.upsert({
    //     url,
    //     _id: entityId,
    //     data: body,
    //   });
    //   entityId = doc?._id;
    // } else {
    //   entityId = searchedEntity._id;
    // }
    //
    // // update moreContacts subdocument
    // const contact = {
    //   entityId,
    //   note: body?.note,
    // };
    //
    // if (!document?.moreContacts) document.moreContacts = [];
    // let idx = document.moreContacts.findIndex(c => c?.entityId === entityId);
    // if (idx === -1) {
    //   document.moreContacts.push(contact);
    // } else {
    //   document.moreContacts[idx].note = body?.note;
    // }
    // const data = { moreContacts: document.moreContacts };
    // const [doc] = await axiosService.put({ _id, url, data });
    //
    // form?.resetFields();
    //
    // refreshDocument(doc._id, true);
    // setShowModal(false);
    //
    // return doc;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-agendas-add"
      document={selectedRecord}
      requiredFields={['description', 'interval']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus="description"
      abortComponentSave={true}
    >
      <ModalComponent
        {...childProps}
        updating={isWorking}
        record={selectedRecord}
        afterClose={() => {
          setSelectedRecord(null);
        }}
      />
    </JarvislyFormProvider>
  );
});

export default AppDedaloAgendasSettingsAddModal;
