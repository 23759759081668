import { Space } from 'antd';
import Search from 'antd/es/input/Search';

const IotDevicesFilter = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { onFilterDone } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onTextSearch = value => {
    onFilterDone(null, { textSearch: value });
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <div className="mt-3">
      <Space direction="vertical">
        <Search
          placeholder="Busca Geral"
          allowClear
          enterButton="Buscar"
          // size="large"
          onSearch={onTextSearch}
        />
      </Space>
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default IotDevicesFilter;
