import { Tooltip } from 'antd';
import { translateX } from 'utils/helpers';

//titulo do cabeçalho
export const UnitsFormHeaderTitle = props => {
    const { document } = props;

    return (
      <Tooltip title={translateX('unit')}>
        <span className="text-uppercase">
          <span>
            {translateX('unit')} Nº {document?._metadata?.counter}
          </span>
        </span>
      </Tooltip>
    );
  };
