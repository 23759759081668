import { getFromLocalStorage } from 'utils/helpers';
import { DefaultFormHeaderColumn1 } from 'components/jarvisly-module/Form/FormHeader';
import { UnitsFormHeaderTitle } from './Components';
import dayjs from 'dayjs';
import UnitsList from './List/List';
import { IoStarOutline } from 'react-icons/io5';
import UnitsForm from './Form/Form';
import UnitsParametersModal from './Parameters/ParametersModal';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MENU_AREA = 'records';
const MENU_GROUP = 'condominium';
const MODULE_TITLE = 'units';
const MODULE_ICON = IoStarOutline;
const MODULE_API = '/v1/units';
export const APPSINDICO_UNITS_ROUTE = `/app/${MENU_AREA}/${MENU_GROUP}/${MODULE_TITLE}`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const APPSINDICO_UNITS_MODULE = {
  rootName: 'units',
  name: 'units',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  urlForm: null,
  api: MODULE_API,
  url: APPSINDICO_UNITS_ROUTE,
  routes: [`${APPSINDICO_UNITS_ROUTE}/:_id`, APPSINDICO_UNITS_ROUTE],

  listState: {
    showParameters: false,
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},
    filterOptions: {},

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },
    sorter: {
      '_metadata.audit.updatedAt': -1,
    },

    sorterOptions: ['_metadata.counter', '_metadata.audit.updatedAt'],

    search: {},

    textSearch: {},
  },

  methods: {
    decorateDocument,
  },

  documentState: {
    showParameters: true,

    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'name',
    newDocumentTitle: 'new_unit',
    autoFill: true,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'askForUpdate',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'autoFill',
    ],
    refreshParametersOnSave: true,

    mockDocument: {
      room: '21',
      selectedBlockId: '66981b2c79aa1ee1007ba799',
      selectedProfileId: '66981ab979aa1ee1007ba792',
      directCall: '123',
      dataResponsible: {
        name: 'João pedro',
        mobile: '(19) 9.9999-9999',
        email: 'joao@gmail.com',
      },
    },
  },

  components: {
    // module index
    dashboard: null,
    list: props => <UnitsList {...props} />,
    grid: null,
    kanban: null,

    // module form
    form: props => <UnitsForm {...props} />,
    formHeader: null,
    formHeaderTitle: props => <UnitsFormHeaderTitle {...props} />,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: null,
    formHeaderColumn3: null,
    formHeaderTags: null,
    formHeaderCustomIcons: null,

    // module parameters
    parameters: props => <UnitsParametersModal {...props} />,

    // data preview
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },
};

// EXPORT ********************************************************************
// ***************************************************************************

export default APPSINDICO_UNITS_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  try {
    if (doc?.birthdate) doc.birthdate = dayjs(doc.birthdate);

    if (doc?.__titular?.birthdate)
      doc.__titular.birthdate = dayjs(doc.__titular.birthdate);

    if (doc?.__habitation?.moveDate)
      doc.__habitation.moveDate = dayjs(doc.__habitation.moveDate);

    doc.__dependents.forEach(dependent => {
      if (dependent.birthdate) {
        dependent.birthdate = dayjs(dependent.birthdate);
      }
      if (dependent.moveDate) {
        dependent.moveDate = dayjs(dependent.moveDate);
      }
    });

    doc.__pets.forEach(pet => {
      if (pet.birthdate) {
        pet.birthdate = dayjs(pet.birthdate);
      }
      if (pet.vaccinatedAt) {
        pet.vaccinatedAt = dayjs(pet.vaccinatedAt);
      }
    });

    return doc;
  } catch (error) {
    throw error;
  }
}
