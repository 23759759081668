import React, { useEffect, useState } from 'react';
import { Card, Col, Flex, Form, Row } from 'antd';
import {
  FormButton,
  FormCpf,
  FormEmail,
  FormInput,
  FormPhone,
  FormWrapper,
  goFocus,
} from 'components/jarvisly-components/others/FormComponents';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { isValidCpf } from 'utils/helpers';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import entityService from 'services/entityService';
import AddressComponent2 from 'components/jarvisly-components/others-old/AddressComponent2';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiQuotationsCheckoutStep2 = props => {
  // props deconstruction ------------------------------------------------------
  const {
    step,
    setStep,
    travellers,
    buyer,
    setBuyer,
    enabledSteps,
    setEnabledSteps,
    isMobile,
    setUpdating,
    isDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  const [form] = Form.useForm();
  const elRefs = {};

  // component states ----------------------------------------------------------
  const [address, setAddress] = useState();
  const [entity, setEntity] = useState(null);

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    if (step === 1) goFocus(elRefs, 'cpf');
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (buyer) {
      form?.setFieldsValue(buyer);
      setAddress({
        addressTitle: buyer?.addressTitle,
        neighborhood: buyer?.neighborhood,
        city: buyer?.city,
        province: buyer?.province,
        addressCoordinates: buyer?.addressCoordinates,
        addressAdditionalInfo: buyer?.addressAdditionalInfo,
        addressMetadata: buyer?.addressMetadata,
      });
      setEntity({
        _id: buyer._id,
        cpf: buyer?.cpf,
      });

      // const validateFields = [];
      //
      // if (document?.cpf) validateFields.push('cpf');
      // if (document?.phone) validateFields.push('phone');
      // if (document?.email) validateFields.push('email');
      // if (document?.zip) validateFields.push('zip');
      //
      // if (validateFields.length > 0) form.validateFields(validateFields);
    } else {
      setEntity(null);
      form?.resetFields();
    }
  }, [form, buyer]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onPrevious = () => {
    if (isDisabled) return setStep(0);

    const values = form.getFieldsValue();

    const body = {
      ...values,
      ...address,
    };

    setBuyer(body);
    setStep(0);
  };

  const onNext = async values => {
    if (isDisabled) return setStep(2);

    setUpdating(true);

    const name = form.getFieldValue('name');

    const body = {
      _id: entity?._id,
      dataType: 'person',
      name: entity?.name || name,
      ...values,
      ...address,
    };

    if (!body?.address2) body.address2 = null;

    const doc = await entityService.addEntity(body, 'customer');

    setBuyer(doc);
    if (enabledSteps && !enabledSteps.includes(2)) {
      setEnabledSteps([...enabledSteps, 2]);
    }

    setStep(2);
  };

  const onCpfChange = async cpf => {
    if (cpf === entity?.cpf) return true;
    if (!isValidCpf(cpf)) return setEntity(null);

    const doc = await entityService.getEntityByField(cpf, 'cpf');

    if (!doc) {
      setEntity(null);
      return true;
    }

    const body = {
      name: doc?.name,
      fullName: doc?.fullName,
      phone: doc?.phone,
      email: doc?.email,
      zip: doc?.zip,
      address1: doc?.address1,
      number: doc?.number,
      address2: doc?.address2 || null,
      __city: doc?.__city,
      addressTitle: doc?.addressTitle,
      neighborhood: doc?.neighborhood,
      city: doc?.city,
      province: doc?.province,
      addressCoordinates: doc?.addressCoordinates,
      addressAdditionalInfo: doc?.addressAdditionalInfo,
      addressMetadata: doc?.addressMetadata,
    };

    setAddress({
      addressTitle: doc?.addressTitle,
      neighborhood: doc?.neighborhood,
      city: doc?.city,
      province: doc?.province,
      addressCoordinates: doc?.addressCoordinates,
      addressAdditionalInfo: doc?.addressAdditionalInfo,
      addressMetadata: doc?.addressMetadata,
    });

    setEntity({ _id: doc?._id, cpf });
    setBuyer(doc);

    form.setFieldsValue(body);

    return true;
  };

  // UI COMPONENTS -------------------------------------------------------------

  const childProps = {
    ...props,
    form,
    elRefs,
    address,
    setAddress,
  };

  return (
    <FormWrapper
      name="form-buyer"
      form={form}
      initialValues={buyer}
      elRefs={elRefs}
      onConfirm={onNext}
    >
      <Row gutter={ROW_GUTTER}>
        <Col xs={24} lg={12}>
          <Card className="w-100 strong-border">
            <Row gutter={ROW_GUTTER}>
              {/* CPF */}
              <Col xs={24} md={10}>
                <FormCpf
                  elRefs={elRefs}
                  disabled={isDisabled}
                  required
                  form={form}
                  autoFocus
                  onChange={onCpfChange}
                />
              </Col>
              {/* CPF */}

              {/* NAME */}
              <Col xs={24} sm={14}>
                <FormInput
                  label="name_social"
                  name="name"
                  onBlur={() => {
                    const nameValue = form.getFieldValue('name');
                    const fullNameValue = form.getFieldValue('fullName');

                    // Atualiza o 'fullName' apenas se ele estiver vazio
                    if (nameValue && (!fullNameValue || fullNameValue === '')) {
                      form.setFieldValue('fullName', nameValue); // Copia o valor de 'name' para 'fullName'
                    }
                  }}
                  required
                />
              </Col>
              {/* NAME */}

              {/* FULL NAME */}
              <Col xs={24} sm={24}>
                <FormInput label="full_name" name="fullName" />
              </Col>
              {/* FULL NAME */}
            </Row>

            <Row gutter={ROW_GUTTER}>
              {/* PHONE */}
              <Col xs={24} md={10}>
                <FormPhone
                  name="phone"
                  form={form}
                  disabled={isDisabled}
                  required
                  elRefs={elRefs}
                />
              </Col>
              {/* PHONE */}

              {/* EMAIL */}
              <Col xs={24} md={14}>
                <FormEmail
                  elRefs={elRefs}
                  form={form}
                  disabled={isDisabled}
                  required
                />
              </Col>
              {/* EMAIL */}
            </Row>
          </Card>
        </Col>

        <Col xs={24} lg={12}>
          <Card className="w-100 strong-border">
            <AddressComponent2 {...childProps} />
          </Card>
        </Col>
      </Row>

      {/*PREV/NEXT*/}
      {!isDisabled ? (
        <Flex justify={isMobile ? 'center' : 'flex-end'} align="center">
          <FormButton
            title="previous"
            className="mr-3"
            icon={<ArrowLeftOutlined />}
            style={{ width: 130 }}
            onClick={onPrevious}
          />

          <FormButton
            title="next"
            type={travellers?.length > 0 ? 'primary' : 'default'}
            icon={<ArrowRightOutlined />}
            style={{ width: 130 }}
            onClick={form.submit}
          />
        </Flex>
      ) : null}
      {/* PREV/NEXT */}
    </FormWrapper>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default MultiQuotationsCheckoutStep2;
