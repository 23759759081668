import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Row,
  Tooltip, ColorPicker
} from 'antd';
import {
  CameraOutlined,
  GlobalOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { generateHexColor } from 'utils/helpers';
import { translateX } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
} from 'components/jarvisly-components/others/FormComponents';
import entityService from 'services/entityService';
import axiosService from 'services/axiosService';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import JarvislyEntityForm from '../../../../../components/jarvisly-components/forms/Items/JarvislyEntityForm';

const AvatarWithActions = props => {
  const [backColor, setBackColor] = useState('#b9bcc4');
  const [foreColor, setForeColor] = useState('#ffffff');

  // console.log('FORE/BACK COLOR =>', foreColor, backColor);

  const onChangeColor = color => {
    const hexBackColor = color.toHexString();
    const hexForeColor = generateHexColor(hexBackColor, true);

    setBackColor(hexBackColor);
    setForeColor(hexForeColor);
  };

  const onSaveColor = isOpen => {
    console.log('isOpen ->', isOpen);
  };

  const handleUpload = () => {
    // Função para fazer o upload da foto
  };

  /*
    const AvatarColorPicker = () => {
      return (
        <div
          style={{
            position: 'absolute',
            // top: '50%', // Centraliza verticalmente
            bottom: -4,
            left: '50%', // Centraliza horizontalmente
            transform: 'translate(-50%, -50%)', // Ajusta para ficar bem no centro
            display: 'flex',
            gap: '1rem',
            background: 'rgba(0, 0, 0, 0.4)',
            padding: '0.5rem 1rem',
            borderRadius: '8px',
          }}
        >
          {/!* Ícone de Upload *!/}
          <Tooltip title="Upload Foto">
            <CameraOutlined
              onClick={handleUpload}
              style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}
            />
          </Tooltip>

          {/!* Ícone de Visualizar *!/}
          <Tooltip title="Visualizar Foto">
            <EyeOutlined
              onClick={handleView}
              style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}
            />
          </Tooltip>

          {/!* Ícone de Remover *!/}
          <Tooltip title="Remover Foto">
            <DeleteOutlined
              onClick={handleRemove}
              style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}
            />
          </Tooltip>
        </div>
      );
    };
  */

  const AvatarInitialName = () => {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%', // Centraliza verticalmente
          left: '50%', // Centraliza horizontalmente
          transform: 'translate(-50%, -50%)', // Ajusta para ficar bem no centro
          display: 'flex',
          gap: '1rem',
          background: backColor,
          color: foreColor,
          width: '90%',
          height: '90%',
          alignItems: 'center',
          borderRadius: '8px',
          fontSize: 68,
          fontWeight: 600,
        }}
      >
        <div style={{ width: '100%' }} className="text-center">
          RB
        </div>
      </div>
    );
  };

  const AvatarActionsTool = (
    <div
      style={{
        position: 'absolute',
        // top: '50%', // Centraliza verticalmente
        bottom: -8,
        left: '50%', // Centraliza horizontalmente
        transform: 'translate(-50%, -50%)', // Ajusta para ficar bem no centro
        display: 'flex',
        gap: '1rem',
        background: 'rgba(0, 0, 0, 0.4)',
        padding: '0.5rem 1rem',
        borderRadius: '8px',
      }}
    >
      {/* Ícone de Upload */}
      <Tooltip title="Upload Foto">
        <CameraOutlined
          onClick={handleUpload}
          style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}
        />
      </Tooltip>

      {/* Ícone de Remover */}
      {/*<Tooltip title="Remover Foto">*/}
      {/*  <CloseCircleOutlined*/}
      {/*    onClick={handleRemove}*/}
      {/*    style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}*/}
      {/*  />*/}
      {/*</Tooltip>*/}

      {/* Ícone color picker */}
      <Tooltip title="Alterar Cor">
        <ColorPicker
          rootClassName="color-picker-small"
          defaultValue={backColor}
          size="small"
          onChange={onChangeColor}
          // open={isPickerOpen}
          // trigger="click"
          value={backColor}
          onOpenChange={onSaveColor}
          // onBlur={() => setIsPickerOpen(false)} // Fecha quando tirar o foco
          // onOpenChange={v => setIsPickerOpen(v)}
        />
        {/*<FormatPainterOutlined*/}
        {/*  onClick={handleRemove}*/}
        {/*  style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}*/}
        {/*/>*/}
      </Tooltip>

      {/* Ícone de Visualizar */}
      {/*<Tooltip title="Visualizar Foto">*/}
      {/*  <EyeOutlined*/}
      {/*    onClick={handleView}*/}
      {/*    style={{ fontSize: '24px', color: '#fff', cursor: 'pointer' }}*/}
      {/*  />*/}
      {/*</Tooltip>*/}
    </div>
  );

  // const handleView = () => {
  //   Modal.info({
  //     title: 'Visualizar Avatar',
  //     content: (
  //       <img
  //         src="/img/avatars/thumb-15.jpg"
  //         alt="avatar"
  //         style={{ width: '100%' }}
  //       />
  //     ),
  //   });
  // };

  // const handleRemove = () => {
  //   // Função para remover a foto
  // };

  return (
    <div className="text-center">
      <div
        className="rounded p-2 bg-white shadow-sm mx-auto"
        style={{
          marginTop: '-3.5rem',
          maxWidth: `${props.avatarSize + 16}px`,
          position: 'relative', // Adiciona posição relativa ao container do avatar
        }}
      >
        <Avatar
          shape="square"
          size={props.avatarSize}
          // src="/img/avatars/thumb-15.jpg"
        />
        <AvatarInitialName />
        {/* Ícones sobre a foto */}
        {AvatarActionsTool}
      </div>
    </div>
  );
};

const ProfileInfo = props => {
  const showAvatar = true;

  // const [selectedColor, setSelectedColor] = useState('#00000066');

  const childProps = {
    ...props,
    // selectedColor,
    // setSelectedColor,
  };

  return (
    <Card>
      <Row justify={showAvatar ? 'center' : 'start'}>
        <Col sm={24} md={23}>
          <div className="d-md-flex">
            {showAvatar ? (
              <AvatarWithActions {...childProps} />
            ) : // <div
            //   className="rounded p-2 bg-white shadow-sm mx-auto"
            //   style={{
            //     marginTop: '-3.5rem',
            //     maxWidth: `${props.avatarSize + 16}px`,
            //   }}
            // >
            //   <Avatar
            //     shape="square"
            //     size={props.avatarSize}
            //     src="/img/avatars/thumb-15.jpg"
            //   />
            // </div>
            null}

            <div className={`w-100 ${showAvatar ? 'ml-md-4' : ''}`}>
              <Flex align="center" className="mb-3 text-md-left text-center">
                <h2 className="mb-0 mt-md-0 mt-2">Ella Robinson</h2>
                <div className="ml-md-3 mt-3 mt-md-0">
                  <Button size="small" type="primary">
                    Follow
                  </Button>
                  <Button size="small" className="ml-2">
                    Message
                  </Button>
                </div>
              </Flex>
              <Row gutter="16">
                <Col sm={24} md={8}>
                  <p className="mt-0 mr-3 text-muted text-md-left text-center">
                    It is a long established fact that a reader will be
                    distracted.
                  </p>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Row className="mb-2">
                    <Col xs={12} sm={12} md={9}>
                      <Icon
                        type={MailOutlined}
                        className="text-primary font-size-md"
                      />
                      <span className="text-muted ml-2">Email:</span>
                    </Col>
                    <Col xs={12} sm={12} md={15}>
                      <span className="font-weight-semibold">
                        ellarbae@coolmail.io
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={9}>
                      <Icon
                        type={PhoneOutlined}
                        className="text-primary font-size-md"
                      />
                      <span className="text-muted ml-2">Phone:</span>
                    </Col>
                    <Col xs={12} sm={12} md={15}>
                      <span className="font-weight-semibold">+12 123 1234</span>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Row className="mb-2 mt-2 mt-md-0 ">
                    <Col xs={12} sm={12} md={9}>
                      <Icon
                        type={HomeOutlined}
                        className="text-primary font-size-md"
                      />
                      <span className="text-muted ml-2">Address:</span>
                    </Col>
                    <Col xs={12} sm={12} md={15}>
                      <span className="font-weight-semibold">
                        Los Angeles, CA
                      </span>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={12} sm={12} md={9}>
                      <Icon
                        type={GlobalOutlined}
                        className="text-primary font-size-md"
                      />
                      <span className="text-muted ml-2">Website:</span>
                    </Col>
                    <Col xs={12} sm={12} md={15}>
                      <span className="font-weight-semibold">ellarbae.io</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

const ProfileForm = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { entityType } = props;

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <JarvislyEntityForm
        {...props}
        entityPersonType={entityType}
        context="__entity"
        additionalInfo="required"
      />
    </>
  );
};

const ProfileTab = props => {
  // console.log('PROPS ->', props);

  const avatarSize = 150;

  // const { isMobile } = props;
  const { selectedUser } = useSelector(s => s.authSlice);
  const { selectedCompany } = useSelector(s => s.authSlice);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [entityType, setEntityType] = useState('person');
  const [requiredFields, setRequiredFields] = useState();

  // console.log('selectedUser?.__entity ->', selectedUser);

  const childProps = {
    ...props,
    selectedEntity,
    setSelectedEntity,
    selectedCompany,
    entityType,
    setEntityType,
    setRequiredFields,
    requiredFields,
    document: selectedUser,
    selectedUser,
  };

  // useEffect to set form values when selectedUser changes
  const onSave = async ({ body }) => {
    console.log('body', body);
    const entity = await entityService.persistyEntity(
      body,
      selectedEntity,
      entityType,
      'user',
    );
    // console.log('entity', entity);

    const dataSet = {
      ...body,
      // name: entity?.name,
      // phoneNumber: entity?.phone,
      entityId: entity?._id,
    };
    if (dataSet?.entity) delete dataSet.entity;
    // console.log('dataSet', dataSet);

    const axiosOptions = {
      url: `/v1/auth/${selectedUser._id}`,
      data: dataSet,
    };

    await axiosService.put(axiosOptions);
    return dataSet;
  };

  return (
    <JarvislyFormProvider
      document={selectedUser}
      requiredFields={['cpf', 'name', 'phone', 'email']}
      onSave={onSave}
      showSubmitButton={true}
      abortComponentSave
    >
      <div className="container my-5">
        <ProfileInfo avatarSize={avatarSize} />
        <Card
          title={
            <div className="text-muted">{translateX('personal_data')}</div>
          }
        >
          <ProfileForm {...childProps} />
        </Card>
      </div>
    </JarvislyFormProvider>
  );
};

export default ProfileTab;
