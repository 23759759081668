import { useContext, useEffect, useState } from 'react';
import { Image, Space, Tooltip, Upload } from 'antd';
import {
  CameraOutlined,
  CopyOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import Icon from 'components/util-components/Icon';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import appService from 'services/appService';
import { clone, generateToken, translateX } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { storage } from 'auth/FirebaseAuth';
import axiosService from 'services/axiosService';

const Picture = props => {
  const {
    fileSize = 1024,
    maxFiles,
    accept,
    fileFolder = props?.attachmentType,
    attachments = [],
    tags = [],
    setAttachments,
    onUploadBefore,
    onUploadAfter,
    onUploadError,
    attachmentType = 'notAvailable',
  } = props;

  // component providers -------------------------------------------------------
  const { documentContext, setDocumentContext } = useContext(ModuleContext);

  // local variables -----------------------------------------------------------
  const { selectedSubscription } = useSelector(s => s.authSlice);
  const theme = useSelector(state => state.theme);
  const { STORAGE_BUCKET } = theme.FIREBASE_CONFIG;

  // component states ----------------------------------------------------------
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const t = Array.isArray(tags) ? [...tags] : [tags];
    const list = attachments?.filter(
      a => a?.tags?.some(tag => t.includes(tag)) && a?.action !== 'remove',
    );
    setFileList([...list]);
  }, [attachments]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const postAttachment = async file => {
    try {
      const _id = file?._id;
      delete file._id;
      const axiosOptions = {
        url: '/v1/attachments',
        _id,
        data: file,
      };
      return await axiosService.upsert(axiosOptions);
    } catch (error) {
      throw error;
    }
  };
  const handleUpload = async ({ onError, onSuccess, file }) => {
    if (maxFiles && fileList?.length > maxFiles) {
      file.status = 'error';
      return onError();
    }
    // file.size = value in bytes (bytes -> KB -> MB -> GB -> TB -> PB -> EB -> ZB -> YB
    const isLimitOk = file.size / 1024 < fileSize;
    if (!isLimitOk) {
      const message = translateX(
        'file_must_be_smaller_than',
        ['%SIZE%'],
        [fileSize],
      );
      appService.message('f', message);
      file.status = 'error';
      return onError();
    }
    onUploadBefore && (await onUploadBefore(file));

    // const storage = firebaseApp.storage();
    const f = buildFile(file);
    const filePath =
      `${selectedSubscription._id}/` +
      (fileFolder ? `${fileFolder}/${f.file}` : f.file);
    const fileRef = ref(storage, filePath);

    file.action = 'add';

    try {
      const snapshot = await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      file.bucket = STORAGE_BUCKET;
      file.rootFolder = selectedSubscription._id;
      file.uri = filePath;
      file.url = downloadURL;
      file.file = f.file;
      file.fileName = f.fileName;
      file.fileExtension = f.fileExtension;
      file.uniqueKey = f.uniqueKey;
      file.attachmentType = attachmentType;

      const data = {
        uniqueKey: f.uniqueKey,
        uid: file.uid,
        service: 'firebase-storage',
        bucket: STORAGE_BUCKET,
        rootFolder: selectedSubscription._id,
        uri: filePath,
        file: f.file,
        fileExtension: f.fileExtension,
        fileName: f.fileName,
        url: downloadURL,
        type: f.type,
        name: f.name,
        size: file.size,
        lastModified: f.lastModifiedDate,
        // thumbUrl: String,
        persisted: false,
        tags: Array.isArray(tags) ? [...tags] : [tags],
        attachmentType,
      };
      const [doc] = await postAttachment(data);

      file._id = doc?._id;
      file.persisted = doc?.persisted;
      onSuccess(null, snapshot, file);
      onUploadAfter && (await onUploadAfter(doc, file));
      setTimeout(() => {
        const attachmentsArray = [...attachments, { ...doc, action: 'add' }];
        setAttachments(attachmentsArray);
        setDocumentContext({ attachments: attachmentsArray });
      }, 20);
      return file; // Retorna o snapshot para casos em que você deseja obter informações adicionais sobre o upload.
    } catch (e) {
      onUploadError && (await onUploadError(e, file));
      onError(e);
    }
  };
  const onRemove = value => {
    return new Promise(resolve => {
      const askOptions = {
        Content: translateX('remove_attachment_description'),
        onOk: () => {
          const idx = attachments.findIndex(f => f.uid === value.uid);
          const attachs = clone(attachments);
          if (idx > -1) {
            // mark to exclude when user click in save because it has persisted
            attachs[idx].action = 'remove';
            // if (!attachs[idx]?.persisted) {
            //   const storageRef = ref(storage, value?.uri);
            //   deleteObject(storageRef).then();
            // }
            setDocumentContext({ attachments: attachs });
            setAttachments([...attachs]);
          }
          resolve();
        },
        onCancel: () => false,
      };
      appService.modal('c', translateX('remove_attachment'), askOptions);
    });
  };

  const handlePreview = async file => {
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const onClickCopyUrl = url => {
    appService.copyUrl(url);
  };

  return (
    <>
      <div
        className={props.className}
        style={{ overflow: 'hidden', border: '1px dotted #c0c0c0' }}
      >
        <ImgCrop>
          <Upload
            {...props}
            disabled={props.disabled || documentContext?.disablePicture}
            accept={accept}
            listType="picture-card"
            customRequest={handleUpload}
            onRemove={onRemove}
            onPreview={handlePreview}
            fileList={fileList}
            maxCount={maxFiles}
          >
            {fileList.length === 0 && (
              <Icon
                type={CameraOutlined}
                className="text-muted font-size-xxl"
              />
            )}

            {previewImage && (
              <Image
                preview={{
                  visible: previewOpen,
                  onVisibleChange: visible => setPreviewOpen(visible),
                  afterOpenChange: visible => !visible && setPreviewImage(''),
                  toolbarRender: (
                    _,
                    {
                      // image: { url },
                      transform: { scale },
                      actions: {
                        // onFlipY,
                        // onFlipX,
                        // onRotateLeft,
                        // onRotateRight,
                        onZoomOut,
                        onZoomIn,
                        // onReset,
                      },
                    },
                  ) => (
                    <Space size={12} className="toolbar-wrapper">
                      <Tooltip title={translateX('copy_image_url')}>
                        <CopyOutlined
                          onClick={() => onClickCopyUrl(previewImage)}
                        />
                      </Tooltip>
                      {/* <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} /> */}
                      <ZoomOutOutlined
                        disabled={scale === 1}
                        onClick={onZoomOut}
                      />
                      <ZoomInOutlined
                        disabled={scale === 50}
                        onClick={onZoomIn}
                      />
                      {/* <UndoOutlined onClick={onReset} /> */}
                    </Space>
                  ),
                }}
                src={previewImage}
              />
            )}
          </Upload>
        </ImgCrop>
      </div>
    </>
  );
};
export default Picture;

export function buildFile(file) {
  const typeParts = file?.name?.split('.');
  let name, type;

  if (typeParts?.length > 0) {
    type = typeParts?.[typeParts?.length - 1];
    typeParts.pop();
    name = typeParts.join('.');
  } else {
    name = file?.name || +new Date();
    type = 'n_a';
  }

  const uniqueKey = generateToken();
  const fileNameAndExtension = `${uniqueKey}.${type.toLowerCase()}`;

  return {
    uniqueKey,
    fileName: name,
    fileExtension: type.toLowerCase(),
    file: fileNameAndExtension,
    url: null,
    fileBase64: btoa(file),

    name: file?.name,
    type: file.type,
    fileSize: file.size,
  };
}
