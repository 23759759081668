import { createSlice } from '@reduxjs/toolkit';
import { HomeOutlined } from '@ant-design/icons';
import { buildAppConfigurations } from '../../configs/ConfigIndex';
import apiConfig from '../../auth/FetchInterceptor';

export const DEFAULT_HOME_MODULE = {
  name: 'home',
  title: 'home',
  icon: HomeOutlined,
  menus: {
    home: [
      {
        path: `/app/home`,
        title: 'home',
        icon: HomeOutlined,
        isGroupTitle: true,
        submenu: [
          {
            key: '/app/home/welcome',
            path: `/app/home/welcome`,
            title: 'welcome',
            icon: HomeOutlined,
            submenu: [],
          },
        ],
      },
    ],
  },

  url: '/app/home/welcome',
  routes: ['/app/home/welcome'],
};

export const initialState = {
  moduleType: null, // "list", "document"
  moduleFreeze: false, // freeze module without show the loading
  selectedModule: null,
  availableModules: null,
  navigateMenu: DEFAULT_HOME_MODULE?.menus?.home || [],
  routesProcessed: false,
  routes: DEFAULT_HOME_MODULE?.routes || [],
  selectedRouteInfo: null,
  listState: null,
  components: null,
  apiRequest: {
    initialQs: null,
    currentQs: null,
  },
  apiResponse: null,
  showMyStates: false,
  showBreadcrumb: false,
  showPageHeader: true,
  disableSubscriptionCombo: false,
  documentState: null,
  parameters: null,
  loadingModule: false,
  moduleLoaded: false,
};

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    rdxSetModuleLoaded: (state, action) => {
      state.moduleLoaded = action.payload;
    },

    rdxSetLoadingModule: (state, action) => {
      state.loadingModule = action.payload;
    },

    rdxSetDisableSubscriptionCombo: (state, action) => {
      state.disableSubscriptionCombo = action.payload;
    },

    rdxSetShowPageHeader: (state, action) => {
      state.showPageHeader = action.payload;
    },

    rdxSetShowBreadcrumb: (state, action) => {
      state.showBreadcrumb = action.payload;
    },

    rdxSetShowMyStates: (state, action) => {
      state.showMyStates = action.payload;
    },

    rdxSetApiRequest: (state, action) => {
      state.apiRequest = action.payload;
    },

    rdxSetApiResponse: (state, action) => {
      state.apiResponse = action.payload;
    },

    rdxSetModuleFreeze: (state, action) => {
      state.moduleFreeze = action.payload;
    },

    rdxSetModuleType: (state, action) => {
      state.moduleType = action.payload;
    },

    rdxSetRouteInfo: (state, action) => {
      state.selectedRouteInfo = action.payload;
    },

    rdxSetAppConfigurations: (state, action) => {
      const config = buildAppConfigurations(action.payload);
      const configRoutes = config.routes;
      const routesSet = new Set([...initialState?.routes, ...configRoutes]);
      const parsedRoutes = [...routesSet].sort((a, b) => b.localeCompare(a));
      const navigateMenu = config?.navigateMenu || [];
      const defaultHomePath = initialState?.navigateMenu[0].path;
      const defaultHomeMenu = navigateMenu.find(m => m.path === defaultHomePath)
        ? []
        : initialState?.navigateMenu;

      const parsedNavigateMenu = [...defaultHomeMenu, ...navigateMenu];

      state.availableModules = config.availableModules;
      state.navigateMenu = parsedNavigateMenu;
      state.routes = parsedRoutes;
      state.routesProcessed = true;
    },

    rdxSetModule: (state, action) => {
      // selectedModule
      state.selectedModule = action.payload;

      apiConfig.defaults.headers['x-module'] = action.payload?.rootName || null;

      // listState
      const ls = action.payload?.listState || {};

      const listState = {
        ...ls,
        showNumberOfLine: localStorage.getItem('showNumberOfLine') === 'true',
      };

      if (listState?.viewMode) {
        listState.viewMode = Array.isArray(listState.viewMode)
          ? listState.viewMode?.[0] || 'list'
          : listState.viewMode;
      }

      state.listState = listState;

      // documentState
      state.documentState = action.payload?.documentState || {};

      // Components
      state.components = action.payload?.components;

      // apiRequest
      state.apiRequest = {
        initialQs: action.payload?.initialRequest || null,
        currentQs: action.payload?.initialRequest || null,
      };

      // apiResponse
      state.apiResponse = null;
    },

    rdxSetListState: (state, action) => {
      const listState = action.payload;

      if (listState?.viewMode) {
        listState.viewMode = Array.isArray(listState.viewMode)
          ? listState.viewMode?.[0] || 'list'
          : listState.viewMode;
      }

      state.listState = listState;
    },

    rdxSetDocumentState: (state, action) => {
      state.documentState = action.payload;
    },

    rdxSetComponents: (components, action) => {
      components.components = action.payload;
    },

    rdxResetModuleSlice: state => {
      for (const [key, value] of Object.entries(initialState))
        state[key] = value;
    },

    rdxSetParameters: (state, action) => {
      state.parameters = action.payload;
    },
  },
});

export const {
  rdxSetModuleLoaded,
  rdxSetLoadingModule,

  // rdxSetLoading,
  rdxSetModule,
  rdxSetAppConfigurations,
  rdxResetModuleSlice,
  rdxSetRouteInfo,
  rdxSetListState,
  rdxSetDocumentState,
  rdxSetComponents,
  rdxSetDocumentComponents,
  rdxSetModuleType,
  rdxSetModuleFreeze,
  rdxSetApiRequest,
  rdxSetApiResponse,
  rdxSetShowMyStates,
  rdxSetShowBreadcrumb,
  rdxSetShowPageHeader,
  rdxSetDisableSubscriptionCombo,
  rdxSetParameters,
} = moduleSlice.actions;

export default moduleSlice.reducer;
