import {
  JarvislyEmail,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyPhone,
  JarvislyWebsite,
} from 'components/jarvisly-components/others/FormComponents';
import { Col, Row } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React, { useContext, useEffect, useState } from 'react';
import { isValidEmail, isValidPhoneNumber } from 'utils/helpers';

// COMPONENT *******************************************************************
// *****************************************************************************

const JarvislyNewContactForm = props => {
  const { form, requiredFields } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    context,
    showName,
    showWebsite,
    contactNameRequired = false,
    phoneProfile,
    phoneLabel,
    emailLabel,
    style,
  } = props;

  // local variables -----------------------------------------------------------

  // Verifica se "phoneEmail" está presente nos requiredFields, com ou sem context
  const phoneOrEmailRequired = requiredFields?.some(
    field => field === 'phoneEmail' || field === `${context}.phoneEmail`,
  );

  let nameMd = 8;
  let phoneMd = 8;
  let emailMd = 16;
  let websiteMd = 8;

  if ((showName && !showWebsite) || (!showName && showWebsite)) {
    phoneMd = 6;
    emailMd = 10;
  } else if (showName && showWebsite) {
    nameMd = 6;
    phoneMd = 6;
    emailMd = 6;
    websiteMd = 6;
  }

  // component states ----------------------------------------------------------
  const [isPhoneRequired, setIsPhoneRequired] = useState(false);
  const [isEmailRequired, setIsEmailRequired] = useState(false);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setIsPhoneRequired(requiredFields?.includes('phoneEmail'));
    setIsEmailRequired(requiredFields?.includes('phoneEmail'));
    onChangeValidateRequired();
  }, [requiredFields]);

  // methods -------------------------------------------------------------------
  const onChangeValidateRequired = () => {
    if (!phoneOrEmailRequired) {
      setIsPhoneRequired(
        requiredFields?.includes(context ? [context, 'phone'] : 'phone'),
      );
      setIsEmailRequired(
        requiredFields?.includes(context ? [context, 'email'] : 'email'),
      );

      return;
    }

    const phone = form?.getFieldValue(context ? [context, 'phone'] : 'phone');
    const isPhoneValid = isValidPhoneNumber(phone);
    const email = form?.getFieldValue(context ? [context, 'email'] : 'email');
    const isEmailValid = isValidEmail(email);

    setIsPhoneRequired(!isEmailValid);
    setIsEmailRequired(!isPhoneValid);

    if (isPhoneValid)
      form?.validateFields([context ? [context, 'email'] : 'email']);
    if (isEmailValid)
      form?.validateFields([context ? [context, 'phone'] : 'phone']);
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Row gutter={ROW_GUTTER} style={style}>
      {/* NAME */}
      {showName ? (
        <Col xs={24} md={nameMd}>
          <JarvislyInput
            name="contactName"
            label="contact_name"
            context={context}
            required={contactNameRequired}
          />
        </Col>
      ) : null}
      {/* NAME */}

      {/* PHONE */}
      <Col xs={24} md={phoneMd}>
        <JarvislyPhone
          context={context}
          required={isPhoneRequired}
          profile={phoneProfile}
          label={phoneLabel}
          onChange={onChangeValidateRequired}
        />
      </Col>
      {/* PHONE */}

      {/* EMAIL */}
      <Col xs={24} md={emailMd}>
        {/*<Col xs={24} md={6}>*/}
        <JarvislyEmail
          context={context}
          required={isEmailRequired}
          label={emailLabel}
          onChange={onChangeValidateRequired}
        />
      </Col>
      {/* EMAIL */}

      {/* WEBSITE */}
      {showWebsite ? (
        <Col xs={24} md={websiteMd}>
          <JarvislyWebsite context={context} />
        </Col>
      ) : null}
      {/* WEBSITE */}
    </Row>
  );
};

export default JarvislyNewContactForm;
