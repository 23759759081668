import { translateX } from 'utils/helpers';
import { Card, Flex, Table } from 'antd';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import { PlusOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';
import axiosService from 'services/axiosService';

const ContactTab = props => {
  const {
    isWorking,
    setIsWorking,
    document,
    mode,
    setShowContactsModal,
    setSelectedRecord,
    refreshDocument,
  } = props;

  // local variables -----------------------------------------------------------
  const isDisabled = isWorking || mode === 'view';
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onAdd = () => {
    setSelectedRecord(null); // Clear selected record for adding new dependent
    setShowContactsModal(true); // Open modal
  };

  const onEdit = record => {
    setSelectedRecord(record);
    setShowContactsModal(true);
  };

  const onDelete = async record => {
    setIsWorking(true);
    if (record?.entityId) {
      if (
        record.dataProfile.length === 1 &&
        record.dataProfile.includes('contact')
      ) {
        await axiosService
          .delete({
            url: '/v1/entity',
            _id: record.entityId,
            headers: { 'x-forcepermanentlydeletation': true },
          })
          .then();
      }

      const axiosOptions = {
        url: `/v1/entity/add-to-set/${document?._id}/$pull/moreContacts.entityId`,
        data: { _id: record?.entityId },
      };
      await axiosService.put(axiosOptions);
    }

    refreshDocument();
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* Debugging: Output the dependents data */}
      <Card>
        <Table
          rowKey={record => record._id} // Assuming each dependent has a unique _id
          columns={tableColumns.filter(x => !x.hidden)}
          dataSource={document?.moreContacts}
          pagination={false}
          loading={!document || isWorking}
        />

        <Flex justify='center'>
          <FormButton
            title="add_contact"
            className="m-3"
            style={{ width: 200 }}
            icon={<PlusOutlined />}
            disabled={isDisabled}
            onClick={onAdd}
          />
        </Flex>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        title: translateX('name'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                shape="circle"
                title={capitalize(record?.name)}
                subtitle={translateX(record?.gender)}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },
      {
        title: translateX('mobile'),
        dataIndex: 'mobile',
        render: (_, record) => {
          return (
            <>
              {record?.mobile ? (
                <div>{record?.mobile}</div>
              ) : (
                <h1>{translateX('n_a')}</h1>
              )}
            </>
          );
        },
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              recordId={record?.entityId}
              isDisabled={isDisabled}
              deleteTitle={translateX(
                'remove_this_item',
                ['%ITEM%'],
                ['contact'],
              )}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default ContactTab;
