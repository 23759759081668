import { DeploymentUnitOutlined } from '@ant-design/icons';
import MultiLeadsList from './List/List';
import MultiLeadsForm from './Form/Form';
import { DefaultFormHeaderColumn1 } from 'components/jarvisly-module/Form/FormHeader';
import { MultiLeadsFormHeaderColumn2, MultiLeadsFormHeaderColumn3 } from './Components';
import dayjs from 'dayjs';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MENU_GROUP = 'commercial';
const MODULE_TITLE = 'leads';
const MODULE_ICON = DeploymentUnitOutlined;
export const LEADS_MODULE_API = '/v1/lead';
export const APPMULTI_LEADS_ROUTE = `/app/${MENU_GROUP}/travel-insurance-${MODULE_TITLE}`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const MULTI_LEADS_MODULE = {
  rootName: 'leads',
  name: 'multiLeads',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  urlForm: null,
  api: LEADS_MODULE_API,

  url: APPMULTI_LEADS_ROUTE,
  routes: [`${APPMULTI_LEADS_ROUTE}/:_id`, APPMULTI_LEADS_ROUTE],

  components: {
    dashboard: null,
    list: props => <MultiLeadsList {...props} />,
    form: (props, ref) => <MultiLeadsForm {...props} ref={ref} />,
    // formHeaderSubtitle: props => <MultiLeadsDocumentSubtitle {...props} />,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: props => <MultiLeadsFormHeaderColumn2 {...props} />,
    formHeaderColumn3: props => <MultiLeadsFormHeaderColumn3 {...props} />,
    formHeaderTags: null,
    // formHeaderCustomIcons: props => (
    //   <DefaultFormHeaderCustomIcons {...props} showStatusIcon />
    // ),
    settings: null,
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {
      dataProfile: ['customer'],
      dataType: ['person'],
    },
    filterOptions: {
      dataProfile: ['customer'],
      dataType: ['person'],
    },

    pagination: {
      pageNumber: 1, // current page
      pageSize: ['10', '20', '50', '100'].includes(
        localStorage.getItem('pageSize'),
      )
        ? Number(localStorage.getItem('pageSize'))
        : 10,
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },
    sorterOptions: ['name', '_metadata.audit.updatedAt', 'destiny', 'status'],

    search: {},

    textSearch: {},
  },

  listState: {
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  documentState: {
    disableLockForEdit: false,
    initTab: 'quotations',
    fieldName: 'name',
    newDocumentTitle: 'new_lead',
    autoFill: false,
    actions: [
      // 'new',
      // 'edit',
      'reload',
      // 'divider',
      // 'duplicate',
      'file',
      'remove',
      'permanently_remove',
      // 'divider',
      // 'document_status',
    ],
    refreshParametersOnSave: true,

    mockDocument: {},
  },
};

export default MULTI_LEADS_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
    if (doc?.followUp && Array.isArray(doc.followUp)) {
      doc.followUp = doc.followUp.map(f => {
        f.label = (
          <>
            <div>
              Data de criação:
              {dayjs(f.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </div>
          </>
        );
        f.children = (
          <>
            <div>Comentario: {f.text}</div>
          </>
        );
        return f;
      });
    }
}
