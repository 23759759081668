import { Flex, Table } from 'antd';
import { translateX } from 'utils/helpers';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import { PlusOutlined } from '@ant-design/icons';

import bikeImage from 'assets/images/bike.png';
import { capitalize } from 'lodash';
import appsindicoService from 'services/appsindicoService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const JarvislyBicyclesIndex = props => {
  // props deconstruction ------------------------------------------------------
  const {
    _id,
    isWorking,
    setIsWorking,
    document,
    mode,
    setShowBicyclesModal,
    setSelectedRecord,
    refreshDocument,
  } = props;

  // local variables -----------------------------------------------------------
  const isDisabled = isWorking || mode === 'view';
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onAdd = () => {
    setSelectedRecord(null);
    setShowBicyclesModal(true);
  };

  const onEdit = record => {
    setSelectedRecord(record);
    setShowBicyclesModal(true);
  };

  const onDelete = async record => {
    setIsWorking(true);
    await appsindicoService.removeArrayId(
      record,
      'v1/units',
      _id,
      'bicyclesIds',
      'v1/bicycles',
    );
    refreshDocument(_id, true);
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Table
        rowKey={record => record._id} // Assuming each dependent has a unique _id
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={document?.__bicycles || []}
        pagination={false}
        // loading={!document || isWorking}
      />

      <Flex justify={appService.isMobile() ? 'center' : 'end'}>
        <FormButton
          title="add_bicycle"
          className="m-3"
          style={{ width: 200 }}
          icon={<PlusOutlined />}
          disabled={isDisabled}
          onClick={onAdd}
        />
      </Flex>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      {
        title: translateX('model'),
        dataIndex: 'model',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                imageUrl={bikeImage}
                title={record?.model?.toUpperCase()}
                subtitle={capitalize(record?.brand)}
                shape="square"
                style={{ padding: 10 }}
                backColor="#ecf2fe"
              />
            </div>
          );
        },
      },
      {
        title: translateX('rim'),
        dataIndex: 'rim',
        render: (_, record) => {
          return (
            <>
              <div>{record?.rim}</div>
            </>
          );
        },
      },
      {
        title: translateX('color'),
        dataIndex: 'color',
        render: (_, record) => {
          return (
            <>
              <div>{translateX(record?.color)}</div>
            </>
          );
        },
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              recordId={record?.entityId}
              isDisabled={isDisabled}
              deleteTitle={translateX(
                'remove_this_item',
                ['%ITEM%'],
                ['Bicycles'],
              )}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default JarvislyBicyclesIndex;
