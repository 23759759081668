import MultiOrdersList from './List/List';
import MultiOrdersForm from './Form/Form';
import {
  MultiOrdersFormHeaderColumn1,
  MultiOrdersFormHeaderColumn2,
  MultiOrdersFormHeaderColumn3,
  MultiOrdersFormHeaderSubtitle,
  MultiOrdersFormHeaderTags,
  MultiOrdersFormHeaderTitle,
} from './Components';
import { FiShoppingBag } from 'react-icons/fi';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MENU_GROUP = 'commercial';
const MODULE_TITLE = 'orders';
const MODULE_ICON = FiShoppingBag;
export const MULTI_ORDERS_API = '/v1/order';
export const APPMULTI_ORDERS_ROUTE = `/app/${MENU_GROUP}/travel-insurance-${MODULE_TITLE}`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const MULTI_ORDERS_MODULE = {
  rootName: 'orders',
  name: 'multiOrders',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  urlForm: null,
  api: MULTI_ORDERS_API,

  url: APPMULTI_ORDERS_ROUTE,
  routes: [`${APPMULTI_ORDERS_ROUTE}/:_id`, APPMULTI_ORDERS_ROUTE],

  components: {
    dashboard: null,
    list: props => <MultiOrdersList {...props} />,
    form: (props, ref) => <MultiOrdersForm {...props} ref={ref} />,
    formHeaderTitle: props => <MultiOrdersFormHeaderTitle {...props} />,
    formHeaderSubtitle: props => <MultiOrdersFormHeaderSubtitle {...props} />,
    formHeaderColumn1: props => <MultiOrdersFormHeaderColumn1 {...props} />,
    formHeaderColumn2: props => <MultiOrdersFormHeaderColumn2 {...props} />,
    formHeaderColumn3: props => <MultiOrdersFormHeaderColumn3 {...props} />,
    formHeaderTags: props => <MultiOrdersFormHeaderTags {...props} />,
    formHeaderCustomIcons: null,
    settings: null,
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},
    filterOptions: {
      status: [
        'waiting_for_payment',
        // 'late_payment',
        'waiting_for_voucher',
        'done',
        'cancelled',
        'cancelled_by_customer',
      ],
    },

    pagination: {
      pageNumber: 1, // current page
      pageSize: ['10', '20', '50', '100'].includes(
        localStorage.getItem('pageSize'),
      )
        ? Number(localStorage.getItem('pageSize'))
        : 10,
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },
    sorterOptions: [
      'customer',
      'destiny',
      '_metadata.audit.createdAt',
      '_metadata.audit.updatedAt',
      '_metadata.counter',
      'status',
    ],

    search: {},

    textSearch: {},
  },

  listState: {
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  documentState: {
    disableLockForEdit: false,
    initTab: 'record',
    fieldName: '_metadata.__documentCode',
    newDocumentTitle: 'new_order',
    autoFill: false,
    actions: [
      // 'new',
      // 'edit',
      'reload',
      // 'divider',
      // 'duplicate',
      'file',
      'remove',
      'permanently_remove',
      // 'divider',
      // 'document_status',
      'finish_order',
      'resend_email',
    ],
    refreshParametersOnSave: true,

    mockDocument: {},
  },
};

export default MULTI_ORDERS_MODULE;

function decorateDocument(doc) {
  return doc;
}
