import { Card, Col, Row } from 'antd';
import {
  JarvislyCpf,
  JarvislyDatePicker,
  JarvislyEmail,
  JarvislyInput,
  JarvislyPhone,
  JarvislySelect,
  JarvislyTitle,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { returnOnlyNumbers, translateX } from 'utils/helpers';
import appService from 'services/appService';
import entityService from 'services/entityService';
import appsindicoService from 'services/appsindicoService';
import { useSelector } from 'react-redux';
import { forwardRef, useImperativeHandle } from 'react';
import axiosService from 'services/axiosService';
import dayjs from 'dayjs';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const UnitsTitularResidentTab = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, refreshDocument } = props;

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);
  const genderOptions = appsindicoService.buildGenderOptions(parameters);

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onCpfChange = async ev => {
    const value = typeof ev === 'object' ? ev?.target?.value : ev;
    const eType = entityService.getEntityTypeByCpfCnpj(value);
    if (!eType) return true;
    const cpf = returnOnlyNumbers(value);
    const doc = await entityService.getEntityByField(cpf);
    if (!doc) return true;
    let title = translateX(
      'document_exists',
      ['%CPF_CNPJ%'],
      [doc?.cpf ? 'CPF' : 'CNPJ'],
    );
    const options = {
      Content: translateX(
        'document_exists_description',
        ['%CPF_CNPJ%'],
        [doc?.cpf ? 'CPF' : 'CNPJ'],
      ),
      onCancel: () => {},
      onOk: () => {},
    };
    appService.modal('q', title, options);
    return false;
  };

  const onSaveTab = async body => {
    const titular = body?.__titular || {};

    try {
      const titularOptions = {
        url: '/v1/titular', // URL da API_
        _id: document?.titularId,
        data: {
          name: titular?.name,
          gender: titular?.gender,
          cpf: titular?.cpf,
          rg: titular?.rg,
          birthdate: titular?.birthdate,
          mobile: titular?.mobile,
          email: titular?.email,
        },
      };
      const [titularResponse] = await axiosService.upsert(titularOptions);
      if (titularResponse && document?._id) {
        const unitsOptions = {
          url: '/v1/units', // URL da API_
          _id: document?._id,
          data: {
            titularId: titularResponse._id,
          },
        };
        await axiosService.upsert(unitsOptions);
      }
      refreshDocument();
    } catch (error) {
      console.error(error);
    }
  };

  useImperativeHandle(ref, () => ({
    onSaveTab,
  }));

  const disabledDate = current => {
    // Pode desabilitar datas do dia atual para frente
    return current && current > dayjs().endOf('day');
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card title={translateX('titular_resident')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={16} lg={16}>
            <JarvislyInput
              label="full_name"
              name={['__titular', 'name']}
              required
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislySelect
              name={['__titular', 'gender']}
              options={genderOptions}
              showSearch
              allowClear
              required
            />
          </Col>
        </Row>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyCpf
              name={['__titular', 'cpf']}
              label={translateX('cpf')}
              onChange={onCpfChange}
              required
            />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyInput name={['__titular', 'rg']} />
          </Col>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyDatePicker
              name={['__titular', 'birthdate']}
              disabledDate={disabledDate}
              required
            />
          </Col>
        </Row>
        <JarvislyTitle title={'contacts'} />
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyPhone
              label="mobile"
              required
              name={['__titular', 'mobile']}
            />
          </Col>
          <Col xs={24} sm={16} lg={16}>
            <JarvislyEmail
              label="email"
              required
              name={['__titular', 'email']}
            />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
});

export default UnitsTitularResidentTab;
