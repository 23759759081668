import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Badge, Tabs } from 'antd';
import { setLocale } from 'utils/helpers';
import {
  AppstoreAddOutlined,
  CreditCardOutlined,
  DatabaseOutlined,
  TeamOutlined,
  UserOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import app from 'configs/ConfigIndex';
import AccountOrganizationsList from './organizationsTab/List/ListIndex';
import appService from 'services/appService';
import useUrl from 'components/jarvisly-components/hooks/urlHooks';
import AccountWhatsAppList from 'modules/app-views/shared-modules/account/whatsappTab/List/ListIndex';
import moduleService from 'components/jarvisly-module/ModuleService';
import ProfileTab from './profileTab/ProfileTab';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const privateTabs = ['team', 'billings'];
if (app.ENABLE_MARKETPLACE) privateTabs.push('resources');
const publicTabs = ['profile', 'organizations'];

// COMPONENT *******************************************************************
// *****************************************************************************

const AccountIndex = props => {
  // props deconstruction ------------------------------------------------------
  let { selectedSubscription, selectedModule } = props;

  // local variables -----------------------------------------------------------
  const navigate = useNavigate();
  const url = useUrl();

  // component states ----------------------------------------------------------
  const [activeTab, setActiveTab] = useState();

  // local variables -----------------------------------------------------------
  const [searchParams] = useSearchParams();

  // -3: carrinho e demo (vencido), 1: carrinho, 3: carrinho e demo (vigente)
  const shopCartCounter = selectedSubscription?.marketplace?.filter(m =>
    [-3, 1, 3].includes(m.status),
  ).length;
  const tabItems = buildTabItems();
  const userProfile = selectedSubscription?.profile || 'guest';

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    let tab = searchParams.get('tab');

    appService.initRoute({
      moduleType: null,
      showPageHeader: false,
      showBreadcrumb: false,
      disableSubscriptionCombo: false,
    });

    if (
      !tab ||
      (!privateTabs.includes(tab) && !publicTabs.includes(tab)) ||
      (userProfile !== 'owner' &&
        (privateTabs.includes(tab) || privateTabs.includes(activeTab)))
    ) {
      tab = selectedModule?.listState?.initTab;
      if (tab) url.updateUrlQueryParams({ tab }, true);
    } else {
      url.updateUrlQueryParams({ tab }, true);
    }

    setActiveTab(tab);
    moduleService.loadingModule(false);
  }, [selectedSubscription]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onTabClick = tab => {
    if (activeTab !== tab) {
      navigate(`?tab=${tab}`, { replace: true });
      setActiveTab(tab);
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <div className="card-container">
      <Tabs
        type="line"
        tabPosition="top"
        activeKey={activeTab}
        items={tabItems.filter(x => !x.hide)}
        onTabClick={onTabClick}
      />
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItems() {
    return [
      {
        key: 'profile',
        hide: false,
        label: (
          <>
            <UserOutlined />
            <span>{setLocale('my_profile')}</span>
          </>
        ),
        // children: (<AccountProfile/>),
        // children: <h1>PROFILE</h1>,
        children: <ProfileTab {...props} activeTab={activeTab} />,
      },

      {
        key: 'organizations',
        hide: false,
        label: (
          <>
            <DatabaseOutlined />
            <span>{setLocale(app.PROFILE.TITLE_ORGANIZATIONS_TAP)}</span>
          </>
        ),
        children: <AccountOrganizationsList {...props} activeTab={activeTab} />,
        // children: <div>Organizations List</div>,
        // children: <ListIndex {...props} />,
      },

      {
        key: 'resources',
        hide:
          selectedSubscription?.profile !== 'owner' || !app.ENABLE_MARKETPLACE,
        label: (
          <>
            <AppstoreAddOutlined />
            <span>{setLocale('resources')}</span>
          </>
        ),
        // children: (<AccountMarketplace/>),
        children: <div>Resources</div>,
      },

      {
        key: 'team',
        hide: selectedSubscription?.profile !== 'owner',
        label: (
          <>
            <TeamOutlined />
            <span>{setLocale('team')}</span>
          </>
        ),
        children: <div>Team</div>,
        // children: <AccountTeams />,
      },

      {
        key: 'billings',
        hide: selectedSubscription?.profile !== 'owner',
        label: (
          <Badge count={shopCartCounter}>
            <CreditCardOutlined />
            <span className="mr-2">{setLocale('billings')}</span>
          </Badge>
        ),
        children: <div>Billing</div>,
        // children: <AccountBillings />,
      },

      {
        key: 'whatsapp',
        hide: !app.WHATSAPP_INTEGRATED,
        label: (
          <>
            <WhatsAppOutlined />
            <span>{setLocale('whatsapp')}</span>
          </>
        ),
        children: <AccountWhatsAppList {...props} activeTab={activeTab} />,
      },
    ];
  }
};

// EXPORT **********************************************************************
// *****************************************************************************

export default AccountIndex;
