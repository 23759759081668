import { useEffect, useState } from 'react';
import appService from 'services/appService';
import Loading from 'components/jarvisly-components/others-old/Loading';
import { Affix, Button, notification, Row, Space } from 'antd';
import { CardPlan, MultiQuotationsHeader } from '../Components';
import { useModuleContext } from 'components/jarvisly-module/ModuleContext';
import MultiQuotationCompareModal from './Modals/CompareModal';
import MultiQuotationCheckoutModal from '../Form/Form';
import QuotationsFilter from './QuotationsFilter';
import { useDispatch, useSelector } from 'react-redux';
import moduleService from '../../../../../components/jarvisly-module/ModuleService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiQuotationsIndex = props => {
  // providers context ---------------------------------------------------------
  const { isWorking, documentContext, selectedSubscription, setDataList } =
    useModuleContext();

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------
  const { filter, listPlans } = documentContext;
  const [api, contextHolder] = notification.useNotification();
  const isMobile = appService.isMobile();
  const { loadingModule } = useSelector(s => s.moduleSlice);
  const dispatch = useDispatch();

  // component states ----------------------------------------------------------
  const [filteredData, setFilteredData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [comparePlans, setComparePlans] = useState([]);
  const [compare, setCompare] = useState([]);
  const [compareColumns, setCompareColumns] = useState([]);
  const [compareData, setCompareData] = useState([]);

  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);

  const [travellers, setTravellers] = useState([]);
  const [buyer, setBuyer] = useState(null);

  // sorter filter
  const [classification, setClassification] = useState('best_seller');
  const [travelProfile, setTravelProfile] = useState(null);
  const [operator, setOperator] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    appService.initRoute({
      moduleType: null,
      showPageHeader: false,
      showBreadcrumb: false,
      disableSubscriptionCombo: false,
    });

    moduleService.loadingModule(false);
  }, [selectedSubscription]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    closeNotification(true);
  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isWorking) {
      setIsFetching(true);
    } else {
      setTimeout(() => setIsFetching(false), 600);
    }
  }, [isWorking]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFilteredData(listPlans);
    setClassification('best_seller');
    setTravelProfile(null);
    setOperator(null);
  }, [listPlans]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (compare.length > 1) {
      openNotification();
    } else {
      closeNotification();
    }
  }, [compare]); // eslint-disable-line react-hooks/exhaustive-deps
  // methods -------------------------------------------------------------------

  const closeNotification = forceClear => {
    api.destroy('compare-box');
    if (forceClear) {
      setCompare([]);
      setComparePlans([]);
      setCompareData([]);
      setCompareColumns([]);
    }
  };

  const onClickComparePlans = () => {
    closeNotification();
    setShowCompareModal(true);
  };

  const openNotification = () => {
    const key = 'compare-box';
    const btn = (
      <Space>
        <Button
          type="link"
          size="small"
          onClick={() => closeNotification(true)}
        >
          Cancelar
        </Button>
        <Button type="primary" size="small" onClick={onClickComparePlans}>
          Comparar
        </Button>
      </Space>
    );
    api.info({
      message: 'Compare os planos selecionados',
      // description: plansToCompare,
      duration: 0,
      closeIcon: null,
      // icon: <InfoCircleTwoTone />,
      btn,
      key,
      onClose: closeNotification,
    });
  };

  const onClickOpenCheckoutModal = plan => {
    closeNotification(true);
    setSelectedRecord(plan);
    setShowCheckoutModal(true);
  };

  // UI COMPONENT --------------------------------------------------------------
  const childProps = {
    ...props,
    ...useModuleContext(),
    buyer,
    filter,
    setBuyer,
    travellers,
    setTravellers,
    onClickOpenCheckoutModal,
    compare,
    comparePlans,
    selectedRecord,
    setSelectedRecord,
    setCompare,
    setComparePlans,
    setCompareData,
    setCompareColumns,
    setShowCompareModal,

    classification,
    setClassification,
    openFilter,
    setOpenFilter,
    travelProfile,
    setTravelProfile,
    operator,
    setOperator,

    filteredData,
    setFilteredData,
  };

  return (
    <div>
      {isMobile ? (
        <MultiQuotationsHeader {...childProps} />
      ) : (
        <Affix offsetTop={95.3}>
          <MultiQuotationsHeader {...childProps} />
        </Affix>
      )}

      <MultiQuotationCompareModal
        filter={filter}
        compare={compare}
        showModal={showCompareModal}
        setShowModal={setShowCompareModal}
        compareData={compareData}
        compareColumns={compareColumns}
        openNotification={openNotification}
      />

      <MultiQuotationCheckoutModal
        {...childProps}
        showModal={showCheckoutModal}
        setShowModal={setShowCheckoutModal}
        record={selectedRecord}
        setSelectedRecord={setSelectedRecord}
      />

      {contextHolder}

      {listPlans?.length > 0 ? (
        <Row justify="center">
          <div className="mb-2 w-100">
            <QuotationsFilter
              {...childProps}
              dataListing={listPlans}
              setDataListing={setDataList}
            />
          </div>
        </Row>
      ) : null}

      <Loading
        isFetching={!loadingModule && isFetching}
        data={filteredData}
        showNoDataIcon
        marginTop={100}
        progressTop={-300}
      />

      {filteredData
        ? filteredData?.map(plan => (
            <div
              key={plan?._id}
              style={{ marginTop: isMobile ? 48 : 16, marginBottom: 36 }}
            >
              <CardPlan {...childProps} plan={plan} key={plan._id} />
            </div>
          ))
        : null}
    </div>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default MultiQuotationsIndex;
