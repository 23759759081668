import {
  JarvislyDatePicker,
  JarvislyEmail,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyPhone,
  JarvislySelect,
  JarvislyTitle,
} from '../../others/FormComponents';
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import { Card, Col, Modal, Row } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useSelector } from 'react-redux';
import appsindicoService from 'services/appsindicoService';
import dayjs from 'dayjs';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { record, showModal, setShowModal, afterClose, isDisabled, updating } =
    props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !record?._id
      ? translateX('new_dependents')
      : translateX('edit_dependents');

  const { parameters } = useSelector(s => s.moduleSlice);
  const genderOptions = appsindicoService.buildGenderOptions(parameters);
  const kinshipOptions = appsindicoService.buildkinshipOptions();

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form?.resetFields();
    if (record) form?.setFieldsValue(record);
  }, [record, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  const disabledDate = current => {
    // Pode desabilitar datas do dia atual para frente
    return current && current > dayjs().endOf('day');
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18 modal-top"
        title={
          <>
            <TagOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        width={640}
        okText={translateX(!isDisabled ? 'save' : 'close')}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        confirmLoading={updating}
        afterClose={afterClose}
      >
        <Card>
          <JarvislyTitle title={'dependent_registration'} />
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={16} lg={16}>
              <JarvislyInput label="full_name" name="name" />
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <JarvislySelect
                name="gender"
                options={genderOptions}
                showSearch
                allowClear
              />
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={12} lg={12}>
              <JarvislyDatePicker
                name="birthdate"
                disabledDate={disabledDate}
              />
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <JarvislySelect
                name="relationship"
                label="kinship_degree"
                options={kinshipOptions}
                showSearch
                allowClear
              />
            </Col>
          </Row>
          <JarvislyTitle title={'dependents'} />
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={8} lg={8}>
              <JarvislyPhone name="mobile" />
            </Col>
            <Col xs={24} sm={16} lg={16}>
              <JarvislyEmail name="email" />
            </Col>
          </Row>
          <JarvislyTitle title={'period'} />
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={8} lg={8}>
              <JarvislyDatePicker label="move_date" name="moveDate" />
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} lg={24}>
              <JarvislyInput name="observation" />
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );
  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const JarvislyDependentsModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, isWorking, refreshDocument } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables II --------------------------------------------------------
  const dependentsTabProps = {
    ...props,
    showModal,
    setShowModal,
    selectedRecord,
    setSelectedRecord,
  };

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal: value => setShowModal(value),
    selectedRecord: selectedRecord,
    setSelectedRecord: value => setSelectedRecord(value),
  }));

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body }) => {
    try {
      const isEditing = selectedRecord?._id; // Verifica se há um dependente selecionado para edição
      const axiosDependentsOptions = {
        url: '/v1/dependents',
        _id: isEditing ? selectedRecord._id : undefined, // Usa o ID se estiver editando
        data: body,
      };

      // Realiza a operação de upsert para dependentes
      const [response] = await axiosService.upsert(axiosDependentsOptions);
      if (response) {
        // Se estamos adicionando um novo dependente, atualiza os IDs dos dependentes na unidade
        if (!isEditing) {
          const existingDependentsIds = document?.dependentsIds || [];
          const newDependentId = response._id;
          const updatedDependentsIds = [
            ...existingDependentsIds,
            newDependentId,
          ];

          const axiosUnitsOptions = {
            url: '/v1/units',
            _id: document?._id,
            data: { dependentsIds: updatedDependentsIds },
          };

          await axiosService.upsert(axiosUnitsOptions);
        }
      }

      refreshDocument();
      setShowModal(false);

      // setShowDependentsModal(false);
      setSelectedRecord(null); // Limpa a seleção após a operação

      return response;
    } catch (error) {
      console.error(error);
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-contact"
      document={selectedRecord}
      requiredFields={[
        'name',
        'gender',
        'birthdate',
        'relationship',
        'mobile',
        'email',
        'moveDate',
      ]}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus="nameResponsable"
    >
      <ModalComponent
        {...dependentsTabProps}
        updating={isWorking}
        record={selectedRecord}
        afterClose={() => {
          setSelectedRecord(null);
        }}
      />
    </JarvislyFormProvider>
  );
});

export default JarvislyDependentsModal;
