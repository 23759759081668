import { GiSoccerKick } from 'react-icons/gi';
import { getFromLocalStorage } from 'utils/helpers';
import ApptexansportsAthletesList from './List/List';
import ApptexansportsAthetesForm from './Form/Form';
import { DefaultFormHeaderColumn1 } from 'components/jarvisly-module/Form/FormHeader';
import dayjs from 'dayjs';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const MENU_GROUP = 'records';
const MODULE_TITLE = 'athletes';
const MODULE_ICON = GiSoccerKick;
const MODULE_API = '/v1/athletes';
export const APPTEXANSPORTS_ATHLETES_ROUTE = `/app/${MENU_GROUP}/${MODULE_TITLE}`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************

const APPTEXANSPORTS_ATHLETES_MODULE = {
  rootName: 'athletes',
  name: 'athletes',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  urlForm: null,
  api: MODULE_API,
  url: APPTEXANSPORTS_ATHLETES_ROUTE,
  routes: [
    `${APPTEXANSPORTS_ATHLETES_ROUTE}/:_id`,
    APPTEXANSPORTS_ATHLETES_ROUTE,
  ],

  components: {
    dashboard: null,
    list: props => <ApptexansportsAthletesList {...props} />,
    form: props => <ApptexansportsAthetesForm {...props} />,
    formHeader: null,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: null,
    formHeaderColumn3: null,
    formHeaderTags: null,
    formHeaderCustomIcons: null,
    settings: null,
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,
  },

  methods: {
    decorateDocument,
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {
      dataProfile: ['athletes'],
      dataType: ['person'],
    },
    filterOptions: {
      dataProfile: ['athletes'],
      dataType: ['person'],
    },

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },

    sorterOptions: ['_metadata.audit.updatedAt'],

    search: {},

    textSearch: {},
  },

  listState: {
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  documentState: {
    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'name',
    hasPicture: true,
    showParameters: true,
    // newDocumentTitle: 'new_athlete',
    autoFill: false,
    actions: [
      'new',
      'edit',
      // 'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
    ],
    refreshParametersOnSave: true,

    mockDocument: {},
  },
};

// EXPORT ********************************************************************
// ***************************************************************************

export default APPTEXANSPORTS_ATHLETES_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  if (doc?.birthdate) doc.birthdate = dayjs(doc.birthdate);

  return doc;
}
