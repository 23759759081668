import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Badge, Button, Table, Tooltip } from 'antd';
import { setLocale, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentStatusTag from 'components/jarvisly-components/others-old/DocumentStatusTag';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import { useEffect, useState } from 'react';
import ListPreview from 'components/jarvisly-module/List/ListPreview';
import APPVEERTICAL_IOTDEVICES_MODULE from '../iotDevices';
import { DeviceTag, IotDevicesInfoIcons } from '../Components';
import appveerticalService from 'services/appveerticalService';

const IotDevicesList = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,
    dataDashboard
  } = props;

  // local variables I ---------------------------------------------------------
  // const { parameters } = useSelector(s => s.moduleSlice);

  const statusFilter = buildDocumentStatusFilter();
  const locationFilter = buildDocumentLocationFilter();
  const typeFilter = buildDocumentTypeFilter();
  const deviceFilter = buildDocumentDeviceFilter();
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------
  const [selectedItem, setSelectedItem] = useState();
  const [showPreviewDrawer, setShowPreviewDrawer] = useState(false);
  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  // useEffect(()=>{
  //   console.log('atualizado dashboard', dataDashboard)
  // }, [dataDashboard])

  // methods -------------------------------------------------------------------
  const onClickPreview = record => {
    setSelectedItem(record);
    setShowPreviewDrawer(true);
  };

  const close = () => {
    setShowPreviewDrawer(false);
  };
  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      {/* PREVIEW LEFT DRAWER */}
      <ListPreview
        close={close}
        visible={showPreviewDrawer}
        record={selectedItem}

        // showDrawer={showPreviewDrawer}
        // setShowDrawer={setShowPreviewDrawer}
        // _module={_module}
        // rdxModuleSettings={rdxModuleSettings}
      />

      {/* PREVIEW LEFT DRAWER */}
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
        size="small"
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDocumentStatusFilter() {
    const options =
      APPVEERTICAL_IOTDEVICES_MODULE?.initialRequest?.filterOptions?.status ||
      [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }
  function buildDocumentLocationFilter() {
    const options =
      APPVEERTICAL_IOTDEVICES_MODULE?.initialRequest?.filterOptions?.location ||
      [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }
  function buildDocumentTypeFilter() {
    const options =
      APPVEERTICAL_IOTDEVICES_MODULE?.initialRequest?.filterOptions?.type || [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }
  function buildDocumentDeviceFilter() {
    const options =
      APPVEERTICAL_IOTDEVICES_MODULE?.initialRequest?.filterOptions?.device ||
      [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }

  function buildTableColumns() {
    return [
      {
        hidden: !showNumberOfLine,
        title: translateX('line'),
        width: 80,
        render: index => {
          if (!pagination) return;

          const { current, pageSize } = pagination;

          return (
            <div className="text-left">
              {current * pageSize - pageSize + index + 1 || index}
            </div>
          );
        },
      },

      {
        title: translateX('device'),
        dataIndex: 'title',
        ...columnSearch('title'),
        ...columnSorter('title', deviceFilter),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                shape="square"
                imageUrl={appveerticalService.getDevicePicture(record?.type)}
                style={{ padding: 14 }}
                title={
                  <Badge
                    className="badge-size-12"
                    color={record?.color || 'gray'}
                    text={record?.title}
                  />
                }
                subtitle={record?.__entity?.name}
                subtitleClassName="text-uppercase text-muted font-weight-bold"
                backColor="#ecf2fe"
              />
            </div>
          );
        },
      },
      {
        title: translateX('channel'),
        dataIndex: 'channel',
        ...columnSorter('channel'),
        ...columnSearch('channel'),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <>
              <div className="text-uppercase">{record?.channel}</div>
            </>
          );
        },
      },

      {
        title: translateX('type'),
        dataIndex: 'type',
        ...columnSorter('type'),
        // ...columnSearch('type'),
        ...columnFilters('type', typeFilter),
        className: 'text-truncate',
        render: (_, record) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DeviceTag record={record} uppercase />
            {/*<IotDevicesModelIcons document={record} />*/}
          </div>
        ),

        // render: (_, record) => (
        //   <>
        //     <div className="text-center">
        //       <DeviceTag record={record} uppercase />
        //     </div>
        //     <div className="text-center text-uppercase font-size-sm font-weight-bold text-muted">
        //       {translateX(record?.model)}
        //     </div>
        //   </>
        // ),
      },
      /*      {
        title: translateX('location'),
        dataIndex: 'location',
        ...columnSorter('location'),
        ...columnFilters('location', locationFilter),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <>
              {/!* <Tag
            className="text-capitalize"
            style={{ margin: 4 }}
            color={record?.__locationColor || 'yellow'}
          >
            {record?.__locationName}
          </Tag> *!/}
              <div className="text-uppercase">{record?.__locationName}</div>
            </>
          );
        },
      },*/
      {
        title: translateX('status'),
        dataIndex: 'status',
        ...columnSorter('status'),
        ...columnFilters('status', statusFilter),
        render: (_, record) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DocumentStatusTag record={record} />
            {/*<IotDevicesConnectedIcons document={record} />*/}
          </div>
        ),
      },

/*
      {
        title: translateX('info'),
        dataIndex: 'info',
        render: (_, record) => <IotDevicesInfoIcons document={record} />,
      },
*/

      {
        title: translateX('last_reading'),
        dataIndex: 'lastReadAt',
        ...columnSorter('lastReadAt'),
        hidden: false,
        render: (_, record) => (
          <>
            {record?.lastReadAt
              ? dayjs(record.lastReadAt).fromNow()
              : translateX('n_a')}
          </>
        ),
      },

      {
        title: setLocale('actions'),
        dataIndex: 'actions',
        align: 'center',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            <Tooltip title={setLocale('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }
};

export default IotDevicesList;
