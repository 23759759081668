import React from 'react';
import JarvislyEntityForm from './Items/JarvislyEntityForm';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const JarvislyPersonIndex = props => {
  // props deconstruction ------------------------------------------------------

  // local variables -----------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* PERSON */}
      <JarvislyEntityForm
        {...props}
        entityPersonType="person"
        cpfCnpjFirst={true}
      />
      {/* PERSON */}

      {/* CONTACTS */}
      {/*<JarvislyContactForm {...props} />*/}
      {/* CONTACTS */}

      {/* ADDRESS */}
      {/*<JarvislyAddressForm {...props} />*/}
      {/* ADDRESS */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// EXPORT **********************************************************************
// *****************************************************************************

export default JarvislyPersonIndex;
