import { TagOutlined } from '@ant-design/icons';
import { Card, Col, Modal, Row } from 'antd';
import {
  JarvislyCnpj,
  JarvislyEmail,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyPhone,
  JarvislyRadio,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useContext, useEffect, useState } from 'react';
import appService from 'services/appService';
import { translateX } from 'utils/helpers';

// COMPONENT *******************************************************************

// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showPixKeyModal,
    setShowPixKeyModal,
    setPixType,
    selectedPix,
    setSelectedPix,
    isDisabled,
    setFormatterRecord,
    selectedType, setSelectedType
  } = props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !selectedPix?._id
      ? translateX('new_pix_key')
      : translateX('edit_pix_key');

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (selectedPix && selectedPix._id) {
      setSelectedType(selectedPix.type);
      setPixType(selectedPix.type)
      setFormatterRecord({
        pixKey: {
          mobile: selectedPix.mobile,
          email: selectedPix.email,
          cnpj: selectedPix.cnpj,
          _id: selectedPix._id,
        },
      });
      form.setFieldsValue(selectedPix);
    }  else {
      setSelectedType(null);
      setPixType(null)
      setFormatterRecord(null);
      setSelectedPix(null);
      form.resetFields(); 
    }
  }, [selectedPix, showPixKeyModal, form, setFormatterRecord, setPixType, setSelectedPix, setSelectedType]);

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowPixKeyModal(false);
  };

  const handleTypeChange = e => {
    setSelectedType(e.target.value);
    setPixType(e.target.value);
  };

  // local variables III ---------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        title={
          <>
            <TagOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showPixKeyModal}
        width={640}
        okText={translateX('save')}
        onCancel={onClose}
        cancelText={translateX('close')}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={'primary'}
        // afterClose={afterClose}
      >
        <Card title={translateX('choice_an_option')}>
          <Row gutter={ROW_GUTTER} style={{ marginBottom: '24px' }}>
            <Col xs={24} sm={24}>
              <JarvislyRadio
                name="type"
                onChange={handleTypeChange}
                value={selectedType}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                direction="horizontal"
                options={[
                  { value: 'cnpj', text: translateX('cnpj') },
                  { value: 'mobile', text: translateX('mobile') },
                  { value: 'email', text: translateX('email') },
                ]}
              />
            </Col>
          </Row>

          <Row gutter={ROW_GUTTER}>
            {selectedType === 'cnpj' && (
              <Col xs={24} sm={24}>
                <JarvislyCnpj label="cnpj" name={['pixKey', 'cnpj']} required />
              </Col>
            )}
            {selectedType === 'mobile' && (
              <Col xs={24} sm={24}>
                <JarvislyPhone
                  label="mobile"
                  name={['pixKey', 'mobile']}
                  required
                />
              </Col>
            )}
            {selectedType === 'email' && (
              <Col xs={24} sm={24}>
                <JarvislyEmail
                  label="email"
                  name={['pixKey', 'email']}
                  required
                />
              </Col>
            )}
          </Row>
        </Card>
      </Modal>
    </>
  );
  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const PixKeyModal = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);
  // props deconstruction ------------------------------------------------------
  const { selectedPix, setShowPixKeyModal, setPixKey } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [pixType, setPixType] = useState();
  const [selectedType, setSelectedType] = useState();
  const [formattedRecord, setFormatterRecord] = useState();

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    form,
    pixType,
    setPixType,
    formattedRecord,
    setFormatterRecord,
    selectedType, setSelectedType
  };

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onConfirm = async body => {
    if (!pixType && !selectedType) {
      appService.notification('i', '', '', 'select_type_pix_key', 5);
      return;
    }

    const newPixKey = {
      _id: selectedPix?._id || Math.random().toString(36).substr(2, 9),
      cnpj: body?.body?.pixKey?.cnpj,
      mobile: body?.body?.pixKey?.mobile,
      email: body?.body?.pixKey?.email,
      type: pixType,
    };

    setPixKey(prevPixKey => {
      const existingIndex = prevPixKey.findIndex(
        key => key._id === newPixKey._id,
      );

      if (existingIndex !== -1) {
        const updatedPixKey = [...prevPixKey];
        updatedPixKey[existingIndex] = newPixKey;
        return updatedPixKey;
      } else {
        return [...prevPixKey, newPixKey];
      }
    });

    setShowPixKeyModal(false);
  };

  // variables III -------------------------------------------------------------
  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
        document={formattedRecord}
        requiredFields={['pixKey.Email', 'pixKey.mobile', 'pixKey.cnpj']}
        showSubmitButton={false}
        onConfirm={onConfirm}
        autoFocus={false}
        abortComponentSave
      >
        <ModalComponent {...childProps} />
      </JarvislyFormProvider>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default PixKeyModal;
