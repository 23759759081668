import {
  FormSelectGender,
  getFieldName,
  goFocus,
  JarvislyAsyncAutoComplete,
  JarvislyCnpj,
  JarvislyCpf,
  JarvislyCpfCnpj,
  JarvislyDatePicker,
  JarvislyEmail,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyPhone,
  JarvislyRadioButtons,
  JarvislySegmented,
  JarvislySelect,
  JarvislySelectGender,
  JarvislyTitle,
  JarvislyWebsite,
  setField,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import entityService from 'services/entityService';
import {
  clone,
  isValidCnpj,
  isValidCpf,
  isValidCpfCnpj,
  isValidEmail,
  isValidPhoneNumber,
  returnOnlyNumbers,
  translateX,
} from 'utils/helpers';
import { Badge, Button, Col, Collapse, Flex, Row, Tooltip } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import React, { useContext, useEffect, useState } from 'react';
import { EditOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';
import { ModuleContext } from '../../../jarvisly-module/ModuleContext';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import JarvislyContactForm from './JarvislyContactForm';
import JarvislyAddressForm from './JarvislyAddressForm';

// COMPONENT *******************************************************************
// *****************************************************************************
const JarvislyEntityForm = props => {
  // providers context ---------------------------------------------------------
  const { elRefs, form, formRefreshed, requiredFields } =
    useContext(JarvislyFormContext);
  const { refreshDocument } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const {
    context,
    showTitle,
    showWebsite = false,
    entityPersonType = 'manual', // 'automatic', 'manual', 'company', 'person'
    entityPersonDefault = ['person', 'company']?.includes(
      props?.entityPersonType,
    )
      ? props.entityPersonType
      : 'person', // 'person' or 'company'
    additionalInfo = 'none', // 'optional' (collapsed), 'required' (explicit) or 'none' (hidden)
    document,

    selectable = false,
    selectedEntity,
    setSelectedEntity,
    mode,

    originRegisteredLabel = 'registered_entity',
    originNewLabel = 'new_entity',
    searchEntityLabel = 'search',

    showCpfCnpj = true,
    cpfCnpjFirst = true,
    setShowBusinessAreaModal,
    setSelectedRecord,
    setRequiredFields,

    setEntityType,
    entityType,
  } = props;

  // local variables I ---------------------------------------------------------
  const isMobile = appService.isMobile('sm');
  const segmentedOptions = buildSegmentedOptions();
  const { selectedModule, parameters } = useSelector(s => s.moduleSlice);
  const navigate = useNavigate();
  const phoneOrEmailRequired = requiredFields?.some(
    field => field === 'phoneEmail' || field === `${context}.phoneEmail`,
  );
  const moduleParameters = parameters?.crmParameters || {};

  // component states ----------------------------------------------------------
  const [, setBillingEmailsOptions] = useState([]);
  const [entityOrigin, setEntityOrigin] = useState(
    selectable ? 'registered_entity' : 'new_entity',
  );
  const [isPhoneRequired, setIsPhoneRequired] = useState(false);
  const [isEmailRequired, setIsEmailRequired] = useState(false);

  // local variables II --------------------------------------------------------
  const cpfCnpjLabel = buildCpfCnpjLabel();

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    if (form && document) form.setFieldsValue(document);
  }, [document, form, formRefreshed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEntityType(selectedEntity?.dataType || 'company');

    if (selectedEntity && selectable) {
      setEntityOrigin('registered_entity');
    }
  }, [selectedEntity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const type =
      document?.__entity?.dataType ||
      document?.[getFieldName('dataType', context)] ||
      entityPersonDefault;

    setEntityType(type);

    setField('dataType', type, form, context);
  }, [entityPersonDefault, entityOrigin, document, formRefreshed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const dataTypeField = getFieldName('dataType', context, document);

    setField(
      'entityOrigin',
      selectable ? 'registered_entity' : 'new_entity',
      form,
      context,
    );

    if (document?.[dataTypeField]) setEntityType(document[dataTypeField]);

    if (!setSelectedEntity) {
      console.error(
        `missing the 'setSelectedEntity' state from parent component!`,
      );
    } else {
      setSelectedEntity && setSelectedEntity(document?.__entity || null);
    }
  }, [formRefreshed, document]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsPhoneRequired(requiredFields?.includes('phoneEmail'));
    setIsEmailRequired(requiredFields?.includes('phoneEmail'));
  }, [requiredFields]);

  useEffect(() => {
    const parameterRequiredFields = clone(
      moduleParameters?.entityRequiredFields || [],
    );

    if (Array.isArray(parameterRequiredFields)) {
      if (
        parameterRequiredFields.includes('businessAreaId') &&
        entityType === 'person'
      ) {
        const updatedFields = parameterRequiredFields.filter(
          field => field !== 'businessAreaId',
        );
        setRequiredFields([...new Set([...updatedFields, 'name', 'fullName'])]);
        return;
      }
      if (
        parameterRequiredFields.includes('cpfCnpj') &&
        entityType &&
        selectedEntity
      ) {
        const updatedFields = parameterRequiredFields.filter(
          field => field !== 'cpfCnpj',
        );
        setRequiredFields([...new Set([...updatedFields, 'name', 'fullName'])]);
        return;
      }
    }

    setRequiredFields([...new Set([...parameterRequiredFields])]);
  }, [moduleParameters?.entityRequiredFields, entityType]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onChangeEntityOrigin = async ev => {
    const value = typeof ev === 'object' ? ev?.target?.value : ev;

    // Atualizar o formulário de forma assíncrona
    const dataForm = form.getFieldsValue();
    await form.resetFields(); // Aguarde a redefinição dos campos
    form.setFieldsValue({
      ...dataForm,
      __entity: { entityOrigin: value },
    });

    setEntityOrigin(value);
    setSelectedEntity(null);
    setBillingEmailsOptions([]);
  };

  const onEntitySelect = async value => {
    const doc = await entityService.getEntityById(value.key);

    setEntityOrigin('registered_entity');
    setSelectedEntity(doc || null);
    setBillingEmailsOptions(entityService.getEntityAllEmailsArr(doc));

    const body = entityService.decorateEntity(doc, 1, context);

    form.setFieldsValue(body);
    form.validateFields([context ? [context, 'phone'] : 'phone']);
  };

  const onCpfCnpjChange = async ev => {
    let tagId = ev?.target?.id;
    let fieldName;

    if (tagId) {
      const parts = tagId.split('_');
      fieldName = parts && parts.length > 0 ? parts[parts.length - 1] : null;
    }

    const value = typeof ev === 'object' ? ev?.target?.value : ev;
    const cpfCnpj = returnOnlyNumbers(value);

    if (
      (entityPersonType === 'automatic' && !isValidCpfCnpj(cpfCnpj)) ||
      (entityPersonType === 'company' && !isValidCnpj(cpfCnpj)) ||
      (entityPersonType === 'person' && !isValidCpf(cpfCnpj)) ||
      (entityPersonType === 'manual' &&
        entityPersonDefault === 'company' &&
        !isValidCnpj(cpfCnpj)) ||
      (entityPersonType === 'manual' &&
        entityPersonDefault === 'person' &&
        !isValidCpf(cpfCnpj))
    ) {
      return;
    }

    if (!isValidCpfCnpj(cpfCnpj)) return;

    const doc = await entityService.getEntityByField(cpfCnpj);

    if (!doc) {
      setEntityType(isValidCnpj(cpfCnpj) ? 'company' : 'person');
      return true;
    }

    let title = translateX(
      'document_exists',
      ['%CPF_CNPJ%'],
      [doc?.cpf ? 'CPF' : 'CNPJ'],
    );
    const options = {
      Content: translateX(
        'document_exists_description',
        ['%CPF_CNPJ%'],
        [doc?.cpf ? 'CPF' : 'CNPJ'],
      ),
      okText: 'yes',
      cancelText: 'no',

      onCancel: () => {
        if (!form) {
          console.warn(`no 'form' found on props!`);
          return;
        }

        if (context) {
          form.setFieldValue([context, fieldName], '');
        } else {
          form.setFieldValue(fieldName, '');
        }

        return false;
      },

      onOk: async () => {
        if (!form) {
          console.warn(`no 'form' found on props!`);
          return;
        }

        if (!selectable) {
          const route = selectedModule?.routes.find(r =>
            r?.includes(selectedModule?.urlForm || selectedModule?.url),
          );

          if (route) {
            const newUrl = route.replace(':_id', doc?._id);
            navigate(newUrl);
          }

          refreshDocument(doc?._id).then();
        } else {
          const value = clone(doc);
          value.key = doc._id;
          await onEntitySelect(value);
        }

        setEntityType(doc.dataType || 'company');
        if (fieldName && elRefs) {
          goFocus(elRefs, fieldName, 'all');
        }
        return false;
      },
    };

    appService.modal('q', title, options);

    return false;
  };

  const onChangeValidateRequired = (field) => {

    if (!phoneOrEmailRequired) {
      setIsPhoneRequired(
        requiredFields?.includes(context ? [context, 'phone'] : 'phone'),
      );
      setIsEmailRequired(
        requiredFields?.includes(context ? [context, 'email'] : 'email'),
      );

      return;
    }

    const phone = form?.getFieldValue(context ? [context, 'phone'] : 'phone');
    const isPhoneValid = isValidPhoneNumber(phone);
    const email = form?.getFieldValue(context ? [context, 'email'] : 'email');
    const isEmailValid = isValidEmail(email);

    setIsPhoneRequired(!isEmailValid);
    setIsEmailRequired(!isPhoneValid);

    if (isPhoneValid)
      form?.validateFields([context ? [context, 'email'] : 'email']);
    if (isEmailValid)
      form?.validateFields([context ? [context, 'phone'] : 'phone']);

    // setTimeout(()=>{
      goFocus(elRefs, [context ? [context, field] : field])
    // },5000)

  };

  const onAddBusinessArea = () => {
    setSelectedRecord(null);
    setShowBusinessAreaModal(true);
  };
  const onBusinessAreaOrigin = record => {
    setSelectedRecord(record); // Passa o registro completo
    setShowBusinessAreaModal(true);
  };

  // local variables III -------------------------------------------------------
  const businessAreaOptions = buildBusinessAreaOptions();
  const customerAdditionalFields = buildCustomerAdditionalFields();

  // UI COMPONENTS -------------------------------------------------------------
  const Title = props => {
    return showTitle ? (
      <JarvislyTitle
        title={typeof showTitle === 'string' ? showTitle : 'personal_data'}
        {...props}
      />
    ) : null;
  };

  // UI COMPONENTS -------------------------------------------------------------
  const SelectedOrigin = props => {
    return (
      <Flex
        justify={isMobile ? 'center' : 'space-between'}
        align="center"
        vertical={isMobile}
        {...props}
      >
        {/* ENTITY ORIGIN */}
        <JarvislyRadioButtons
          context={context}
          name="entityOrigin"
          onChange={onChangeEntityOrigin}
          noLabel
          options={[
            {
              value: 'registered_entity',
              label: translateX(originRegisteredLabel),
            },
            {
              value: 'new_entity',
              label: translateX(originNewLabel),
            },
          ]}
        />
        {/* ENTITY ORIGIN */}

        {/* ENTITY PERSON TYPE */}
        {entityOrigin === 'new_entity' ? (
          entityPersonType === 'manual' ? (
            <Flex
              justify={isMobile ? 'center' : 'space-between'}
              align="center"
              vertical={isMobile}
            >
              <JarvislySegmented
                name="dataType"
                context={context}
                x-no-label
                options={segmentedOptions}
                onChange={v => setEntityType(v)}
                block={false}
              />
            </Flex>
          ) : null
        ) : null}
        {/* ENTITY PERSON TYPE */}
      </Flex>
    );
  };

  const BKP = props => {
    return (
      <Row gutter={ROW_GUTTER}>
        {/* CPF/CNPJ */}
        <Col
          xs={24}
          sm={10}
          lg={showWebsite ? 6 : 8}
          order={cpfCnpjFirst ? 1 : 2}
          style={{
            display: showCpfCnpj ? 'block' : 'none',
          }}
        >
          {entityPersonType === 'company' ||
          (entityPersonType === 'manual' && entityType === 'company') ? (
            <JarvislyCnpj
              context={context}
              label={cpfCnpjLabel}
              onChange={onCpfCnpjChange}
            />
          ) : entityPersonType === 'person' ||
            (entityPersonType === 'manual' && entityType === 'person') ? (
            <JarvislyCpf
              context={context}
              label={cpfCnpjLabel}
              onChange={onCpfCnpjChange}
            />
          ) : (
            <JarvislyCpfCnpj
              context={context}
              label={cpfCnpjLabel}
              onChange={onCpfCnpjChange}
            />
          )}
        </Col>
        {/* CPF/CNPJ */}

        {/* NAME */}
        {entityOrigin === 'new_entity' ? (
          <Col
            xs={24}
            sm={14}
            lg={showWebsite ? 12 : 16}
            order={cpfCnpjFirst ? 2 : 1}
          >
            <JarvislyInput
              context={context}
              name="name"
              label={translateX(entityType === 'person' ? 'name' : 'company')}
            />
          </Col>
        ) : null}
        {/* NAME */}

        {/* AREA OF ACTIVY */}
        {entityOrigin === 'new_entity' && entityType === 'person' ? (
          <Col xs={24} sm={10} lg={showCpfCnpj ? 6 : 8} order={3}>
            <JarvislyPhone
              context={context}
              required={isPhoneRequired}
              onChange={()=>onChangeValidateRequired('phone')}
            />
          </Col>
        ) : null}
        {/* AREA OF ACTIVY */}

        {/* WEBSITE */}
        {entityOrigin === 'new_entity' ? (
          entityType === 'company' && showWebsite ? (
            <Col
              xs={24}
              sm={24}
              lg={showCpfCnpj ? (entityType === 'person' ? 8 : 10) : 12}
              order={4}
            >
              <JarvislyWebsite context={context} />
            </Col>
          ) : entityType === 'person' ? (
            <Col xs={24} sm={24} lg={12} order={4}>
              <JarvislyEmail
                context={context}
                required={isEmailRequired}
                onChange={()=>onChangeValidateRequired('email')}
              />
            </Col>
          ) : null
        ) : null}
        {/* WEBSITE */}
      </Row>
    );
  };

  const NewEntityPerson = props => {
    return (
      <Row gutter={ROW_GUTTER}>
        {/* CPF */}
        <Col
          xs={24}
          sm={9}
          lg={6}
          order={cpfCnpjFirst ? 1 : 2}
          style={{
            display: showCpfCnpj ? 'block' : 'none',
          }}
        >
          <JarvislyCpf
            context={context}
            label={cpfCnpjLabel}
            onChange={onCpfCnpjChange}
          />
        </Col>
        {/* CPF */}
        {/* NAME */}
        <Col xs={24} sm={15} lg={6} order={cpfCnpjFirst ? 2 : 1}>
          <JarvislyInput context={context} name="name" />
        </Col>
        {/* NAME */}
        {/* PHONE */}
        <Col xs={24} sm={9} lg={6} order={3}>
          <JarvislyPhone
            context={context}
            required={isPhoneRequired}
            onChange={()=>onChangeValidateRequired('phone')}
          />
        </Col>
        {/* PHONE */}
        {/* EMAIL */}
        <Col xs={24} sm={15} lg={6} order={4}>
          <JarvislyEmail
            context={context}
            required={isEmailRequired}
            onChange={()=>onChangeValidateRequired('email')}
          />
        </Col>
        {/* EMAIL */}
      </Row>
    );
  };

  const NewEntityCompany = props => {
    return (
      <>
        <Row gutter={ROW_GUTTER}>
          {/* CNPJ */}
          <Col
            xs={24}
            sm={10}
            lg={8}
            order={cpfCnpjFirst ? 1 : 2}
            style={{
              display: showCpfCnpj ? 'block' : 'none',
            }}
          >
            <JarvislyCnpj
              context={context}
              label={cpfCnpjLabel}
              onChange={onCpfCnpjChange}
            />
          </Col>
          {/* CNPJ */}

          {/* COMPANY */}
          <Col
            xs={24}
            sm={14}
            lg={showWebsite ? 8 : 16}
            order={cpfCnpjFirst ? 2 : 1}
          >
            <JarvislyInput context={context} name="name" label="company" />
          </Col>
          {/* COMPANY */}

          {/* WEBSITE */}
          {showWebsite ? (
            <Col xs={24} sm={24} lg={8} order={3}>
              <JarvislyWebsite context={context} />
            </Col>
          ) : null}
          {/* WEBSITE */}
        </Row>

        {/* CONTACT FORM */}
        <JarvislyContactForm
          {...props}
          showWebsite={false}
          showName
          context={context}
        />
        {/* CONTACT FORM */}
      </>
    );
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Title />

      <SelectedOrigin
        style={{
          display: selectable ? 'flex' : 'none',
        }}
      />

      <Row
        gutter={ROW_GUTTER}
        style={{
          display: entityOrigin === 'registered_entity' ? 'flex' : 'none',
        }}
      >
        {/* ENTITY */}
        <Col xs={24} md={12}>
          <JarvislyAsyncAutoComplete
            context={context}
            name="selectedEntity"
            label={searchEntityLabel}
            required={entityOrigin === 'registered_entity'}
            tooltip="Nome, Telefone, Email ou Documento"
            x-data-profile={[
              'customer',
              'provider',
              'collaborator',
              'resident',
              'operator',
            ]}
            x-on-select={onEntitySelect}
          />
        </Col>
        {/* ENTITY */}
      </Row>

      {/* NEW ENTITY */}
      {!selectable && entityPersonType === 'manual' ? (
        <Flex
          justify={isMobile ? 'center' : 'space-between'}
          align="center"
          vertical={isMobile}
          style={{
            display: entityOrigin === 'new_entity' ? 'flex' : 'none',
          }}
        >
          {/* ENTITY TYPE OPTIONS */}
          <JarvislySegmented
            name="dataType"
            context={context}
            x-no-label
            options={segmentedOptions}
            onChange={v => setEntityType(v)}
            block={false}
          />
          {/* ENTITY TYPE OPTIONS */}
        </Flex>
      ) : null}

      {entityOrigin === 'new_entity' ? (
        entityType === 'company' ? (
          <NewEntityCompany {...props} />
        ) : (
          <NewEntityPerson {...props} />
        )
      ) : null}

      {/* ADDITIONAL FIELDS */}
      {entityOrigin === 'new_entity' ? (
        additionalInfo === 'optional' ? (
          <Collapse
            ghost
            items={customerAdditionalFields}
            className="no-collapse-padding"
          />
        ) : additionalInfo === 'required' ? (
          customerAdditionalFields[0].children
        ) : null
      ) : null}
      {/* ADDITIONAL FIELDS */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildSegmentedOptions() {
    return [
      {
        label: translateX('legal_person'),
        value: 'company',
        icon: <ShopOutlined />,
      },
      {
        label: translateX('individual_person'),
        value: 'person',
        icon: <UserOutlined />,
      },
    ];
  }

  function buildCpfCnpjLabel() {
    switch (entityPersonType) {
      case 'person':
        return 'cpf';

      case 'company':
        return 'cnpj';

      case 'manual':
        return translateX(entityType === 'company' ? 'cnpj' : 'cpf');

      case 'automatic':
      default:
        return undefined;
    }
  }

  function buildBusinessAreaOptions() {
    if (!parameters?.entityParameters?.businessArea?.length) {
      return [];
    }

    return parameters?.entityParameters?.businessArea?.map(businessArea => {
      return {
        value: businessArea._id,
        text: businessArea.label,
        label: (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span key={businessArea._id}>
                <Badge
                  dot
                  style={{
                    marginRight: 8,
                    color: businessArea?.foreColor,
                    backgroundColor: businessArea?.backColor,
                    marginTop: 4,
                  }}
                />
                {businessArea.label}
              </span>

              {mode !== 'view' && (
                <Tooltip title={translateX('edit')}>
                  <Button
                    size="small"
                    className="m-2"
                    icon={<EditOutlined />}
                    onClick={() => onBusinessAreaOrigin(businessArea)} // Passando o registro completo
                  />
                </Tooltip>
              )}
            </div>
          </>
        ),
        disabled: false,
      };
    });
  }

  function buildCustomerAdditionalFields() {
    return [
      {
        key: 'additional_fields',
        label: `${translateX('additional_info')} (${translateX('optional')})`,
        children: (
          <>
            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={9} lg={12}>
                <JarvislyInput
                  context={context}
                  name="fullName"
                  label={entityType === 'person' ? 'full_name' : 'company_name'}
                />
              </Col>

              <Col xs={24} sm={12} md={7} lg={6}>
                <JarvislyDatePicker
                  context={context}
                  name="birthdate"
                  label={translateX(
                    entityType === 'person' ? 'birthdate' : 'foundation_date',
                  )}
                />
              </Col>

              <Col xs={24} sm={12} md={8} lg={6}>
                {entityType === 'person' ? (
                  <JarvislySelectGender context={context} />
                ) : (
                  <JarvislySelect
                    label="area_activy"
                    name="businessAreaId"
                    options={businessAreaOptions}
                    context={context}
                    showSearch
                    allowClear
                    x-show-add-button={true}
                    x-on-add-button-fn={onAddBusinessArea}
                    optionLabelProp="text"
                  />
                )}
              </Col>
            </Row>

            {/* ADDRESS */}
            <JarvislyAddressForm {...props} addressContext={context} />
            {/* ADDRESS */}
          </>
        ),
      },
    ];
  }
};
export default JarvislyEntityForm;
