import React, { useEffect, useMemo } from 'react';
import RegisterForm from '../../components/RegisterForm';
import { Col, Grid, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import utils from 'utils';
import { translateX } from 'utils/helpers';
import { version } from 'appversion';
import NavLanguage from 'components/layout-components/NavLanguage';
import { THEME_BACKGROUND } from '../../../../constants/ThemeConstant';
import { onRequestsWorking } from '../../../../store/slices/themeSlice';
import app, { printGlobalStateToConsole } from '../../../../configs/ConfigIndex';
import appService from '../../../../services/appService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const { useBreakpoint } = Grid;
// const backgroundURL = '/img/others/img-17.jpg'
const backgroundURL = THEME_BACKGROUND[app.THEME_COLOR];
const backgroundStyle = {
  backgroundImage: `url(${backgroundURL})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

// COMPONENT *******************************************************************
// *****************************************************************************

const RegisterAdm = props => {
  // props deconstruction ------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const currentTheme = useSelector(state => state.theme.currentTheme);
  const headerNavColor = useSelector(state => state.theme.headerNavColor);
  const isDarkTheme = currentTheme === 'dark';
  const navMode = useMemo(() => {
    if (!headerNavColor) {
      return utils.getColorContrast(isDarkTheme ? '#000000' : '#ffffff');
    }
    return utils.getColorContrast(headerNavColor);
  }, [isDarkTheme, headerNavColor]);
  const { working } = useSelector(state => state.theme);
  const dispatch = useDispatch();

  const logo = appService.getLogo(isDarkTheme);
  const logoSide = appService.getLogo(isDarkTheme, 'login-side');

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (!working) dispatch(onRequestsWorking(0));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    // <div
    //   className={`h-100 ${currentTheme === 'light' ? 'bg-white' : ''}`}
    //   onDoubleClick={printGlobalStateToConsole}
    // >
    <div className={`h-100`} onDoubleClick={printGlobalStateToConsole}>
      <Row justify="center" className="align-items-stretch h-100">
        <Col xs={20} sm={20} md={24} lg={16}>
          <Row justify="space-between" style={{ height: '100vh' }}>
            <Col span={24}>
              <Row
                justify="center"
                align="top"
                style={{
                  height: '100%',
                  paddingTop: 48,
                }}
              >
                {isMobile && <img src={logo} alt="logo" />}
              </Row>
            </Col>

            <Col span={24}>
              <Row
                justify="center"
                align="top"
                style={{
                  height: '100%',
                  marginTop: -40,
                }}
              >
                <Col
                  style={{
                    minWidth: 300,
                    maxWidth: 300,
                  }}
                >
                  <h1 className="mt-4" style={{ paddingTop: 30 }}>
                    {translateX('sign_up')}
                  </h1>

                  <div className="mt-4">
                    <RegisterForm {...props} />
                  </div>
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row
                justify="space-between"
                align="bottom"
                className="pb-4 pl-0 pr-0 pl-md-4 pr-md-4"
                style={{ height: '100%' }}
              >
                <Col>
                  <span className="font-size-sm text-muted">
                    Jarvisly App v{version}
                  </span>
                </Col>
                <Col>
                  {app?.NAVIGATION?.LANGUAGES ? (
                    <NavLanguage mode={navMode} onFixed="login" />
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>

          {/*
          <div className="container d-flex flex-column justify-content-center h-100">
            <Row justify="center">
              <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                <h1>Sign Up</h1>
                <p>Already have an account? <a href="/auth/login-2">Sign In</a></p>
                <div className="mt-4">
                  <RegisterForm {...props} />
                </div>
              </Col>
            </Row>
          </div>
*/}
        </Col>

        <Col xs={0} sm={0} md={0} lg={8}>
          <div
            className="d-flex flex-column justify-content-between h-100 px-4"
            style={backgroundStyle}
          >
            <div className="text-right">
              <img src={logoSide} alt="logo" />
            </div>

            <Row justify="center">
              <Col xs={0} sm={0} md={0} lg={20}>
                <img
                  className="img-fluid mb-5"
                  src="/img/others/img-19.png"
                  alt=""
                />
                <h1 className="text-white" style={{ fontSize: 20 }}>
                  {/* {translateX( "welcome_to")}  */}
                  {app.TITLE}
                </h1>
                <p className="text-white">
                  {translateX(app.SLOGAN_REGISTRATION)}
                </p>
                <p className="text-white font-weight-bold text-right font-italic">
                  {app.SLOGAN_REGISTRATION2}
                </p>
              </Col>
            </Row>

            <div className="d-flex justify-content-end pb-4">
              <div>
                <a
                  className="text-white font-size-sm"
                  href="/#"
                  onClick={e => e.preventDefault()}
                >
                  {translateX('term_conditions')}
                </a>
                <span className="mx-2 text-white"> | </span>
                <a
                  className="text-white font-size-sm"
                  href="/#"
                  onClick={e => e.preventDefault()}
                >
                  {translateX('privacy_policy')}
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RegisterAdm;
