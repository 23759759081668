import { forwardRef, useState } from 'react';
import { translateX } from 'utils/helpers';
import { Modal, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import BlockModal from './blocks/BlockModal';
import BlockTab from './blocks/BlockTab';
import ProfileTab from './profiles/ProfileTab';
import ProfileModal from './profiles/ProfileModal';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { showParametersModal, setShowParametersModal } = props;

  // local variables I ----------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);

  // component states -----------------------------------------------------------
  const [tabIdx, setTabIdx] = useState();
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowParametersModal(false);
  };

  function onTabClick(key) {
    setTabIdx(key);

    switch (key) {
      // case 'configurations':
      //   configurationTabRef?.current?.getFirstFocus();
      //   break;

      // case 'requiredFields':
      //   requiredFieldsTabRef?.current?.getFirstFocus();
      //   break;

      default:
        break;
    }
  }

  // local variables III --------------------------------------------------------
  const childProps = {
    ...props,
    parameters,
    selectedRecord,
    setSelectedRecord,
  };
  const tabItems = buildTabItems();

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        // className="title-fixed-with-18 modal-top"
        title={translateX('parameters')}
        // destroyOnClose={true}
        destroyOnClose={true}
        open={showParametersModal}
        width={640}
        // okText={translateX('save')}
        onCancel={onClose}
        cancelText={translateX('close')}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        // onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
      >
        <BlockModal
          {...childProps}
          setShowParametersModal={setShowBlockModal}
          showParametersModal={showBlockModal}
        />

        <ProfileModal
          {...childProps}
          setShowParametersModal={setShowProfileModal}
          showParametersModal={showProfileModal}
        />

        <Tabs onChange={onTabClick} items={tabItems} activeKey={tabIdx} />
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItems() {
    return [
      {
        label: translateX('blocks'),
        key: 'blocks',
        hide: false,
        children: (
          <>
            <BlockTab
              {...childProps}
              setShowParametersModal={setShowBlockModal}
              showParametersModal={showBlockModal}
            />
          </>
        ),
      },

      {
        label: translateX('profiles'),
        key: 'profiles',
        hide: false,
        children: (
          <>
            <ProfileTab
              {...childProps}
              setShowParametersModal={setShowProfileModal}
              showParametersModal={showProfileModal}
            />
          </>
        ),
      },
    ];
  }
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const UnitsParametersModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // for 'contacts' tab
  // const [showParametersModal, setShowParametersModal] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  // useImperativeHandle(ref, () => ({
  //   showParametersModal,
  //   setShowParametersModal: value => setShowParametersModal(value),
  // }));

  // methods -------------------------------------------------------------------

  // variables III -------------------------------------------------------------
  // const childProps = {
  //   ...props,
  //   showParametersModal,
  //   setShowParametersModal,
  // };

  // UI COMPONENT --------------------------------------------------------------
  return <ModalComponent {...props} />;
});

export default UnitsParametersModal;
