import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Card, Col, Row } from 'antd';
import {
  goFocus,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyNumber,
  JarvislyTitle,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';

// COMPONENT *******************************************************************
// *****************************************************************************
const AthletesStatistcTab = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------
  const { elRefs } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { document, tab } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [attachments, setAttachments] = useState([]);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    goFocus(elRefs, 'requirement', 'end');
  }, [tab, elRefs]);

  useEffect(() => {
    setAttachments(document?.__attachments || []);
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    attachments,
  }));

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* REQUIREMENT */}
      <Card>
        <JarvislyTitle title={'responsible'} />
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={24} lg={12}>
            <JarvislyInput name="lastClub" label="last_club" />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <JarvislyInput name="previousClubs" label="previous_clubs" />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <JarvislyInput name="hometown" label="hometown" />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            {/* <JarvislyInput name="height" label="height" /> */}
            <JarvislyNumber
              name="height"
              suffixDefaultValue="brl"
              decimalSeparator=","
              addonBefore={null}
              min={1}
              max={999}
              addonAfter="cm"
              suffixStyle={{ width: 60 }}
            />
            {/* <JarvislyNumber name="weightAthletes" addonBefore={null} addonAfter={null} /> */}
          </Col>
          <Col xs={24} sm={24} lg={12}>
            {/* <JarvislyInput name="weightAthletes" label="weight" /> */}
            <JarvislyNumber
              name="weight"
              suffixDefaultValue="brl"
              decimalSeparator=","
              addonBefore={null}
              min={1}
              max={999}
              addonAfter="Kg"
              suffixStyle={{ width: 60 }}
            />
          </Col>

          <Col xs={24} sm={24} lg={12}>
            <JarvislyInput name="allergy" label="allergy" />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
});

export default AthletesStatistcTab;
