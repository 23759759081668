import axios from 'axios';
import axiosService from './axiosService';
import appService from './appService';
import { translateX } from 'utils/helpers';

const MAIN_API = '/v1/whatsapp';
const WHATSAPP_API = '/v1/instance';
const whatsappService = {};

whatsappService.sendWhatsAppMessage = async (session, number, message) => {
  try {
    const url = `http://localhost:8077/api${WHATSAPP_API}/${session}/send-text/${number}/${message}`;
    await axios.get(url);
    appService.message(
      's',
      translateX('whatsapp_test_sucess'),
      'add_account',
      5,
    );
  } catch (error) {
    appService.message(
      'e',
      translateX('whatsapp_test_error'),
      'add_account',
      5,
    );
    throw error;
  }
};

whatsappService.postClientConnection = async (body, subscriptionId) => {
  try {
    const axiosOptions = {
      url: MAIN_API,
      data: body,
      headers: { 'x-subscriptionid': subscriptionId },
    };
    await axiosService.post(axiosOptions);
    appService.message(
      's',
      translateX('whatsapp_connected_sucess'),
      'add_account',
      5,
    );
  } catch (error) {
    appService.message(
      'e',
      translateX('whatsapp_connected_error'),
      'add_account',
      5,
    );
    throw error;
  }
};

whatsappService.editClientConnection = async (body, _id) => {
  try {
    const axiosOptions = {
      url: MAIN_API,
      _id: _id,
      data: body,
    };
    await axiosService.put(axiosOptions);
    appService.message(
      's',
      translateX('whatsapp_edit_sucess'),
      'add_account',
      5,
    );
  } catch (error) {
    appService.message(
      'e',
      translateX('whatsapp_edit_error'),
      'add_account',
      5,
    );
    throw error;
  }
};

whatsappService.getWhatsAppList = async subscriptionId => {
  try {
    const axiosOptions = {
      url: `${MAIN_API}/get-pagination`,
      headers: { 'x-subscriptionid': subscriptionId },
    };
    return await axiosService.get(axiosOptions);
  } catch (error) {
    throw error;
  }
};

whatsappService.deleteWhatsapp = async _id => {
  try {
    const axiosOptions = {
      url: MAIN_API,
      _id,
    };

    await axiosService.delete(axiosOptions);
  } catch (error) {}
};

export default whatsappService;
