import { Table } from 'antd';
import {
  parseCnpjToDisplay,
  parseCpfToDisplay,
  translateWord,
  translateX,
} from 'utils/helpers';
import React from 'react';
import { CrmStatusTag } from '../Components';

const CrmList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,

    numberLineColumn,
    documentCodeColumn,
    lastUpdatedColumn,
    editViewColumn,
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
        size="small"
        // expandable={{
        //   expandedRowRender,
        //   defaultExpandedRowKeys: ['0'],
        // }}

        expandable={{
          expandedRowRender: buildTableItemsColumns,
          defaultExpandedRowKeys: ['0'],
        }}

        // expandable={{
        //   expandedRowRender: record => (
        //     <p
        //       style={{
        //         margin: 0,
        //       }}
        //     >
        //       {record.departure}
        //     </p>
        //   ),
        //
        //   rowExpandable: record => record.name !== 'Not Expandable',
        // }}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      numberLineColumn,
      documentCodeColumn,

      {
        title: translateWord('customer'),
        dataIndex: 'customer',
        className: 'text-truncate',
        render: (_, record) => (
          <span>
            {record?.__entity?.dataType === 'company'
              ? record?.__entity?.fullName
              : record?.__entity?.name}
          </span>
        ),
      },

      // {
      //   title: translateWord('cpf_cnpj'),
      //   dataIndex: 'cpfCnpj',
      //   className: 'text-truncate',
      //   render: (_, record) => (
      //     <span>
      //       {record?.__entity?.dataType === 'company'
      //         ? parseCnpjToDisplay(record?.__entity?.cnpj)
      //         : parseCpfToDisplay(record?.__entity?.cpf)}
      //     </span>
      //   ),
      // },

      {
        title: translateWord('status'),
        dataIndex: 'status',
        width: 100,
        render: (_, record) => (
          // const receivable = record?.__receivables?.[0];
          // return receivable ? (
          //   <ReceivablesStatusTag record={receivable} />
          // ) : (
          <CrmStatusTag record={record} />
        ),
        // );
      },

      lastUpdatedColumn,
      editViewColumn,
    ].filter(x => !x.hidden);
  }

  function buildTableItemsColumns(record) {
    const dataItem = dataList.filter(i => i?._id === record?._id);

    const columns = [
      {
        title: translateX('description'),
        dataIndex: 'opportunity',
        // width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {record?.opportunity}
          </span>
        ),
      },

      // {
      //   title: translateWord('plan'),
      //   dataIndex: 'plan',
      //   className: 'text-truncate',
      //   render: (_, record) => {
      //     return (
      //       <>
      //         <div>{record?.__plan?.name}</div>
      //         <div className="text-muted">{record?.__operator?.name}</div>
      //       </>
      //     );
      //   },
      // },
      //
      // {
      //   title: setLocale('destiny'),
      //   dataIndex: 'arrival',
      //   render: (_, record) => <span>{translateX(record?.destiny)}</span>,
      // },
      //
      // {
      //   title: setLocale('dates'),
      //   dataIndex: 'date',
      //   render: (_, record) => (
      //     <>
      //       <div style={{ display: 'flex' }}>
      //         <div>
      //           {record?.departure && dayjs(record.departure).format('L')}
      //         </div>
      //         <div style={{ marginTop: 2 }}>
      //           <Icon type={FaPlaneDeparture} className="ml-2" />
      //         </div>
      //       </div>
      //
      //       <div style={{ display: 'flex' }}>
      //         <div>{record?.arrival && dayjs(record.arrival).format('L')}</div>
      //         <div style={{ marginTop: 2 }}>
      //           <Icon type={FaPlaneArrival} className="ml-2" />
      //         </div>
      //       </div>
      //     </>
      //   ),
      // },
      //
      // {
      //   title: setLocale('passengers'),
      //   dataIndex: 'passengers',
      //   render: (_, record) =>
      //     record?.travellers.map(t => (
      //       <div key={t._id}>
      //         {t.name}, {t.ageInTravelDate}
      //       </div>
      //     )),
      //   // render: (_, record) => (
      //   //   <>
      //   //
      //   //     <div>Fulano, 78</div>
      //   //     <div>Ciclano, 22</div>
      //   //   </>
      //   // ),
      // },
    ].filter(x => !x.hidden);

    return (
      <Table
        rowKey="_id"
        size="small"
        className="no-table-overflow"
        columns={columns}
        dataSource={dataItem || []}
        pagination={false}
      />
    );
  }
};

export default CrmList;
