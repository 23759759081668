import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Collapse, Flex, Row } from 'antd';
import { isValidCpfCnpj, returnOnlyNumbers, translateX } from 'utils/helpers';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import {
  goFocus,
  JarvislyAsyncAutoComplete,
  JarvislyCpfCnpj,
  JarvislyDatePicker,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyMoneyPercentage,
  JarvislyMultiEmails,
  JarvislyNumber,
  JarvislyPhone,
  JarvislyRadioButtons,
  JarvislySelect,
} from 'components/jarvisly-components/others/FormComponents';
import bankService from 'services/bankService';
import appService from 'services/appService';
import entityService from 'services/entityService';
import { useSelector } from 'react-redux';
import JarvislyContactForm from 'components/jarvisly-components/forms/Items/JarvislyContactForm';
import JarvislyAddressForm from 'components/jarvisly-components/forms/Items/JarvislyAddressForm';
import dayjs from 'dayjs';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const BillCard = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { setMainValue } = props;

  // local variables I ---------------------------------------------------------
  const billingTypesOptions = bankService.getBillingTypes();
  const banksOptions = bankService.getCompanyBanks();
  const disabledDueDate = current =>
    current && current < dayjs().startOf('day');

  // component states ----------------------------------------------------------
  const [minimalValue, setMinimalValue] = useState(0);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //
  //   if (document) {
  //
  //     form.setFieldsValue({
  //       ...document,
  //       selectedBank: selectedCompany?.__mainBank?.accountCode,
  //       installment: 1,
  //     });
  //
  //     onChangeSelectedBank(selectedCompany?.__mainBank?.accountCode);
  //   }
  //
  //
  // }, [formRefreshed, banksOptions, document]);// eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onChangeSelectedBank = value => {
    const selectedBank = banksOptions.find(b => b.value === value);
    let minValue = 0;

    if (selectedBank?.bankCode) {
      const bank = bankService.getBankByNumber(selectedBank.data.bankCode);
      minValue = bank.minimalBillValue || 0;
    }

    if (minValue !== minimalValue) setMinimalValue(minValue);
  };

  const onValueChange = value => {
    setMainValue(value);
  };

  const onChangeDueDate = value => {
    form.setFieldValue('discountDate', value);
  };

  // UI COMPONENTS -------------------------------------------------------------

  return (
    <>
      <Card title={<div className="text-muted">{translateX('bill')}</div>}>
        <Row gutter={ROW_GUTTER}>
          {/* BANKS  */}
          <Col xs={24} md={6}>
            <JarvislySelect
              name="selectedBank"
              label="bank"
              showSearch
              allowClear
              options={banksOptions}
              onChange={onChangeSelectedBank}
            />
          </Col>
          {/* BANKS  */}

          {/*VALUE*/}
          <Col xs={24} md={6}>
            <JarvislyNumber
              name="value"
              suffixDefaultValue="brl"
              decimalSeparator=","
              min={minimalValue}
              max={9999999.99}
              addonBefore={null}
              addonAfter="R$"
              suffixStyle={{ width: 60 }}
              onChange={onValueChange}
            />
          </Col>
          {/*VALUE*/}

          {/* DUE DATE */}
          <Col xs={18} sm={6}>
            <JarvislyDatePicker
              name="dueDate"
              label="due_date"
              onChange={onChangeDueDate}
              disabledDate={disabledDueDate}
            />
          </Col>
          {/* DUE DATE */}

          {/* PAYMENT METHOD */}
          <Col xs={24} md={6}>
            <JarvislySelect
              name="billingType"
              label="payment_method"
              showSearch
              allowClear
              options={billingTypesOptions}
            />
          </Col>
          {/* PAYMENT METHOD */}
        </Row>

        <Row gutter={ROW_GUTTER}>
          {/* NOTE */}
          <Col xs={24} md={18}>
            <JarvislyInput name="description" />
          </Col>
          {/* NOTE */}

          {/*VALUE*/}
          <Col xs={24} md={6}>
            <JarvislyNumber
              name="installment"
              min={1}
              max={999}
              addonBefore={null}
              addonAfter={null}
            />
          </Col>
          {/*VALUE*/}
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// COMPONENT *******************************************************************
// *****************************************************************************
const FeesCard = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { mainValue } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------

  return (
    <>
      <Card
        title={
          <div className="text-muted">{translateX('interest_and_fines')}</div>
        }
      >
        <Row gutter={ROW_GUTTER}>
          {/* INTEREST */}
          <Col xs={24} md={12}>
            <JarvislyMoneyPercentage
              name="interest"
              label="monthly_interest"
              profile={['percentage']}
              prefixDefaultValue="percentage"
              decimalSeparator=","
              min={0}
              max={9999999.99}
              mainValue={mainValue}
            />
          </Col>
          {/* INTEREST */}

          {/* FINE */}
          <Col xs={24} md={12}>
            <JarvislyMoneyPercentage
              name="fine"
              profile={['percentage', 'value']}
              prefixDefaultValue="percentage"
              decimalSeparator=","
              min={0}
              max={9999999.99}
              mainValue={mainValue}
            />
          </Col>
          {/* FINE */}
        </Row>

        <Row gutter={ROW_GUTTER}>
          {/* DISCOUNT */}
          <Col xs={24} md={12}>
            <JarvislyMoneyPercentage
              name="discount"
              profile={['percentage', 'value']}
              prefixDefaultValue="percentage"
              decimalSeparator=","
              min={0}
              max={9999999.99}
              mainValue={mainValue}
            />
          </Col>
          {/* DISCOUNT */}

          {/* DATE DISCOUNT */}
          <Col xs={24} md={12}>
            {/*<JarvislyDatePicker*/}
            {/*  name="discountExpireDate"*/}
            {/*  label="discount_payment_until"*/}
            {/*/>*/}
          </Col>
          {/* DATE DISCOUNT */}
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// COMPONENT *******************************************************************
// *****************************************************************************
const CustomerCard = props => {
  // providers context ---------------------------------------------------------
  const { form, elRefs, formRefreshed } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    selectedCustomer,
    setSelectedCustomer,
    context,
    document,
    _id,
    mode,
    isWorking,
  } = props;

  // local variables I ---------------------------------------------------------
  const isMobile = appService.isMobile();
  const isDisabled = isWorking || mode === 'view';

  // component states ----------------------------------------------------------
  const [billingEmailsOptions, setBillingEmailsOptions] = useState([]);
  const [customerType, setCustomerType] = useState('registered_customer');

  // local variables II --------------------------------------------------------
  const customerAdditionalFields = buildCustomerAdditionalFields();

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form.setFieldsValue({
      [context]: {
        customerType: 'registered_customer',
      },
    });

    setSelectedCustomer(document?.customer);

    // if (document) {
    //   updateFormAdressState(document[context], context);
    // }
  }, [formRefreshed, document]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onChangeCustomerType = async ev => {
    const value = typeof ev === 'object' ? ev?.target?.value : ev;

    // resetForm();

    // form.setFieldsValue({ [context]: { customerType: value } });
    form.setFieldValue([context], {});

    if (value === 'registered_customer') {
      goFocus(elRefs, 'selectedEntity');
    } else {
      goFocus(elRefs, 'cpfCnpjNewCustomer');
    }

    setCustomerType(value);
    setSelectedCustomer(null);
    setBillingEmailsOptions([]);
  };

  const onCustomerSelect = async value => {
    const doc = await entityService.getEntityById(value.key);

    setSelectedCustomer(doc || null);
    setBillingEmailsOptions(entityService.getEntityAllEmailsArr(doc));

    const body = entityService.decorateEntity(doc, 1, context);

    form.setFieldsValue(body);
    form.validateFields([[context, 'mobile']]);
  };

  const onCpfCnpjChange = async ev => {
    let tagId = ev?.target?.id;
    let fieldName;

    if (tagId) {
      const parts = tagId.split('_');
      fieldName = parts && parts.length > 0 ? parts[parts.length - 1] : null;
    }

    const value = typeof ev === 'object' ? ev?.target?.value : ev;

    const cpfCnpj = returnOnlyNumbers(value);

    if (!isValidCpfCnpj(cpfCnpj)) return;

    const doc = await entityService.getEntityByField(cpfCnpj);

    if (!doc) return true;

    const body = entityService.decorateEntity(doc, 1, context);
    body[context].customerType = 'registered_customer';

    let title = translateX(
      'document_exists',
      ['%CPF_CNPJ%'],
      [doc?.cpf ? 'CPF' : 'CNPJ'],
    );
    const options = {
      Content: translateX(
        'document_exists_description',
        ['%CPF_CNPJ%'],
        [doc?.cpf ? 'CPF' : 'CNPJ'],
      ),
      okText: 'yes',
      cancelText: 'no',

      onCancel: () => {
        form.setFieldValue(
          [context, fieldName],
          selectedCustomer?.cpf || selectedCustomer?.cnpj,
        );
        if (fieldName) goFocus(elRefs, fieldName, 'all');
        return false;
      },

      onOk: () => {
        setCustomerType('registered_customer');
        setBillingEmailsOptions(entityService.getEntityAllEmailsArr(doc));
        setSelectedCustomer(doc);
        // updateFormAdressState(doc, 'customer');

        // setRefreshAddress(+new Date());

        form.setFieldValue([context], body[context]);
        form.validateFields([[context, 'mobile']]);
        goFocus(elRefs, 'selectedEntity');
        return false;
      },
    };

    appService.modal('q', title, options);

    return false;
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <Card title={<div className="text-muted">{translateX('customer')}</div>}>
      {!_id ? (
        <Flex justify={isMobile ? 'center' : 'flex-start'} align="center">
          {/* CUSTOMER OPTIONS */}
          <JarvislyRadioButtons
            context={context}
            name="customerType"
            onChange={onChangeCustomerType}
            noLabel
            options={[
              {
                value: 'registered_customer',
                label: translateX('registered_customer'),
              },
              {
                value: 'new_customer',
                label: translateX('new_customer'),
              },
            ]}
          />
        </Flex>
      ) : null}
      {/* CUSTOMER OPTIONS */}

      <Row
        gutter={ROW_GUTTER}
        style={{
          display: customerType === 'registered_customer' ? 'flex' : 'none',
        }}
      >
        {/* CUSTOMER */}
        <Col xs={24} md={16}>
          <JarvislyAsyncAutoComplete
            context={context}
            name="selectedEntity"
            label="drawee"
            required={customerType === 'registered_customer'}
            disabled={
              isDisabled || (document?.status && document.status !== 'new')
            }
            x-data-profile={[
              'customer',
              'provider',
              'collaborator',
              'resident',
              'operator',
            ]}
            x-on-select={onCustomerSelect}
          />
        </Col>
        {/* CUSTOMER */}

        {/* CPF/CNPJ */}
        <Col xs={24} sm={8}>
          <JarvislyCpfCnpj
            context={context}
            disabled={
              isDisabled ||
              !selectedCustomer ||
              (document?.status && document.status !== 'new')
            }
            required={customerType === 'registered_customer'}
            onChange={onCpfCnpjChange}
          />
        </Col>
        {/* CPF/CNPJ */}
      </Row>

      <Row
        gutter={ROW_GUTTER}
        style={{
          display: customerType !== 'registered_customer' ? 'flex' : 'none',
        }}
      >
        <Col xs={24} sm={8}>
          <JarvislyCpfCnpj
            context={context}
            name="cpfCnpjNewCustomer"
            required={customerType !== 'registered_customer'}
            onChange={onCpfCnpjChange}
          />
        </Col>

        <Col xs={24} sm={16}>
          <JarvislyInput
            context={context}
            name="name"
            required={customerType !== 'registered_customer'}
          />
        </Col>
      </Row>

      <Row gutter={ROW_GUTTER}>
        {/* MOBILE */}
        <Col xs={24} sm={8}>
          <JarvislyPhone
            context={context}
            name="mobile"
            profile="MOBILE"
            disabled={
              isDisabled ||
              (customerType === 'registered_customer' && !selectedCustomer)
            }
          />
        </Col>
        {/* MOBILE */}

        {/* EMAIL */}
        <Col xs={24} sm={16}>
          {/*<JarvislyEmail name='billingEmails' label='emails' />*/}
          <JarvislyMultiEmails
            context={context}
            name="billingEmails"
            label="billing_emails"
            disabled={
              isDisabled ||
              (customerType === 'registered_customer' && !selectedCustomer)
            }
            options={billingEmailsOptions}
          />
        </Col>
        {/* EMAIL */}
      </Row>

      {/* ADDITIONAL FIELDS */}
      <Collapse
        ghost
        style={{
          display:
            customerType !== 'registered_customer' || selectedCustomer
              ? 'flex'
              : 'none',
        }}
        onChange={keys => {
          if (keys?.includes('additional_fields')) {
            // if (selectedCustomer) {
            // setTimeout(() => {
            // form.validateFields(['mobile']);
            // });
            // }

            goFocus(elRefs, 'phone', 'all');
          }
        }}
        items={customerAdditionalFields}
        className="no-collapse-padding"
      />
      {/* ADDITIONAL FIELDS */}
    </Card>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildCustomerAdditionalFields() {
    return [
      {
        key: 'additional_fields',
        label: `${translateX('additional_info')} (${translateX('optional')})`,
        children: (
          <>
            {/* CONTACTS */}
            <JarvislyContactForm {...props} />
            {/* CONTACTS */}

            {/* ADDRESS */}
            <JarvislyAddressForm {...props} />
            {/* ADDRESS */}

            {/* NOTE */}
            {/*<JarvislyNoteForm*/}
            {/*  {...props}*/}
            {/*  disabled={*/}
            {/*    customerType === 'registered_customer' && !selectedCustomer*/}
            {/*  }*/}
            {/*/>*/}
            {/* NOTE */}
          </>
        ),
      },
    ];
  }
};

// COMPONENT *******************************************************************
// *****************************************************************************
const ReceivablesRecordIndex = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form, formRefreshed } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { document } = props;

  // local variables I ---------------------------------------------------------
  const { selectedCompany } = useSelector(s => s.authSlice);

  // component states ----------------------------------------------------------
  const [mainValue, setMainValue] = useState(0);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    mainValue,
    setMainValue,
    selectedCompany,
    document,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (document?._id  && form) {
      form.setFieldsValue(document);
      setMainValue(document?.value);
      // setMainValue(document?.value || 0);
    } else {
      form.setFieldsValue({
        selectedBank: selectedCompany?.__mainBank?.accountCode,
        installment: 1,
        interest: 3,
        fine: 2,
      });
    }
  }, [formRefreshed, document]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* BILL CARD */}
      <BillCard {...childProps} />
      {/* BILL CARD */}

      {/* CUSTOMER CARD */}
      <FeesCard {...childProps} />
      {/* CUSTOMER CARD */}

      {/* CUSTOMER CARD */}
      <CustomerCard {...childProps} context="customer" />
      {/* CUSTOMER CARD */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default ReceivablesRecordIndex;
