import { GoTools } from 'react-icons/go';
import { getFromLocalStorage } from 'utils/helpers';
import VehiclesForm from './Form/Form';
import VehiclesList from './List/List';
import { DefaultFormHeaderColumn1 } from 'components/jarvisly-module/Form/FormHeader';
import { VehiclesFormHeaderTitle } from './Components';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
const MENU_AREA = 'records';
// const MENU_GROUP = 'operational';
const MODULE_TITLE = 'vehicles';
const MODULE_ICON = GoTools;
const MODULE_API = '/v1/vehicles';
export const APPSINDICO_VEHICLES_ROUTE = `/app/${MENU_AREA}/${MODULE_TITLE}`;
// export const APPSINDICO_VEHICLES_ROUTE = `/app/${MENU_AREA}/${MENU_GROUP}/${MODULE_TITLE}`;

// MODULE PARAMETERS ***********************************************************
// *****************************************************************************
const APPSINDICO_VEHICLES_MODULE = {
  rootName: 'vehicles',
  name: 'vehicles',
  title: MODULE_TITLE,
  icon: MODULE_ICON,
  urlForm: null,
  api: MODULE_API,
  url: APPSINDICO_VEHICLES_ROUTE,
  routes: [
    `${APPSINDICO_VEHICLES_ROUTE}/:_id`,
    APPSINDICO_VEHICLES_ROUTE,
  ],

  listState: {
    viewMode: ['list'], // 'list', 'kanban', 'grid' or 'dashboard'
    showNumberOfLine: false, // show number of line in table list
    showCheckBoxes: false, // show check boxes on table list for multiple selection
    hasFilter: true, // show the filter (folders and customs) drawer button
    hasAccessControl: true, // show the control access modal for this module
    isTableChanged: false, // detect if the table was changed, check if the currentQs is different of initialQs (includes column sorter, search and pagination)
    isFilterChanged: false, // detect if the filters was changed
  },

  initialRequest: {
    folder: 'documents',
    folderOptions: ['documents', 'recycle', 'filed'],

    filter: {},
    filterOptions: {},

    pagination: {
      pageNumber: 1, // current page
      pageSize: getFromLocalStorage('pageSize'),
    },
    paginationOptions: {
      pageSize: [10, 20, 50, 100],
    },

    sorter: {
      '_metadata.audit.updatedAt': -1,
    },

    sorterOptions: ['_metadata.counter', '_metadata.audit.updatedAt'],

    search: {},

    textSearch: {},
  },

  methods: {
    decorateDocument,
  },

  documentState: {
    disableLockForEdit: false,
    initTab: 'record',
    fieldName: 'name',
    newDocumentTitle: 'new_vehicle',
    autoFill: false,
    actions: [
      'new',
      'edit',
      'reload',
      'divider',
      'duplicate',
      'file',
      'remove',
      'permanently_remove',
      'calibrate'
    ],
    refreshParametersOnSave: true,

    mockDocument: {},
  },

  components: {
    //dashboard
    dashboard: null,

    //list components
    list: props => <VehiclesList {...props}/>,
    grid: null,
    kanban: null,
    parameters: null,
    dataImport: null,
    previewHeader: null,
    previewBody: null,
    previewActions: null,

    //form components
    form: props => <VehiclesForm {...props}/>,
    formHeaderTitle: props => <VehiclesFormHeaderTitle {...props} />,
    formHeaderSubtitle: null,
    formHeaderColumn1: props => <DefaultFormHeaderColumn1 {...props} />,
    formHeaderColumn2: null,
    formHeaderColumn3: null,
    formHeaderTags: null,
    formHeaderCustomIcons: null,
  },
};

// EXPORT ********************************************************************
// ***************************************************************************
export default APPSINDICO_VEHICLES_MODULE;

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================

function decorateDocument(doc) {
  return doc;
}
