import { Card, Col, ColorPicker, Form, Row } from 'antd';
import {
  JarvislyFormContext,
  JarvislyInput,
  JarvislySelect,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appveerticalService from 'services/appveerticalService';
import { translateX } from 'utils/helpers';
import React, { useContext, useEffect, useState } from 'react';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const IotDevicesDeviceTab = props => {
  // providers context ---------------------------------------------------------
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { isDisabled, document, activeTab, initTab } = props;

  // local variables I ---------------------------------------------------------
  // const { parameters } = useSelector(s => s.moduleSlice);
  const devicesOptions = appveerticalService.buildDevicesOptions();

  // component states ----------------------------------------------------------
  const [selectedDeviceOptions, setSelectedDeviceOptions] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(document?.type);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (document?.type && !selectedDevice) {
      setSelectedDevice(document.type);
    }
  }, [document, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const currentDeviceModel = form?.getFieldValue('model');
    const selectedDeviceModelOpt =
      appveerticalService.buildDevicesModelOptions(selectedDevice || currentDeviceModel);

    if (selectedDeviceModelOpt.length > 0 && currentDeviceModel) {
      const modelMatched = selectedDeviceModelOpt?.find(
        m => m.value === currentDeviceModel,
      );


      if (!modelMatched) form?.setFieldValue('model', '');

    } else {
      form?.setFieldValue('model', '');
    }

    setSelectedDeviceOptions(
      appveerticalService.buildDevicesModelOptions(selectedDevice),
    );

  }, [selectedDevice]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* DEVICE CARD */}
      <Card title={translateX('device')}>
        <Row gutter={ROW_GUTTER}>
          {/* CHANNEL */}
          <Col xs={24} sm={6}>
            <JarvislyInput name='channel' />
          </Col>
          {/* CHANNEL */}

          {/* DEVICE */}
          <Col xs={24} sm={8}>
            <JarvislySelect
              label='device'
              name='type'
              options={devicesOptions}
              onChange={d => setSelectedDevice(d)}
              showSearch
            />
          </Col>
          {/* DEVICE */}

          {/* MODEL */}
          <Col xs={24} sm={8}>
            <JarvislySelect
              label='model'
              name='model'
              options={selectedDeviceOptions}
              showSearch
            />
          </Col>
          {/* MODEL */}

          {/*          <Col xs={24} sm={22} lg={6}>
            <JarvislySelect
              label="location"
              name="locationId"
              options={devicesLocationsOptions}
              required
              showSearch
            />
          </Col>*/}

          {/* <Col style={{display: 'none'}}>
            <JarvislySelect name="service" options={devicesTypesOptions} />
          </Col> */}

          {/* COLOR */}
          <Col xs={24} sm={2}>
            <Form.Item
              name='color'
              // style={{ width: '0%', marginTop: '25px' }}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '25px',
              }}
              required={true}
            >
              <ColorPicker
                disabled={isDisabled}
                value={form?.getFieldValue('color')}
                onChange={color => form.setFieldsValue({ color })}
                defaultFormat='hex'
                size='small'
              />
            </Form.Item>
          </Col>
          {/* COLOR */}
        </Row>

        <Row gutter={ROW_GUTTER}>
          {/* TITLE */}
          <Col xs={24} sm={12} lg={12}>
            <JarvislyInput name='title' />
          </Col>
          {/* TITLE */}

          {/* DESCRIPTION */}
          <Col xs={24} sm={12} lg={12}>
            <JarvislyInput name='description' />
          </Col>
          {/* DESCRIPTION */}
        </Row>
      </Card>
      {/* DEVICE CARD */}
    </>
  );
};

export default IotDevicesDeviceTab;
