import { Card, Col, Row } from 'antd';
import {
  JarvislyDatePicker,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyPhone,
  JarvislySelect,
  JarvislyTitle,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import appsindicoService from 'services/appsindicoService';
import axiosService from 'services/axiosService';
import { translateX } from 'utils/helpers';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const HabitationTab = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { refreshDocument, document } = props;
  // local variables I ---------------------------------------------------------
  const profileOptions = appsindicoService.buildHabitationProfileOptions();
  const contractOptions = appsindicoService.buildContractOptions();
  const kinshipOptions = appsindicoService.buildkinshipOptions();

  // component states ----------------------------------------------------------
  const [, setSelectedProfile] = useState('');

  const [, setshowContract] = useState(false);
  const [, setShowrentalRealEstate] = useState(false);

  const [, setSelectedContract] = useState();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const handleProfileChange = value => {
    setSelectedProfile(value);
    const profile = vp();
    setshowContract(profile > 1);
  };

  const handleContractChange = value => {
    setSelectedContract(value);
    const contract = vc();
    setShowrentalRealEstate(contract === 1 || contract === 2);
  };

  const vp = () => {
    const value =
      form.getFieldValue(['__habitation', 'profile']) || document?.profile;
    let profile;

    switch (value) {
      case 'owner':
        profile = 1;
        break;
      case 'tenant':
        profile = 2;
        break;
      case 'non_tenant_resident':
        profile = 3;
        break;
      default:
        profile = 0;
    }

    return profile;
  };

  const vc = () => {
    const value =
      form.getFieldValue(['__habitation', 'contract']) || document?.contract;
    let contract;

    switch (value) {
      case 'rental_through_agency':
        contract = 1;
        break;
      case 'rental_direct_owner':
        contract = 2;
        break;
      default:
        contract = 0;
    }

    return contract;
  };

  const onSaveTab = async body => {
    const habitation = body?.__habitation || {};

    try {
      const habitationOptions = {
        url: '/v1/habitations', // URL da API_
        _id: document?.habitationId,
        data: {
          profile: habitation?.profile,
          contract: habitation?.contract,

          realEstateAgentName: habitation?.realEstateAgentName,
          realEstateAgentMobile: habitation?.realEstateAgentMobile,
          realEstateCompanyName: habitation?.realEstateCompanyName,

          renterName: habitation?.renterName,
          renterMobile: habitation?.renterMobile,
          renterRelationship: habitation?.renterRelationship,

          ownerName: habitation?.ownerName,
          ownerMobile: habitation?.ownerMobile,
          ownerRelationship: habitation?.ownerRelationship,

          moveDate: habitation?.moveDate,

          observation: habitation?.observation,
        },
      };
      const [habitationResponse] = await axiosService.upsert(habitationOptions);
      if (habitationResponse && document?._id) {
        const unitsOptions = {
          url: '/v1/units', // URL da API_
          _id: document?._id,
          data: {
            habitationId: habitationResponse._id,
          },
        };
        await axiosService.upsert(unitsOptions);
      }
      refreshDocument();
    } catch (error) {
      console.error(error);
    }
  };

  useImperativeHandle(ref, () => ({
    onSaveTab,
  }));

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card title={translateX('habitation')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12} lg={12}>
            <JarvislySelect
              name={['__habitation', 'profile']}
              onChange={handleProfileChange}
              options={profileOptions}
              showSearch
              allowClear
              required
            />
          </Col>
          {vp() > 1 && (
            <Col xs={24} sm={12} lg={12}>
              <JarvislySelect
                name={['__habitation', 'contract']}
                onChange={handleContractChange}
                options={contractOptions}
                showSearch
                allowClear
                required
              />
            </Col>
          )}
        </Row>

        {vp() === 3 && vc() >= 1 && (
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} md={8}>
              <JarvislyInput
                name={['__habitation', 'renterName']}
                label="renter_name"
                required
              />
            </Col>
            <Col xs={24} sm={8}>
              <JarvislyPhone
                label="mobile"
                name={['__habitation', 'renterMobile']}
                required
              />
            </Col>
            <Col xs={24} md={8}>
              <JarvislySelect
                name={['__habitation', 'renterRelationship']}
                label="kinship_degree"
                showSearch
                allowClear
                options={kinshipOptions}
                required
              />
            </Col>
          </Row>
        )}

        {vp() > 1 && vc() === 1 && (
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} md={8}>
              <JarvislyInput
                name={['__habitation', 'realEstateAgentName']}
                label="agent_name"
                required
              />
            </Col>
            <Col xs={24} sm={8}>
              <JarvislyPhone
                label="mobile"
                name={['__habitation', 'realEstateAgentMobile']}
                required
              />
            </Col>
            <Col xs={24} md={8}>
              <JarvislyInput
                name={['__habitation', 'realEstateCompanyName']}
                label="real_estate_name"
                required
              />
            </Col>
          </Row>
        )}

        {vp() > 1 && vc() === 2 && (
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} md={8}>
              <JarvislyInput
                name={['__habitation', 'ownerName']}
                label="owner_name"
                required
              />
            </Col>
            <Col xs={24} sm={8}>
              <JarvislyPhone
                label="mobile"
                name={['__habitation', 'ownerMobile']}
                required
              />
            </Col>
            <Col xs={24} md={8}>
              <JarvislySelect
                name={['__habitation', 'ownerRelationship']}
                label="kinship_degree"
                showSearch
                allowClear
                options={kinshipOptions}
                required
              />
            </Col>
          </Row>
        )}

        <JarvislyTitle title={'period'} />
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8} lg={8}>
            <JarvislyDatePicker
              label="move_date"
              name={['__habitation', 'moveDate']}
              required
            />
          </Col>
          <Col xs={24} lg={24}>
            <JarvislyInput
              name={['__habitation', 'observation']}
              label="observation"
              maxLength={500}
            />
          </Col>
        </Row>
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
});

export default HabitationTab;
