import { forwardRef, useContext, useEffect, useState } from 'react';
import { translateX } from 'utils/helpers';
import { Modal, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import JarvislyEntitiesParametersRequiredFields from '../../../../../components/jarvisly-components/forms/modals/JarvislyParameters/JarvislyEntitiesParametersRequiredFields';
import { JarvislyFormProvider } from 'components/jarvisly-components/others/FormComponents';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { refreshParameters } = useContext(ModuleContext);
  const { moduleType } = useSelector(s => s.moduleSlice);

  // props deconstruction ------------------------------------------------------
  const { showParametersModal, setShowParametersModal } = props;

  // local variables I ---------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);

  // component states ----------------------------------------------------------
  const [tabIdx, setTabIdx] = useState();
  // const [categories] = useState(['sapato', 'meia']);
  const [isUpdated, setIsUpdated] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    // setCategories(parameters?.categories || [])
    // form?.setFieldsValue({ categories: parameters?.categories || [] });
  }, [parameters]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    // refreshParameters();
    // refreshList();
    setShowParametersModal(false);
  };

  /*  const onSubmit = async data => {
    data.categories?.map(c => {
      if (c.color?.toHexString) c.color = c.color?.toHexString();
      return c;
    });
    const axiosOptions = {
      url: '/v1/iot-device-parameters',
      _id: parameters?._id,
      data,
    };
    appService.message('s', 'saved_parameters', 'onSave');
    await axiosService.upsert(axiosOptions);
    onClose();
  };*/

  function onTabClick(key) {
    setTabIdx(key);

    switch (key) {
      // case 'configurations':
      //   configurationTabRef?.current?.getFirstFocus();
      //   break;

      // case 'requiredFields':
      //   requiredFieldsTabRef?.current?.getFirstFocus();
      //   break;

      default:
        break;
    }
  }

  // local variables III --------------------------------------------------------
  const childProps = {
    ...props,
    isUpdated,
    setIsUpdated,
  };
  const tabItems = buildTabItems();

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        // className="title-fixed-with-18 modal-top"
        title={translateX('parameters')}
        destroyOnClose={true}
        open={showParametersModal}
        width={640}
        // okText={translateX('save')}
        onCancel={onClose}
        okText={translateX('close')}
        // cancelText={translateX('close')}
        // okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={onClose}
        getContainer={false}
        okType={'default'}
        // confirmLoading={isWorking}
        afterClose={() => {
          if (isUpdated) {
            if (moduleType === 'document') {
              refreshParameters(true);
            }

            setIsUpdated(false);
          }
        }}
      >
        <Tabs onChange={onTabClick} items={tabItems} activeKey={tabIdx} />
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItems() {
    return [
      {
        label: translateX('form'),
        key: 'form',
        hide: false,
        children: <JarvislyEntitiesParametersRequiredFields {...childProps} />,
      },
    ];
  }
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const CustomersParametersModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // for 'contacts' tab
  // const [showParametersModal, setShowParametersModal] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  // useImperativeHandle(ref, () => ({
  //   showParametersModal,
  //   setShowParametersModal: value => setShowParametersModal(value),
  // }));

  // methods -------------------------------------------------------------------
  const onConfirm = ({ body }) => {
    console.log('BODY TO SAVE ON ON_CONFIRM ->', body);
  };

  // variables III -------------------------------------------------------------
  // const childProps = {
  //   ...props,
  //   showParametersModal,
  //   setShowParametersModal,
  // };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-parameters-customer"
      document={document}
      // requiredFields={requiredFields}
      // setTab={setTab}
      onConfirm={onConfirm}
      // onValuesChange={(v, allValues) => setFormData({ ...allValues })}
    >
      <ModalComponent {...props} />
    </JarvislyFormProvider>
  );
});

export default CustomersParametersModal;
