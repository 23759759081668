import { createContext, useState } from 'react';

export const ParametersContext = createContext({});

const ParametersProvider = ({ children }) => {
  // component states ----------------------------------------------------------
  const [selectedRecord, setSelectedRecord] = useState();
  const [showBusinessAreaModal, setShowBusinessAreaModal] = useState();
  const [showOriginModal, setShowOriginModal] = useState(false);
  const [showBoardModal, setShowBoardModal] = useState(false);
  const [showPipelineModal, setShowPipelineModal] = useState(false);
  const [showTemperatureModal, setShowTemperatureModal] = useState(false);

  const exposedValues = {
    selectedRecord,
    setSelectedRecord,
    showBusinessAreaModal,
    setShowBusinessAreaModal,
    showOriginModal,
    setShowOriginModal,
    showPipelineModal,
    setShowPipelineModal,
    showBoardModal,
    setShowBoardModal,
    showTemperatureModal,
    setShowTemperatureModal,
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <ParametersContext.Provider value={exposedValues}>
      {children}
    </ParametersContext.Provider>
  );
};

export default ParametersProvider;
