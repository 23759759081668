import React from 'react';
import { Button, Col, Divider, Drawer, Modal, Row, Tooltip } from 'antd';
import { BorderlessTableOutlined, FormOutlined, StarOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { translateWord } from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentTags from 'components/jarvisly-components/others-old/DocumentTags';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************

const ListPreview = props => {
  // props deconstruction ------------------------------------------------------

  const {
    // showDrawer,
    // setShowDrawer,
    visible,
    close,
    record,
  } = props;

  // component states -----------------------------------------------------------

  // local variables ------------------------------------------------------------
  const navigate = useNavigate();
  const { selectedModule, components } = useSelector(s => s.moduleSlice);

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------

  /* eslint-disable no-unused-vars */
  const onDevelopment = () => {
    Modal.warning({
      title: 'Função Indisponível!',
      content: 'Este recurso está em desenvolvimento.',
    });
  };

  const handleOpenButton = () =>
    navigate(`${selectedModule.url}/${record._id}`);

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Drawer
      width={300}
      placement="left"
      onClose={close}
      closable={false}
      open={visible}

      // open={showDrawer}
      // onClose={() => setShowDrawer(false)}
      // closable={false}
    >
      <Col>
        {/* MODULE PREVIEW HEADER COMPONENT */}
        {components?.previewHeader ? (
          <>
            {components?.previewHeader(props)}
            <Divider dashed className="mt-0" />
          </>
        ) : null}
        {/* MODULE PREVIEW HEADER COMPONENT */}

        <div>
          <h6 className="text-muted text-uppercase mb-3">
            {translateWord('document')}
          </h6>

          <Row
            className="preview-row text-truncate"
            wrap={false}
            align={'middle'}
          >
            <Tooltip title={translateWord('document_code')} placement="right">
              <BorderlessTableOutlined />
              <span className="ml-3 text-dark text-uppercase">
                {record?._metadata?.counter}.{record?._metadata?.documentCode}
              </span>
            </Tooltip>
          </Row>

          <Row
            className="preview-row text-truncate"
            wrap={false}
            align={'middle'}
          >
            <Tooltip title={translateWord('creation_date')} placement="right">
              <StarOutlined />
              <span className="ml-3 text-dark text-uppercase">
                {dayjs(record?._metadata?.audit?.createdAt).format('L LT')}
              </span>
            </Tooltip>
          </Row>

          <Row
            className="preview-row text-truncate"
            wrap={false}
            align={'middle'}
          >
            <Tooltip title={translateWord('last_update')} placement="right">
              <FormOutlined />
              <span className="ml-3 text-dark text-uppercase">
                {dayjs(
                  record?._metadata?.audit?.updatedAt
                    ? record._metadata.audit.updatedAt
                    : record?._metadata?.audit?.createdAt,
                ).format('L LT')}
              </span>
            </Tooltip>
          </Row>

          <Row
            justify="end"
            align="middle"
            className="pt-3"
            style={{ minHeight: 18 }}
          >
            {components?.formHeaderCustomIcons &&
              components.formHeaderCustomIcons(props)}

            <DocumentTags
              record={record}
              className={components?.formHeaderCustomIcons ? 'ml-2' : ''}
            />
          </Row>
        </div>

        {/* MODULE PREVIEW BODY COMPONENT */}
        <div className="mt-5">
          {components?.previewBody && components?.previewBody(props)}
        </div>
        {/* MODULE PREVIEW BODY COMPONENT */}
      </Col>

      <Row justify="center" className="mt-5">
        <Button
          type="primary"
          style={{ width: 180 }}
          disabled={!record?._id}
          onClick={handleOpenButton}
        >
          {translateWord('open')}
        </Button>
      </Row>

      {/* MODULE PREVIEW ACTIONS COMPONENT */}
      {components?.previewActions && components?.previewActions(props)}
      {/* MODULE PREVIEW ACTIONS COMPONENT */}

      {/* DRAWER FOOTER */}
      <div>
        <div className="mb-4" />
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%', // borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'center',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }}
        >
          <div className="text-muted">_id: {record?._id}</div>
        </div>
      </div>
      {/* DRAWER FOOTER */}
    </Drawer>
  );
};

export default ListPreview;
