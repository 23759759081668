import { TagOutlined } from '@ant-design/icons';
import { Card, Col, Modal, Row } from 'antd';
import {
  JarvislyCnpj,
  JarvislyEmail,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyPhone,
  JarvislyRadio,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useContext, useEffect, useState } from 'react';
import appService from 'services/appService';
import axiosService from 'services/axiosService';
import { translateX } from 'utils/helpers';

// ...

const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showPixKeyViewModal,
    setShowPixKeyViewModal,
    selectedPix,
    setSelectedPix,
    isDisabled,
    setPixType,
    setFormatterRecord,
  } = props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !selectedPix?._id
      ? translateX('new_pix_key')
      : translateX('edit_pix_key');

  // component states -----------------------------------------------------------
  const [selectedType, setSelectedType] = useState();

  useEffect(() => {
    if (selectedPix && selectedPix._id) {
      setSelectedType(selectedPix.type);
      setPixType(selectedPix.type)
      setFormatterRecord({
        pixKey: {
          mobile: selectedPix.mobile,
          email: selectedPix.email,
          cnpj: selectedPix.cnpj,
          _id: selectedPix._id,
        },
      });
      form.setFieldsValue(selectedPix);
    } else {
      setSelectedType(null);
      setPixType(null)
      setFormatterRecord(null);
      // setSelectedPix(null);
      form.resetFields(); 
    }
  }, [selectedPix, showPixKeyViewModal, form, setFormatterRecord, setPixType, setSelectedPix]);

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowPixKeyViewModal(false);
  };

  const handleTypeChange = e => {
    setSelectedType(e.target.value);
    setPixType(e.target.value);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Modal
      title={
        <>
          <TagOutlined />
          <span className="ml-2">{title}</span>
        </>
      }
      destroyOnClose={true}
      open={showPixKeyViewModal}
      width={640}
      okText={translateX('save')}
      onCancel={onClose}
      cancelText={translateX('close')}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => (isDisabled ? onClose() : form.submit())}
      getContainer={false}
      okType={'primary'}
    >
      <Card title={translateX('choice_an_option')}>
        <Row gutter={ROW_GUTTER} style={{ marginBottom: '24px' }}>
          <Col xs={24} sm={24}>
            <JarvislyRadio
              name="type"
              onChange={handleTypeChange}
              value={selectedType}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              direction="horizontal"
              options={[
                { value: 'cnpj', text: translateX('cnpj') },
                { value: 'mobile', text: translateX('mobile') },
                { value: 'email', text: translateX('email') },
              ]}
            />
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          {selectedType === 'cnpj' && (
            <Col xs={24} sm={24}>
              <JarvislyCnpj label="cnpj" name={['pixKey', 'cnpj']} required />
            </Col>
          )}
          {selectedType === 'mobile' && (
            <Col xs={24} sm={24}>
              <JarvislyPhone
                label="mobile"
                name={['pixKey', 'mobile']}
                required
              />
            </Col>
          )}
          {selectedType === 'email' && (
            <Col xs={24} sm={24}>
              <JarvislyEmail
                label="email"
                name={['pixKey', 'email']}
                required
              />
            </Col>
          )}
        </Row>
      </Card>
    </Modal>
  );
};

// MAIN COMPONENT **************************************************************

const PixKeyViewModal = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    setShowPixKeyViewModal,
    selectedRecord,
    selectedPix,
    refreshDocument,
  } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [pixType, setPixType] = useState();
  const [formattedRecord, setFormatterRecord] = useState();

  // local variables II --------------------------------------------------------

  const childProps = {
    ...props,
    pixType,
    setPixType,
    formattedRecord,
    setFormatterRecord,
  };

  // providers context ---------------------------------------------------------

  // methods -------------------------------------------------------------------

  const onConfirm = async body => {
    if (!selectedPix && !pixType) {
      appService.notification('i', '', '', 'select_type_pix_key', 5);
      return;
    }
  
    const newBody = {
      ...body?.body?.pixKey,
      type: pixType,
      _id: selectedPix?._id || `temp-${Date.now()}`, // Gera um novo _id se não houver um existente
    };
  
    // Verifica se o item existe no array
    const existingIndex = selectedRecord.pixKey.findIndex(
      key => key._id === selectedPix?._id
    );
  
    if (existingIndex === -1) {
      // Adiciona como novo item
      selectedRecord.pixKey.push(newBody);
    } else {
      // Atualiza item existente
      selectedRecord.pixKey[existingIndex] = {
        ...selectedRecord.pixKey[existingIndex],
        ...newBody,
      };
    }
  
    const axiosOptions = {
      url: `/v1/banks`,
      _id: selectedRecord._id,
      data: {
        pixKey: selectedRecord.pixKey,
      },
    };
    await axiosService.put(axiosOptions);
  
    appService.message('s', 'saved_parameters', 'onSave');
    refreshDocument(true);
  
    setShowPixKeyViewModal(false);
  
    return body;
  };
  
  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      document={formattedRecord}
      requiredFields={['pixKey.Email', 'pixKey.mobile', 'pixKey.cnpj']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus={false}
      abortComponentSave
    >
      <ModalComponent {...childProps} />
    </JarvislyFormProvider>
  );
  
  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

};

export default PixKeyViewModal;
