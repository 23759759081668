import { useContext, useState } from 'react';
import { translateX } from 'utils/helpers';
import { Modal, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import LocationsTab from './locations/LocationsTab';
import SectorsTab from './sectors/SectorsTab';
import BusinessAreaTab from 'components/jarvisly-components/forms/modals/JarvislyParameters/BusinessAreaParameters/BusinessAreaTab';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';
import { ParametersContext } from 'components/jarvisly-module/ParametersProvider';
import BusinessAreaModal from 'components/jarvisly-components/forms/modals/JarvislyParameters/BusinessAreaParameters/BusinessAreaModal';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { refreshParameters } = useContext(ModuleContext);
  const { moduleType } = useSelector(s => s.moduleSlice);

  // props deconstruction ------------------------------------------------------
  const {
    showParametersModal,
    setShowParametersModal,
    selectedRecord,
    setSelectedRecord,
    isUpdated,
    setIsUpdated,
  } = props;

  // local variables I ----------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);

  // component states -----------------------------------------------------------
  const [tabKey, setTabKey] = useState('businessArea');

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowParametersModal(false);
  };

  function onTabClick(key) {
    setTabKey(key);

    switch (key) {
      // case 'locations':
      //   configurationTabRef?.current?.getFirstFocus();
      //   break;

      // case 'requiredFields':
      //   requiredFieldsTabRef?.current?.getFirstFocus();
      //   break;

      default:
        break;
    }
  }
  // local variables III --------------------------------------------------------
  const childProps = {
    ...props,
    isDisabled: false,
    isUpdated,

    parameters,
    setIsUpdated,
    selectedRecord,
    setSelectedRecord,
  };

  const tabItems = buildTabItems();

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        title={translateX('parameters')}
        destroyOnClose={true}
        open={showParametersModal}
        width={640}
        onCancel={onClose}
        cancelText={translateX('close')}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={onClose}
        getContainer={false}
        okType={'default'}
        afterClose={() => {
          if (isUpdated) {
            if (moduleType === 'document') refreshParameters(true);
            setIsUpdated(false);
          }
        }}
      >
        <Tabs onChange={onTabClick} items={tabItems} activeKey={tabKey} />
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTabItems() {
    return [
      {
        label: translateX('area_activy'),
        key: 'businessArea',
        hide: false,
        children: <BusinessAreaTab {...childProps} />,
      },
      {
        label: translateX('locations'),
        key: 'locations',
        hide: false,
        children: <LocationsTab {...childProps} />,
      },
      {
        label: translateX('sectors'),
        key: 'sectors',
        hide: false,
        children: <SectorsTab {...childProps} />,
      },
    ];
  }
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const IotDevicesParametersModal = props => {
  // providers context ---------------------------------------------------------
  const {
    showBusinessAreaModal,
    setShowBusinessAreaModal,
    showOriginModal,
    setShowOriginModal,
    selectedRecord,
    setSelectedRecord,
  } = useContext(ParametersContext);

  // props deconstruction ------------------------------------------------------

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [isUpdated, setIsUpdated] = useState(false);

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // variables III -------------------------------------------------------------
  const childProps = {
    ...props,
    setShowBusinessAreaModal,
    showBusinessAreaModal,
    showOriginModal,
    setShowOriginModal,
    selectedRecord,
    setSelectedRecord,
    isUpdated,
    setIsUpdated,
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <ModalComponent {...childProps} />

      {/* AREA ACTIVY MODAL WRAPPER */}
      <BusinessAreaModal {...childProps} />
      {/* AREA ACTIVY MODAL WRAPPER */}
    </>
  );
};

export default IotDevicesParametersModal;
