import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyPhone,
  JarvislySelect,
  JarvislyTitle,
} from '../../others/FormComponents';
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { returnOnlyNumbers, toCapitalCase, translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import appService from 'services/appService';
import { Card, Col, Modal, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { API_ATHLETES } from 'constants/ApiConstant';
import { ROW_GUTTER } from 'constants/ThemeConstant';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { record, showModal, setShowModal, afterClose, isDisabled, updating } =
    props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !record?._id
      ? translateX('new_responsable')
      : translateX('edit_responsable');

  // const childProps = {
  //   ...props,
  //   form,
  // };

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form?.resetFields();
    if (record) form?.setFieldsValue(record);
  }, [record, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18 modal-top"
        title={
          <>
            <UserOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        width={640}
        okText={translateX(!isDisabled ? 'save' : 'close')}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        confirmLoading={updating}
        afterClose={afterClose}
      >
        <Card>
          <JarvislyTitle title={'responsible'} />
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} lg={12}>
              <JarvislySelect
                name="responsibleFirst"
                label="responsible"
                options={[
                  { value: 'Pai/Mãe', label: translateX('father_mother') },
                  {
                    value: 'Padrasto/Madrasta',
                    label: translateX('stepfather_mother'),
                  },
                  { value: 'Avô/Avó', label: translateX('grandfather_mother') },
                  { value: 'Tio(a)', label: translateX('uncle_aunt') },
                ]}
              />
            </Col>

            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput name="nameResponsable" label="father_name" />
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <JarvislyInput name="profession" label="profession" />
            </Col>
            <Col xs={24} sm={24} lg={12}>
              <JarvislyPhone name="phoneResponsable" label="phone" />
            </Col>
          </Row>
        </Card>
        {/* PERSON */}
        {/* <JarvislySelect
              name="name"  label="responsible"
              options={[
                { value: 'maePai', label: translateX('father_mother')},
                { value: 'Pai  ', label: translateX('stepfather_mother') },
                { value: 'Vo  ', label: translateX('grandfather_mother') },
              ]}
            /> */}
        {/* <JarvislyPersonForm {...childProps} /> */}
        {/* PERSON */}

        {/* CONTACTS */}
        {/* <JarvislyContactForm {...props} /> */}
        {/* CONTACTS */}

        {/* NOTE */}
        {/* <JarvislyNoteForm {...props} /> */}
        {/* NOTE */}
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const JarvislyResponsablesModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { _id, document, isWorking, refreshDocument } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // for 'contacts' tab
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables II --------------------------------------------------------
  const contactsTabProps = {
    ...props,
    showModal,
    setShowModal,
    selectedRecord,
    setSelectedRecord,
  };

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal: value => setShowModal(value),
    selectedRecord: selectedRecord,
    setSelectedRecord: value => setSelectedRecord(value),
  }));

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body, form }) => {
    console.log('body ->', body);

    const url = API_ATHLETES;
    const name = body.nameResponsable.toLowerCase();
    const cpf = returnOnlyNumbers(body?.cpf);
    let entityId = selectedRecord?._id;

    if (
      document?.moreContacts.findIndex(
        c => c?.name?.toLowerCase() === name && c?._id !== entityId,
      ) > -1
    ) {
      appService.notification(
        'e',
        'check_info',
        'check_info',
        'contact_already_exists',
        5,
      );
      return;
    }

    body.nameResponsable = toCapitalCase(body.nameResponsable);
    body.dataType = 'person';
    body.dataProfile = 'contact';
    body.uniqueKey = cpf || +new Date();

    // update moreContacts subdocument
    const contact = {
      entityId,
      note: body?.note,
      nameResponsable: body?.nameResponsable,
      profession: body?.profession,
      phoneResponsable: body?.phoneResponsable,
      responsibleFirst: body?.responsibleFirst,
    };

    if (!document?.moreContacts) document.moreContacts = [];
    let idx = document.moreContacts.findIndex(c => c?._id === entityId);
    if (idx === -1) {
      document.moreContacts.push(contact);
    } else {
      document.moreContacts[idx].note = body?.note;
      document.moreContacts[idx].nameResponsable = body?.nameResponsable;
      document.moreContacts[idx].profession = body?.profession;
      document.moreContacts[idx].phoneResponsable = body?.phoneResponsable;
      document.moreContacts[idx].responsibleFirst = body?.responsibleFirst;
    }
    const data = { moreContacts: document.moreContacts };
    const [doc] = await axiosService.put({ _id, url, data });

    form?.resetFields();

    refreshDocument(doc._id, true);
    setShowModal(false);

    return doc;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-contact"
      document={selectedRecord}
      requiredFields={['cpf', 'nameResponsable']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus="nameResponsable"
    >
      <ModalComponent
        {...contactsTabProps}
        showBirthdate
        updating={isWorking}
        record={selectedRecord}
        afterClose={() => {
          setSelectedRecord(null);
        }}
      />
    </JarvislyFormProvider>
  );
});

export default JarvislyResponsablesModal;
