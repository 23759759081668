import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from 'components/jarvisly-components/others/FormComponents';
import { useState } from 'react';
import { translateX } from 'utils/helpers';
import { Tabs } from 'antd';
import IotDevicesDeviceTab from './device/deviceTab';
import useTab from 'components/jarvisly-components/hooks/useTabHook';
import IotDevicesCustomerTab from './customerTab/IotDevicesCustomerTab';
import InstallationTab from './installation/installationTab';
import SettingsTab from './settings/settingsTab';
import ParametersProvider from 'components/jarvisly-module/ParametersProvider';
import entityService from 'services/entityService';

const IotDevicesForm = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, selectedModule, mode } = props;

  // local variables I ---------------------------------------------------------
  const initTab = selectedModule.documentState.initTab;
  const availableTabs = [initTab, 'customer', 'installation', 'settings'];

  // component states ----------------------------------------------------------
  const [tab, setTab] = useTab(availableTabs, 'channel');
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [entityType, setEntityType] = useState('company');
  const [showContactsModal, setShowContactsModal] = useState(false);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedTab: tab,
    initTab,

    selectedEntity,
    setSelectedEntity,
    entityType,
    setEntityType,
    tab,
    showContactsModal,
    setShowContactsModal,
  };
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    console.log('body...', body);

    // create or update entity
    const entity = await entityService.persistyEntity(
      body,
      selectedEntity,
      entityType,
      'customer',
    );

    // post or put crm
    const dataSet = {
      ...body,
      entityId: entity?._id,
    };

    // color
    if (body?.color) {
      // Convert the color object into a string (hex or rgba) depending on the schema expectation
      if (typeof body.color === 'object' && body.color.metaColor) {
        dataSet.color = `rgba(${body.color.metaColor.r}, ${body.color.metaColor.g}, ${body.color.metaColor.b}, ${body.color.metaColor.a})`;
      }
    }

    // ENTITY
    if (dataSet?.entity) delete dataSet.entity;

    return dataSet;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <ParametersProvider>
      {/* WRAPPER MODULE PARAMETERS MODAL */}
      {selectedModule?.components?.parameters
        ? selectedModule.components.parameters(childProps)
        : null}
      {/* WRAPPER MODULE PARAMETERS MODAL */}

      <JarvislyFormProvider
        name="form-iot-devices"
        document={document}
        initialValues={{ service: 'veertical' }}
        autoFocus="name"
        requiredFields={['channel', 'type', 'model', 'color', 'title']}
        setTab={setTab}
        onSave={onSave}
        showSubmitButton={true}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </ParametersProvider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('device'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <IotDevicesDeviceTab {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },

      {
        label: translateX('installation'),
        key: 'installation',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab={'installation'}>
            <InstallationTab {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },
      /*

      {
        label: translateX('customer'),
        key: 'customer',
        hide: false,
        disabled: mode === 'add',
        children: <h1>CUSTOMER</h1>
        // children:             <IotDevicesCustomerTab {...childProps} activeTab={tab} />

/!*
        children: (
          <JarvislyTabProvider tab={'customer'}>
            <IotDevicesCustomerTab {...childProps} activeTab={tab} />
          </JarvislyTabProvider>
        ),
*!/
      },
*/

      /*      {
        label: translateX('settings'),
        key: 'settings',
        hide: false,
        disabled: mode === 'add',
        children: (
          <JarvislyTabProvider tab={'settings'}>
            <h1>settings</h1>
            {/!*<SettingsTab {...childProps} activeTab={tab} />*!/}
          </JarvislyTabProvider>
        ),
      },*/
    ];
  }
};

export default IotDevicesForm;
