import { Tooltip } from 'antd';
import { translateX } from 'utils/helpers';

//titulo do cabeçalho
export const VehiclesFormHeaderTitle = props => {
    const { document } = props;

    return (
      <Tooltip title={translateX('vehicle')}>
        <span className="text-uppercase">
          <span>
            {translateX('vehicle')} Nº {document?._metadata?.counter}
          </span>
        </span>
      </Tooltip>
    );
  };
