import { translateX } from 'utils/helpers';
import appService from 'services/appService';
import { Flex, Table, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormButton, JarvislyEditViewRemoveItem } from 'components/jarvisly-components/others/FormComponents';
import axiosService from 'services/axiosService';

// COMPONENT *******************************************************************
// *****************************************************************************
const TemperatureTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    setShowTemperatureModal,
    setSelectedRecord,
    refreshParameters,
    parameters,
    setIsWorking,
  } = props;

  // local variables I ----------------------------------------------------------
  const crmParameters = parameters?.crmParameters || {};
  const tableColumns = buildTableColumns();

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onAdd = () => {
    setShowTemperatureModal(true);
  };
  const onEdit = record => {
    setSelectedRecord(record);
    setShowTemperatureModal(true);
  };

  const onDelete = async record => {
    setIsWorking(true);
    const axiosOptions = {
      url: `/v1/crm-parameters/add-to-set/${crmParameters?._id}/$pull/temperatures._id`,
      data: {
        _id: record?._id,
      },
    };

    appService.message('s', 'saved_parameters', 'onSave');
    await axiosService.put(axiosOptions);

    refreshParameters(true);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={crmParameters?.temperatures || []}
        pagination={false}
        disabled={false}
        size="small"
      />

      <Flex justify="center">
        <FormButton
          title="add_temperature"
          className="m-3"
          style={{ width: 'fit-content' }}
          icon={<PlusOutlined />}
          disabled={false}
          onClick={onAdd}
        />
      </Flex>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        title: translateX('name'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <Tag key={record._id} color={record?.backColor}>
              <span
                className="text-uppercase"
                style={{ color: record?.foreColor }}
              >
                {record?.label}
              </span>
            </Tag>
          );
        },
      },
      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              size="small"
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              isDisabled={false}
              deleteTitle={translateX(
                'remove_this_item',
                ['%ITEM%'],
                ['contact'],
              )}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default TemperatureTab;
