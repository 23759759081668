import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Descriptions, Modal, Row } from 'antd';
import IntlMessage from 'components/util-components/IntlMessage';
import { MailOutlined, MobileOutlined, SendOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { parsePhoneToDisplay, translateX } from 'utils/helpers';
import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislySegmented,
  JarvislySelect,
  JarvislyTextArea,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appService from 'services/appService';
import authService from 'services/authService';
import whatsappService from 'services/whatsappService';
import { useSelector } from 'react-redux';
import axiosService from 'services/axiosService';
import app from 'configs/ConfigIndex';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showModal,
    setShowModal,
    afterClose,
    document,
    channel,
    setChannel,
    list,
    setCurrentSession,
    confirmLoading,
    setConfirmLoading,
  } = props;

  // local variables I ----------------------------------------------------------
  const whatsappOptions = buildWhatsAppOptions(list);

  //   // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------
  const channelOptions = buildChannelOptions(channel);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (
      !whatsappOptions ||
      (whatsappOptions?.length === 0 && document?.dataResponsible?.email)
    ) {
      setChannel('email');
    } else if (
      !whatsappOptions ||
      (whatsappOptions?.length === 0 && !document?.dataResponsible?.email)
    ) {
      setChannel('sms');
    } else {
      setChannel('whatsapp');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whatsappOptions, document]);

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    form.resetFields();
    setShowModal(false);
    setConfirmLoading(false);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18 modal-top"
        title={
          <>
            <SendOutlined className="mr-2" />
            {translateX('request_update')}
          </>
        }
        destroyOnClose={true}
        open={showModal}
        width={640}
        okText={translateX('send')}
        onCancel={onClose}
        onOk={() => form.submit()}
        getContainer={false}
        okType={'primary'}
        confirmLoading={confirmLoading}
        afterClose={afterClose}
      >
        <Card>
          <Descriptions
            title={setLocale(true, 'responsible')}
            size="small"
            column={1}
          >
            <Descriptions.Item
              label={setLocale(true, 'name')}
              labelStyle={{ width: 140 }}
            >
              <span className="text-muted">
                {document?.dataResponsible?.name}
              </span>
            </Descriptions.Item>
            <Descriptions.Item
              label={setLocale(true, 'mobile')}
              labelStyle={{ width: 140 }}
            >
              <span className="text-muted">
                {document?.dataResponsible?.mobile}
              </span>
            </Descriptions.Item>
            <Descriptions.Item
              label={setLocale(true, 'email')}
              labelStyle={{ width: 140 }}
            >
              <span className="text-muted">
                {document?.dataResponsible?.email}
              </span>
            </Descriptions.Item>
            <Descriptions.Item
              label={setLocale(true, 'access_code')}
              labelStyle={{ width: 140 }}
            >
              <span className="text-muted">
                {document?.dataResponsible?.id}
              </span>
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <Row gutter={ROW_GUTTER}>
          <Col span={24}>
            <JarvislySegmented
              name="channel"
              disabled={false}
              options={channelOptions}
              onChange={v => setChannel(v)}
            />
          </Col>
          {channel === 'whatsapp' && whatsappOptions?.length > 0 && (
            <Col xs={24} md={16}>
              <JarvislySelect
                name="whatsapp_options"
                options={whatsappOptions}
                onChange={v => setCurrentSession(v)}
                disabled={false}
                required
              />
            </Col>
          )}
          {channel === 'email' && (
            <Col xs={24}>
              <JarvislyInput name="subject" disabled={false} required />
            </Col>
          )}
          {channel !== 'form' && (
            <Col xs={24}>
              <JarvislyTextArea name="message" disabled={false} rows={5} />
            </Col>
          )}
        </Row>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************

const AskUpdateModal = props => {
  // providers context ---------------------------------------------------------
  const { selectedSubscription, selectedCompany } = useSelector(
    s => s?.authSlice,
  );

  // props deconstruction ------------------------------------------------------
  const { document, isWorking, setShowModal } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [channel, setChannel] = useState('whatsapp');
  const [list, setList] = useState([]);
  const [currentSession, setCurrentSession] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    channel,
    setChannel,
    list,
    setCurrentSession,
    confirmLoading,
    setConfirmLoading,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (app.WHATSAPP_INTEGRATED) {
      (async () => await getWppList(selectedSubscription?._id))();
    }
  }, [selectedSubscription?._id]);

  // methods -------------------------------------------------------------------
  const getWppList = async id => {
    try {
      const response = await whatsappService.getWhatsAppList(id);
      setList(response[0]);
    } catch (error) {
      console.log('Error getting list');
    }
  };

  const sendEmail = async (subject, message, destiny, name, fromName) => {
    try {
      const axiosOptions = {
        url: `/v1/send-email/update`,
        data: {
          subject,
          message,
          destiny,
          name,
          fromName,
        },
      };
      await axiosService.post(axiosOptions);
    } catch (error) {
      console.log('Error sening email --> ', error);
    }
  };

  const onConfirm = async ({ body, form }) => {
    const data = {
      channel: channel,
      message: body.message,
    };

    switch (data.channel) {
      case 'whatsapp': {
        try {
          setConfirmLoading(true);
          await whatsappService.sendWhatsAppMessage(
            currentSession,
            document?.dataResponsible?.mobile,
            body.message,
          );
          appService.message(
            's',
            translateX('ask_update_send'),
            'add_account',
            5,
          );
          form.resetFields();
          setShowModal(false);
          setCurrentSession('');
          setConfirmLoading(false);
        } catch (error) {
          appService.message(
            'e',
            translateX('e_ask_update_send'),
            'add_account',
            5,
          );
          setConfirmLoading(false);
        }
        break;
      }
      case 'email':
        try {
          setConfirmLoading(true);
          await sendEmail(
            body.subject,
            body.message,
            document?.dataResponsible?.email,
            document?.dataResponsible?.name,
            selectedCompany?.fullName,
          );
          form.resetFields();
          setShowModal(false);
          setConfirmLoading(false);
          appService.message(
            's',
            translateX('ask_update_send'),
            'add_account',
            5,
          );
        } catch (error) {
          setConfirmLoading(false);
          appService.message(
            'e',
            translateX('e_ask_update_send'),
            'add_account',
            5,
          );
        }
        break;
      case 'sms':
        try {
          appService.notification(
            <MobileOutlined />,
            'sending_sms',
            'sendingKey',
            'wait_for_sms',
            0,
          );
          setConfirmLoading(true);
          await authService.sendRequestUpdateMessage(
            document?.dataResponsible?.name,
            document?.dataResponsible?.mobile,
            data,
          );
          appService.message(
            's',
            translateX('ask_update_send'),
            'add_account',
            5,
          );
          setConfirmLoading(false);
          appService.notificationDestroy('sendingKey');
          setShowModal(false);
          form.resetFields();
        } catch (error) {
          appService.message(
            'e',
            translateX('e_ask_update_send'),
            'add_account',
            5,
          );
          setConfirmLoading(false);
          return;
        }
        break;
      default:
        console.log('Set a channel');
    }

    form?.resetFields();

    return null;
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-contact"
      requiredFields={['message', 'status']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      abortComponentSave={true}
    >
      <ModalComponent
        {...childProps}
        updating={isWorking}
        afterClose={() => {}}
      />
    </JarvislyFormProvider>
  );
};

// INTERNAL FUNCTIONS ========================================================
// ===========================================================================
function buildChannelOptions(channel) {
  return [
    {
      value: 'whatsapp',
      label: translateX('whatsapp'),
      icon: <WhatsAppOutlined />,
      disabled: channel !== 'whatsapp',
    },
    {
      value: 'email',
      label: translateX('email'),
      icon: <MailOutlined />,
      disabled: channel !== 'email',
    },
    {
      value: 'sms',
      label: translateX('sms'),
      icon: <MobileOutlined />,
      disabled: channel !== 'sms',
    },
    // {
    //   value: 'form',
    //   label: translateX('form'),
    //   icon: <PrinterOutlined />,
    // },
  ];
}

const buildWhatsAppOptions = list => {
  return list
    ?.filter(item => !item._metadata.audit.removedAt)
    .map(item => ({
      value: item.session,
      label: `${item.title} - ${parsePhoneToDisplay(item.mobile)}`,
    }));
};

// EXPORT **********************************************************************
// *****************************************************************************

export default AskUpdateModal;
