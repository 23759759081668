import { Flex, Table } from 'antd';
import { translateX } from 'utils/helpers';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import appService from 'services/appService';
import { PlusOutlined } from '@ant-design/icons';

import imgPuppy from 'assets/images/puppy.png';
import imgCat from 'assets/images/cat.png';
import imgBird from 'assets/images/bird.png';
import imgHamster from 'assets/images/hamster.png';
import imgEgg from 'assets/images/egg.png';
import { capitalize } from 'lodash';
import appsindicoService from 'services/appsindicoService';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const JarvislyPetsIndex = props => {
  // props deconstruction ------------------------------------------------------
  const {
    _id,
    isWorking,
    setIsWorking,
    document,
    mode,
    setShowPetsModal,
    setSelectedRecord,
    refreshDocument,
  } = props;

  // local variables -----------------------------------------------------------
  const isDisabled = isWorking || mode === 'view';
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onAdd = () => {
    setSelectedRecord(null);
    setShowPetsModal(true);
  };

  const onEdit = record => {
    setSelectedRecord(record);
    setShowPetsModal(true);
  };

  const onDelete = async record => {
    setIsWorking(true);
    await appsindicoService.removeArrayId(
      record,
      'v1/units',
      _id,
      'petsIds',
      'v1/pets',
    );
    refreshDocument(_id, true);
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Table
        rowKey={record => record._id} // Assuming each dependent has a unique _id
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={document?.__pets || []}
        pagination={false}
        loading={!document || isWorking}
      />

      <Flex justify={appService.isMobile() ? 'center' : 'end'}>
        <FormButton
          title="add_pet"
          className="m-3"
          style={{ width: 200 }}
          icon={<PlusOutlined />}
          disabled={isDisabled}
          onClick={onAdd}
        />
      </Flex>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTableColumns() {
    return [
      {
        title: translateX('name'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          let img;

          // Determinando a imagem com base no tipo do animal
          switch (record?.specie) {
            case 'puppy':
              img = imgPuppy;
              break;
            case 'cat':
              img = imgCat;
              break;
            case 'bird':
              img = imgBird;
              break;
            case 'hamster':
              img = imgHamster;
              break;
            default:
              img = imgEgg;
              break;
          }

          // Traduzindo o tipo do animal
          const subtitle = translateX(record?.specie);

          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                imageUrl={img} // Usando a imagem correta baseada no tipo do animal
                title={record?.name?.toUpperCase()}
                subtitle={capitalize(subtitle)}
                shape="square"
                style={{ padding: 10 }}
                backColor="#ecf2fe"
              />
            </div>
          );
        },
      },
      {
        title: translateX('breed'),
        dataIndex: 'breed',
        render: (_, record) => {
          return (
            <>
              <div>{translateX(record?.breed)}</div>
            </>
          );
        },
      },
      {
        title: translateX('color'),
        dataIndex: 'color',
        render: (_, record) => {
          return (
            <>
              <div>{translateX(record?.color)}</div>
            </>
          );
        },
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              recordId={record?.entityId}
              isDisabled={isDisabled}
              deleteTitle={translateX('remove_this_item', ['%ITEM%'], ['pet'])}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default JarvislyPetsIndex;
